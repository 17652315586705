<template>
    <div class="main_bd">
        <div class="head_title">Boost</div>
        <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="merchant">Merchant management </a-menu-item>
            <a-menu-item key="boost"> Boost Today </a-menu-item>
            <a-menu-item key="boost_history"> Boost History </a-menu-item>
            <a-menu-item key="transaction"> IAP Transaction </a-menu-item>
            <a-menu-item key="robot"> Robot Management </a-menu-item>
        </a-menu>
        <div class="mod_default_box permission">
            <span v-if="false" style="margin-right: 10px; margin-left: 2px;">Date Range: </span><a-range-picker v-if="false" @change="onChange" />
            <a-table style="margin-top: 10px" :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="transaction_list" >
                <template slot="status_zone" slot-scope="text">
                    <a-tag v-if="text == 0" color="pink"> Available </a-tag>
                    <a-tag v-if="text == 1" color="green"> On Sale </a-tag>
                    <a-tag v-if="text == 10" color="blue"> Sold </a-tag>
                    <a-tag v-if="text == 11" color="red"> Unavailable </a-tag>
                    <a-tag v-if="text == 16" color="orange"> Expired </a-tag>
                </template>
                <template slot="exchange_zone" slot-scope="text">
                    <span v-if="text && text.ori_wait_code">
                        {{ 'From ' + text.ori_wait_code + ' to ' + text.new_wait_code }}
                    </span>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-button type="info" @click="show_transaction_detail(data)">Show Transaction Detail</a-button>
                </div>
            </a-table>
            <a-pagination style="margin-top: 10px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count" show-less-items />
        </div>
        <a-modal
            title="IAP Transaction Detail"
            :visible="show_transaction_detail_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="500"
            style="height: 400px; overflow: auto;"
            >
            <a-descriptions>
                <a-descriptions-item label="Purchase time" :span="3">
                    {{ transaction_info.purchaseDate && moment.tz(transaction_info.purchaseDate, 'America/New_York').format('YYYY-MM-DD hh:mm') }}
                </a-descriptions-item>
                <a-descriptions-item label="Price" :span="3">
                    {{ transaction_info.price && '$' + (transaction_info.price / 1000) }}
                </a-descriptions-item>
                <a-descriptions-item label="IAP Store" :span="3">
                    {{ transaction_info.storefront || 'USA' }}
                </a-descriptions-item>
                <a-descriptions-item label="Purchase Type" :span="3">
                    {{ transaction_info.type || 'Consumable' }}
                </a-descriptions-item>
                <a-descriptions-item label="Product" :span="3">
                    {{ transaction_info.productId || '' }}
                </a-descriptions-item>
            </a-descriptions>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from 'moment-timezone';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "ID",
            dataIndex: "idx",
        },
        {
            title: "Merchant Name",
            dataIndex: "sname",
        },
        {
            title: "Create time",
            dataIndex: "create_time",
        },
        {
            title: "Phone Number",
            dataIndex: "target_phone_num"
        },
        {
            title: "Exchange Data",
            dataIndex: "exchange_data",
            scopedSlots: { customRender: "exchange_zone" }
        },
        {
            title: "Amount",
            dataIndex: "amount"
        },
        {
            title: "Sale Status",
            dataIndex: "status_text",
        },
        {
            title: "Payment Status",
            dataIndex: "paid_status_text",
        },
        {
            title: 'Action',
            dataIndex: '',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'transaction_history',
        components: {
        },
        data() {
            return {
                moment,
                current: ['transaction'],
                transaction_list: [],
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                transaction_info: {},
                layer_data: {},
                show_transaction_detail_layer: false,
                start_date: '',
                end_date: ''
            };
        },
        beforeMount() {},
        async created() {
            this.get_transaction_list();
        },
        watch: {
            current() {
                if (this.current == 'merchant') {
                    this.$router.push({
                        path: '/market/boost/merchants'
                    });
                } else if (this.current == 'robot') {
                    this.$router.push({
                        path: '/market/boost/robots'
                    });
                } else if (this.current == 'boost') {
                    this.$router.push({
                        path: '/market/boost/sales'
                    });
                } else if (this.current == 'boost_history') {
                    this.$router.push({
                        path: '/market/boost/history'
                    });
                }
            },
        },
        mounted() {
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getBoostTransactionList: 'boost/getBoostTransactionList',
                getBoostTransactionInfo: 'boost/getBoostTransactionInfo',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_transaction_list() {
                let { list, total_count, total_page } = await this.getBoostTransactionList({
                    page: this.cur_page,
                    page_size: 20
                });

                this.transaction_list = list;
                this.total_count = total_count * 1;
                this.total_page = total_page * 1;
            },
            show_transaction_detail(data) {
                this.layer_data = data;
                this.show_transaction_detail_layer = true;
                this.get_boost_sales_detail(data);
            },
            async get_boost_sales_detail(data) {
                let res = await this.getBoostTransactionInfo({
                    pay_id: data.pay_id
                });

                if (res && res.info) {
                    this.transaction_info = res.info;
                }
            },
            handleOk() {
                this.show_transaction_detail_layer = false;
                this.layer_data = {};
            },
            turnPage(e) {
                this.get_transaction_list();
            },
            onChange(date, dateString) {
                this.start_date = dateString[0];
                this.end_date = dateString[1];
                
                this.get_transaction_list();
            },
        }
    };
</script>

<style>
</style>
