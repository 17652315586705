<template>
    <div class="u-content">
        <img :src="imgUrl || photo" alt="" style="width: 100px; height: 100px" v-show="imgUrl || photo" />
        <img-cutter
            ref="imgCutterModal"
            fileType="png"
            :boxWidth="400"
            :boxHeight="400"
            :cutWidth="cutwidth"
            :cutHeight="cutheight"
            :originalGraph="true"
            :smallToUpload="true"
            :moveAble="true"
            :imgMove="true"
            :rate="rate"
            :tool="false"
            :toolBoxOverflow="false"
            :previewMode="false"
            @cutDown="cutDown"
        >
            <a-button type="link" class="upload-btn" slot="open" :disabled="disabled"> Upload </a-button>
        </img-cutter>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import errorImg from '../assets/images/error.png';
import ImgCutter from 'vue-img-cutter';

export default {
    name: 'UploadImg',
    props: {
        photo: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        cutwidth: {
            type: Number,
            default: 300
        },
        cutheight:  {
            type: Number,
            default: 300
        },
    },
    data() {
        return {
            sid: this.$store.state.app.sid,
            imgUrl: '',
            errorImg: errorImg,
            rate: '1:' + (this.cutheight / this.cutwidth).toFixed(2)
        };
    },
    components: { ImgCutter },
    methods: {
        //通过cutDown事件接收选择的图片信息
        cutDown(event) {
            console.log(event);
            if (event.file.size < 524288) {
                this.$message.error('The image is too small。Please re trim！');
                return false;
            }
            if (event.file.size > 5242880) {
                this.$message.error('Image size cannot exceed 5MB。Please re trim！');
                return false;
            }
            var formData = new FormData();
            formData.append('file', event.file);
            let time = Date.parse(new Date());
            var randomNum = '';
            for (var i = 0; i < 4; i++) {
                var a = Math.floor(Math.random() * 9);
                randomNum += a;
            }
            formData.append('name', this.sid + '_draw_photo_' + time + randomNum);

            this.setLoading({ is_loading: true });
            this.uploadImg(formData).then((res) => {
                this.imgUrl = res ? res.file_url : this.errorImg;
                this.$emit('upload', this.imgUrl);
                this.setLoading({ is_loading: false });
            });
        },

        ...mapActions({
            uploadImg: 'draw/uploadImg'
        }),
        ...mapMutations({
            setLoading: 'app/APP_SET_IF_LOADING'
        })
    }
};
</script>
<style scoped>
.u-content {
    display: flex;
    align-items: center;
}
.upload-btn {
    width: 145px;
    height: 28px !important;
    color: #fff !important;
    border-radius: 8px !important;
    background-color: rgba(116, 39, 255, 1) !important;
    font-family: 'Helvetica';
    font-size: 16px !important;
    margin-left: 30px;
}
</style>