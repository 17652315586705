<template>
    <div class="main_bd">
        <div style="margin: 40px 0">
            <a-breadcrumb>
                <span slot="separator" style="color: #000; font-size: 22px">></span>
                <router-link :to="'/drawManagement'">
                    <a-breadcrumb-item class="b-item-1">Draw management</a-breadcrumb-item>
                </router-link>
                <a-breadcrumb-item class="b-item-2">{{ draw_id ? 'Edit the draw' : 'Create a draw' }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="permission">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
                labelAlign="left">
                <div class="form-item">
                    <div class="form-title">Basic settings</div>
                    <a-form-model-item ref="event_name" label="Title" prop="event_name">
                        <a-input v-model="form.event_name" placeholder="No more than 50 characters" @blur="
                            () => {
                                $refs.event_name.onFieldBlur();
                            }
                        " :maxLength="50" :disabled="!!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item label="Picture" prop="event_photo_url" required extra="image up to 5MB, square only, 1000*1000 px or above. 
JPG, PNG only">
                        <upload-img @upload="upload" :photo="form.event_photo_url" :disabled="!!draw_id"></upload-img>
                    </a-form-model-item>
                    <a-form-model-item label="Description" prop="event_description">
                        <a-input v-model="form.event_description" type="textarea"
                            placeholder="No more than 300 characters" :auto-size="{ minRows: 3 }" :maxLength="300" />
                    </a-form-model-item>
                    <a-form-model-item ref="user_name" label="Merchant id" prop="user_name">
                        <a-input id="user_name" v-model="form.user_name" placeholder="Related merchant email"
                            @blur="onMerchantBlur" :disabled="!!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item ref="need_pay" label="Price" prop="need_pay">
                        <a-radio-group v-model="form.need_pay" :disabled="!!draw_id">
                            <a-radio :value="0">
                                Free
                            </a-radio>
                            <a-radio :value="1">
                                Paid
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </div>
                <div class="form-item" v-show="form.need_pay === 1">
                    <div class="form-title">Price settings</div>
                    <a-form-model-item ref="price" label="Product price" prop="price">
                        <a-input-number v-model="form.price"
                            :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')" :precision="2" :min="0.01"
                            style="width: 200px;" :disabled="!!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item ref="tax" label="Tax" prop="tax">
                        <a-input-number v-model="form.tax"
                            :formatter="value => ` ${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\%\s?|(,*)/g, '')" :precision="2" :min="0"
                            style="width: 200px;" :disabled="!!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item ref="service_fee" label="Service fee" prop="service_fee">
                        <a-radio-group v-model="form.service_fee_type" @change="onServiceChange" :disabled="!!draw_id">
                            <a-radio :style="radioStyle" value="fixed">
                                Fixed     
                                <a-input-number v-model="form.service_fee_value1"
                                    :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" :precision="2" :min="0" :disabled="form.service_fee_type === 'percentage'"
                                    style="width: 200px; margin-left: 40px;" />
                            </a-radio>
                            <a-radio :style="radioStyle" value="percentage">
                                Percentage
                                <a-input-number v-model="form.service_fee_value2"
                                    :formatter="value => ` ${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                    :parser="value => value.replace(/\%\s?|(,*)/g, '')" :precision="2" :min="0"
                                    :disabled="form.service_fee_type === 'fixed' || !!draw_id" style="width: 200px;"  />
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </div>
                <div class="form-item">
                    <div class="form-title">Event settings</div>
                    <a-form-model-item label="Event publish time" required prop="publish_time">
                        <a-date-picker v-model="form.publish_time.date" placeholder="Pick a date" style="width: 50%"
                            :disabled-date="disabledDate" :disabled="!!draw_id" />
                        <a-time-picker :disabledHours="() => disabledHours('publish_time')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'publish_time')"
                            v-model="form.publish_time.time" style="width: 50%" format="HH:mm"
                            :disabled="!form.publish_time.date || !!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item label="Event active time" required props="start_time"
                        :wrapper-col="{ span: 20 }">
                        <a-date-picker v-model="form.start_time.date" placeholder="start date" style="width: 25%"
                            :disabled-date="disabledStartDate"
                            :disabled="!form.publish_time.date || !form.publish_time.time || !!draw_id" />
                        <a-time-picker :disabledHours="() => disabledHours('start_time')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'start_time')"
                            v-model="form.start_time.time" style="width: 20%" format="HH:mm" placeholder="start time"
                            :disabled="!form.publish_time.date || !form.publish_time.time || !form.start_time.date || !!draw_id" />
                        to
                        <a-date-picker v-model="form.end_time.date" placeholder="end date" style="width: 25%"
                            :disabled-date="disabledEndDate" :disabled="
                                !form.publish_time.date ||
                                !form.publish_time.time ||
                                !form.start_time.date ||
                                !form.start_time.time || !!draw_id
                            " />
                        <a-time-picker :disabledHours="() => disabledHours('end_time')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'end_time')" v-model="form.end_time.time"
                            style="width: 20%" format="HH:mm" placeholder="end time" :disabled="
                                !form.publish_time.date ||
                                !form.publish_time.time ||
                                !form.start_time.date ||
                                !form.start_time.time ||
                                !form.end_time.date || !!draw_id
                            " />
                    </a-form-model-item>
                    <a-form-model-item label="Result notify time" prop="result_time">
                        <a-date-picker v-model="form.result_time.date" placeholder="Pick a date" style="width: 50%"
                            :disabled-date="disabledResultDate" :disabled="
                                !form.publish_time.date ||
                                !form.publish_time.time ||
                                !form.start_time.date ||
                                !form.start_time.time ||
                                !form.end_time.date ||
                                !form.end_time.time || !!draw_id
                            " />
                        <a-time-picker :disabledHours="() => disabledHours('result_time')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'result_time')"
                            v-model="form.result_time.time" style="width: 50%" format="HH:mm" :disabled="
                                !form.publish_time.date ||
                                !form.publish_time.time ||
                                !form.start_time.date ||
                                !form.start_time.time ||
                                !form.end_time.date ||
                                !form.end_time.time ||
                                !form.result_time.date || !!draw_id
                            " />
                    </a-form-model-item>
                </div>
                <div class="form-item">
                    <div class="form-title">Prize settings</div>
                    <a-form-model-item label="Prize number" required prop="prize_count">
                        <a-input-number :min="1" v-model="form.prize_count" :disabled="!!draw_id" />
                    </a-form-model-item>
                    <a-form-model-item label="Prize description" prop="prize_description">
                        <a-input v-model="form.prize_description" type="textarea"
                            placeholder="No more than 300 characters" :auto-size="{ minRows: 3 }" :maxLength="300" />
                    </a-form-model-item>
                    <a-form-model-item label="How it works" prop="prize_guidelines">
                        <a-input v-model="form.prize_guidelines" type="textarea"
                            placeholder="No more than 300 characters" :auto-size="{ minRows: 3 }" :maxLength="300" />
                    </a-form-model-item>
                    <a-form-model-item label="Prize validity time">
                        <a-checkbox v-model="prize_stime_checked" @change="stimeCheck" :disabled="!!draw_id"></a-checkbox>
                        <a-date-picker :disabled="!prize_stime_checked || !!draw_id" v-model="form.prize_active_stime.date"
                            placeholder="start date" style="width: 50%" :disabled-date="disabledActiveStartDate"
                            @openChange="handleStartOpenChange" />
                        <a-time-picker :disabled="!prize_stime_checked || !!draw_id" v-model="form.prize_active_stime.time"
                            style="width: 45%" format="HH:mm" placeholder="start time"
                            :disabledHours="() => disabledHours('prize_active_stime')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'prize_active_stime')" />
                        <br />
                        <a-checkbox v-model="prize_etime_checked" @change="etimeCheck" :disabled="!!draw_id"></a-checkbox>
                        <a-date-picker :disabled="!prize_etime_checked || !!draw_id" v-model="form.prize_active_etime.date"
                            placeholder="end date" style="width: 50%" :disabled-date="disabledActiveEndDate"
                            :open="endOpen" @openChange="handleEndOpenChange" />
                        <a-time-picker :disabled="!prize_etime_checked || !!draw_id" v-model="form.prize_active_etime.time"
                            style="width: 45%" format="HH:mm" placeholder="end time"
                            :disabledHours="() => disabledHours('prize_active_etime')"
                            :disabledMinutes="(val) => disabledMinutes(val, 'prize_active_etime')" />
                    </a-form-model-item>
                </div>

                <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
                    <a-button v-if="draw_id" type="primary" class="create-btn" @click="onEditSubmit"> Save and update </a-button>
                    <a-button v-else type="primary" class="create-btn" @click="onSubmit"> Create </a-button>
                    <a-button class="cancel-btn" @click="cancel"> Cancel </a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<style>
.col_main .col_main_inner {
    width: 100%;
    margin: 0 auto;
}

.main_bd {
    padding: 0 45px;
    width: 100%;
    margin: 0 auto;
}

.b-item-1 .ant-breadcrumb-link {
    color: rgba(0, 0, 0, 1);
    font-family: 'Helvetica-Bold';
    font-size: 24px;
    font-weight: 700;
}

.b-item-2 .ant-breadcrumb-link {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica';
    font-size: 18px;
    font-weight: 400;
}

.form-item {
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 8px;
    background-color: rgba(252, 252, 252, 1);
}

.form-title {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px;
}

.ant-form-item-label {
    color: rgba(0, 0, 0, 1);
    font-family: 'Helvetica-Bold';
    font-size: 16px;
    font-weight: 700;
}

.create-btn {
    width: 134px;
    height: 30px !important;
    border: none !important;
    border-radius: 8px !important;
    background-color: rgba(44, 145, 0, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    font-family: 'Helvetica';
    font-size: 14px !important;
    font-weight: 400 !important;
}

.cancel-btn {
    margin-left: 15px;
    width: 124px;
    height: 30px !important;
    border: none !important;
    border-radius: 8px !important;
    font-family: 'Helvetica';
    font-size: 14px !important;
    font-weight: 400 !important;
    background-color: rgba(230, 230, 230, 1) !important;
    color: rgba(0, 0, 0, 1) !important;
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import UploadImg from '../components/UploadImg.vue';

export default {
    name: 'creatDraw',
    components: { UploadImg },
    data() {
        return {
            labelCol: { span: 3 },
            wrapperCol: { span: 10 },
            prize_stime_checked: false,
            prize_etime_checked: false,
            endOpen: false,
            startTimeOpen: false,
            draw_id: '',
            form: {
                sid: '',
                event_name: '',
                user_name: '',
                event_description: '',
                event_photo_url: '',
                publish_time: {
                    date: '',
                    time: ''
                },
                time_range: [],
                start_time: {
                    date: '',
                    time: ''
                },
                end_time: {
                    date: '',
                    time: ''
                },
                result_time: {
                    date: '',
                    time: ''
                },
                prize_description: '',
                prize_guidelines: '',
                prize_active_stime: {
                    date: '',
                    time: ''
                },
                prize_active_etime: {
                    date: '',
                    time: ''
                },
                prize_count: 1,
                need_pay: 0,
                price: 0,
                tax: 0,
                service_fee_type: 'fixed',
                service_fee_value1: 0,
                service_fee_value2: 0
            },
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
                marginBottom: '10px'
            },
            rules: {
                event_name: [
                    { required: true, message: 'Please input title', trigger: 'blur' },
                    { min: 1, max: 50, message: 'No more than 50 characters', trigger: 'blur' }
                ],
                user_name: [{ required: true, message: 'Please input merchant email', trigger: 'blur' }],
                event_description: [
                    { required: true, message: 'Please input event description', trigger: 'blur' },
                    { min: 1, max: 300, message: 'No more than 300 characters', trigger: 'blur' }
                ],
                publish_time: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
                time_range: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
                result_time: [{ required: true, message: 'Please pick a date', trigger: 'change' }],
                prize_description: [
                    { required: true, message: 'Please input prize description', trigger: 'blur' },
                    { min: 1, max: 300, message: 'No more than 300 characters', trigger: 'blur' }
                ],
                prize_guidelines: [
                    { required: true, message: 'Please input How it works', trigger: 'blur' },
                    { min: 1, max: 300, message: 'No more than 300 characters', trigger: 'blur' }
                ],
                need_pay: [{ required: true, trigger: 'blur' }],
                price: [{ required: true, trigger: 'blur' }],
            }
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    mounted() {
        const { draw_id } = this.$route.query
        if(draw_id){
            this.draw_id = draw_id;
            this.getDrawById({
                id: this.draw_id
            }).then(res => {
                console.log(res.data)
                this.form = res.data;
            })
        }
    },
    computed: {
        ...mapGetters({
            msg_opts: 'app/get_app_msg',
            store_info: 'draw/store_info'
        }),
        ...mapState({})
    },
    methods: {
        moment,
        disabledHours(type) {
            let HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
            let hours = [];
            let HOURSDate = [];
            let selectedDay;
            let curDay;
            let curHours;
            if (type == 'publish_time') {
                selectedDay = moment(this.form.publish_time.date).format('DD');
                curDay = new Date().getDate();
                curHours = new Date().getHours();
            } else if (type == 'start_time') {
                selectedDay = moment(this.form.start_time.date).format('DD');
                curDay = moment(this.form.publish_time.date).format('DD');
                curHours = moment(this.form.publish_time.time).hours();
            } else if (type == 'end_time') {
                selectedDay = moment(this.form.end_time.date).format('DD');
                curDay = moment(this.form.start_time.date).format('DD');
                curHours = moment(this.form.start_time.time).hours();
            } else if (type == 'result_time') {
                selectedDay = moment(this.form.result_time.date).format('DD');
                curDay = moment(this.form.end_time.date).format('DD');
                curHours = moment(this.form.end_time.time).hours();
            } else if (type == 'prize_active_etime') {
                selectedDay = moment(this.form.prize_active_etime.date).format('DD');
                curDay = moment(this.form.prize_active_stime.date).format('DD');
                curHours = moment(this.form.prize_active_stime.time).hours();
            } else if (type == 'prize_active_stime') {
                selectedDay = moment(this.form.prize_active_stime.date).format('DD');
                curDay = moment(this.form.prize_active_etime.date).format('DD');
                curHours = moment(this.form.prize_active_etime.time).hours();
            }
            if (selectedDay == curDay) {
                for (var i = 0; i < parseInt(curHours); i++) {
                    hours.push(i);
                }
            }
            for (var k = 0; k < HOURS.length; k++) {
                if (type == 'prize_active_stime' && this.form.prize_active_etime.time != '') {
                    if (selectedDay == curDay && hours.indexOf(k - 1) <= 0) {
                        HOURSDate.push(k);
                    }
                } else {
                    if (hours.indexOf(k) >= 0) {
                        HOURSDate.push(k);
                    }
                }
            }
            return HOURSDate;
        },
        disabledMinutes(selectedHour, type) {
            let timeArr;
            let selectedDay;
            let curDay;
            if (type == 'publish_time') {
                var myDate = new Date();
                timeArr = [myDate.getHours(), myDate.getMinutes()];
                selectedDay = moment(this.form.publish_time.date).format('DD');
                curDay = myDate.getDate();
            } else if (type == 'start_time') {
                timeArr = [moment(this.form.publish_time.time).hours(), moment(this.form.publish_time.time).minutes()];
                selectedDay = moment(this.form.start_time.date).format('DD');
                curDay = moment(this.form.publish_time.date).format('DD');
            } else if (type == 'end_time') {
                timeArr = [moment(this.form.start_time.time).hours(), moment(this.form.start_time.time).minutes()];
                selectedDay = moment(this.form.end_time.date).format('DD');
                curDay = moment(this.form.start_time.date).format('DD');
            } else if (type == 'result_time') {
                timeArr = [moment(this.form.end_time.time).hours(), moment(this.form.end_time.time).minutes()];
                selectedDay = moment(this.form.result_time.date).format('DD');
                curDay = moment(this.form.end_time.date).format('DD');
            } else if (type == 'prize_active_etime') {
                timeArr = [
                    moment(this.form.prize_active_stime.time).hours(),
                    moment(this.form.prize_active_stime.time).minutes()
                ];
                selectedDay = moment(this.form.prize_active_etime.date).format('DD');
                curDay = moment(this.form.prize_active_stime.date).format('DD');
            } else if (type == 'prize_active_stime') {
                timeArr = [
                    moment(this.form.prize_active_etime.time).hours(),
                    moment(this.form.prize_active_etime.time).minutes()
                ];
                selectedDay = moment(this.form.prize_active_stime.date).format('DD');
                curDay = moment(this.form.prize_active_etime.date).format('DD');
            }
            var MINITES = [];
            for (var i = 0; i < 60; i++) {
                MINITES.push(i);
            }
            let minutes = [];
            let MINITESDATE = [];
            if (parseInt(selectedDay) == curDay && selectedHour == parseInt(timeArr[0])) {
                for (var j = 0; j < parseInt(timeArr[1]); j++) {
                    minutes.push(j);
                }
            }
            for (var k = 0; k < MINITES.length; k++) {
                if (type == 'publish_time' && minutes.indexOf(k) >= 0) {
                    MINITESDATE.push(k);
                } else if ((type == 'start_time' || type == 'end_time') && minutes.indexOf(k - 1) >= 0) {
                    MINITESDATE.push(k);
                } else if (type == 'result_time' && minutes.indexOf(k - 5) >= 0) {
                    MINITESDATE.push(k);
                }
                if (type == 'prize_active_stime' && this.form.prize_active_etime.time != '') {
                    if (
                        parseInt(selectedDay) == curDay &&
                        selectedHour == parseInt(timeArr[0]) &&
                        minutes.indexOf(k) <= 0
                    ) {
                        MINITESDATE.push(k);
                    }
                } else {
                    if (minutes.indexOf(k) >= 0) {
                        MINITESDATE.push(k);
                    }
                }
            }
            return MINITESDATE;
        },
        disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().startOf('day');
        },
        disabledStartDate(current) {
            // Can not select days before today and today
            return current && current.valueOf() <= this.form.publish_time.date.valueOf();
        },
        disabledEndDate(current) {
            // Can not select days before today and today
            return current && current.valueOf() <= this.form.start_time.date.valueOf();
        },
        disabledResultDate(current) {
            // Can not select days before today and today
            return current && current.valueOf() <= this.form.end_time.date.valueOf();
        },
        stimeCheck() {
            if (!this.prize_stime_checked) {
                this.form.prize_active_stime.date = this.form.prize_active_stime.time = null;
            }
        },
        etimeCheck() {
            if (!this.prize_etime_checked) {
                this.form.prize_active_etime.date = this.form.prize_active_etime.time = null;
            }
        },
        disabledActiveStartDate(startValue) {
            const endValue = this.form.prize_active_etime.date;
            if (!startValue || !endValue) {
                return false;
            }
            return startValue.valueOf() > endValue.valueOf();
        },
        disabledActiveEndDate(endValue) {
            const startValue = this.form.prize_active_stime.date;
            if (!endValue || !startValue) {
                return false;
            }
            return startValue.valueOf() >= endValue.valueOf();
        },
        handleStartOpenChange(open) {
            if (!open && this.prize_etime_checked) {
                this.endOpen = true;
            }
        },
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
        onMerchantBlur(e) {
            const user_name = e && e.target.value;
            if (user_name) {
                this.getStoreInfo({
                    user_name
                }).then(res => {
                    console.log(res)
                    if (res.sid) {
                        this.form.sid = res.sid;
                        this.form.store_name = res.store_name;
                    } else {
                        this.$message.error('The user does not exist, please fill in again');
                        this.$refs.user_name.resetField();
                        document.getElementById("user_name").focus();
                    }
                })
            }
        },
        upload(url) {
            this.form.event_photo_url = url;
        },
        onServiceChange(e) {
            const type = this.form.service_fee_type;
            if (type === 'percentage'){
                this.form.service_fee_value1 = 0;
            } else {
                this.form.service_fee_value2 = 0;
            }
        },
        onSubmit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let obj = { ...this.form };
                    if (obj.need_pay === 0){
                        obj.price = 0;
                        obj.tax = 0;
                        obj.service_fee_type = 'fixed';
                        obj.service_fee_value = 0
                    } else {
                        obj.service_fee_value = obj.service_fee_type === 'fixed' ? obj.service_fee_value1 : obj.service_fee_value2 / 100;
                        obj.tax = obj.tax / 100;
                    }
                    const _obj = this.parseTime(obj);
                    let params = { ...obj, ..._obj, create_time: Date.parse(new Date()), event_status: 0 };
                    this.addDraw({
                        ...params,
                        operate_user: window.localStorage.getItem('uname') || '',
                        cb: this.cancel
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        onEditSubmit() {
            this.updateDraw({
                id: this.draw_id,
                event_description: this.form.event_description,
                prize_description: this.form.prize_description,
                prize_guidelines: this.form.prize_guidelines,
                operate_user: window.localStorage.getItem('uname') || '',
                cb: this.cancel
            });
            window.localStorage.setItem('has_edit_id', this.draw_id);
        },
        parseTime(obj) {
            const p_date = moment(obj.publish_time.date).format('YYYY-MM-DD');
            const p_time = moment(obj.publish_time.time).format('HH:mm');
            const s_date = moment(obj.start_time.date).format('YYYY-MM-DD');
            const s_time = moment(obj.start_time.time).format('HH:mm');
            const e_date = moment(obj.end_time.date).format('YYYY-MM-DD');
            const e_time = moment(obj.end_time.time).format('HH:mm');
            const r_date = moment(obj.result_time.date).format('YYYY-MM-DD');
            const r_time = moment(obj.result_time.time).format('HH:mm');
            const p_a_sdate = moment(obj.prize_active_stime.date).format('YYYY-MM-DD');
            const p_a_stime = moment(obj.prize_active_stime.time).format('HH:mm');
            const p_a_edate = moment(obj.prize_active_etime.date).format('YYYY-MM-DD');
            const p_a_etime = moment(obj.prize_active_etime.time).format('HH:mm');
            return {
                publish_time: moment(p_date + ' ' + p_time).valueOf(),
                start_time: moment(s_date + ' ' + s_time).valueOf(),
                end_time: moment(e_date + ' ' + e_time).valueOf(),
                result_time: moment(r_date + ' ' + r_time).valueOf(),
                prize_active_stime: this.prize_stime_checked ? moment(p_a_sdate + ' ' + p_a_stime).valueOf() : '',
                prize_active_etime: this.prize_etime_checked ? moment(p_a_edate + ' ' + p_a_etime).valueOf() : ''
            };
        },
        cancel() {
            //this.$refs.ruleForm.resetFields();
            this.$router.go(-1);
        },
        ...mapActions({
            addDraw: 'draw/addDraw',
            getStoreInfo: 'draw/getStoreInfo',
            updateDraw: 'draw/updateDraw',
            getDrawById: 'draw/getDrawById',
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        })
    }
};
</script>
