<template lang="html">
    <div id="app">
        <router-view v-if="is_login"></router-view>
        <!-- <p style="display: none;">{{ msg_opts }}</p> -->
        <div v-if="!is_login">
            <TopBar></TopBar>
            <div class="body blur_row side_l" style="padding-top: 0; position: relative; background: #fff;">
                <div class="col_main">
                    <div class="col_main_inner">
                        <router-view v-if="isRouterAlive"></router-view>
                    </div>
                </div>
                <div class="loading-layer" v-show="is_loading">
                    <a-spin size="large" style="position: absolute; top: 50%; left: 50%;"></a-spin>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.loading-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #eee;
    opacity: 0.7;
}
.body {
    padding-bottom: 40px;
    box-sizing: border-box;
}

.body {
    padding-top: 40px;
}

.col_side {
    min-height: 1px;
    width: 230px;
    float: left;
}

.col_main {
    overflow: hidden;
    min-height: 700px;
}

.col_main .col_main_inner {
    margin: 0 auto;
}
.head_title {
    color: rgba(0, 0, 0, 1);
    font-family: 'Helvetica-Bold';
    font-size: 24px;
    text-align: center;
    margin-top: 35px;
}
</style>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from 'vue-sweetalert2';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { setLang } from '@/locales';

    import TopBar from '../components/TopBar';

    import Spin from "ant-design-vue/lib/spin";
    import "ant-design-vue/lib/spin/style/css";

    Vue.use(Spin);
    Vue.use(VueSweetalert2);

    let HAS_ALERT = false;

    export default {
        name: 'app',
        components: {
            TopBar
        },
        data() {
            return {
                uname: '',
                is_login: this.$router.history.current.path.indexOf('/login') > -1 ? true : false,
                is_loading: false,
                isRouterAlive: true
            };
        },
        provide () { 
            return {
                reload: this.reload
            }
        },
        beforeMount() {
            this.is_login = this.$router.history.current.path.indexOf('/login') > -1 ? true : false;
            let _token = window.localStorage.getItem('token') || '';

            if (!this.is_login && !_token) {
                this.$router.replace({ path: "login" });
            }

            let _uname = window.localStorage.getItem('uname') || '';
            this.uname = _uname;
        },
        updated() {},
        beforeCreate() {},
        created() {
        },
        watch: {
            '$route'() {
                this.is_login = this.$router.history.current.path.indexOf('/login') > -1 ? true : false;
                window['__logger__'] && window['__logger__']({
                    view: 1,
                    click: 0
                });

                if (!this.is_login) {
                    console.log('path', this.$router.history.current.path);
                    if (this.role == 'assist' && (this.$router.history.current.path.indexOf('/master') == -1 && this.$router.history.current.path.indexOf('/monitor') == -1)) {
                        this.$router.replace({ path: '/master/list' });
                    } else if (this.role == 'admin' && this.$router.history.current.path == '/guests') {
                        this.$router.replace({ path: '/feedback' });
                    }
                }
            },
            msg_opts() {
                if (this.msg_opts && !HAS_ALERT) {
                    let temp_opts = Object.assign({
                        type: 'error',
                        showConfirmButton: false,
                        timer: 2000
                    }, this.msg_opts);
                    HAS_ALERT = true;
                    this.$swal(temp_opts).then(() => {
                        this.set_msg(null);
                        HAS_ALERT = false;
                    });
                }
            }
        },
        computed: {
            ...mapGetters({
                msg_opts: 'app/get_app_msg',
                role: 'app/get_role'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                initData: 'app/initData',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            reload() {
                this.isRouterAlive = false;
                this.$nextTick(function() {
                    this.isRouterAlive = true;
                });
            }
        }
    };
</script>
