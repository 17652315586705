import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import app from './modules/app';
import login from './modules/login';
import guest from './modules/guest';
import feedback from './modules/feedback';
import draw from './modules/draw';
import order from './modules/order';
import content from './modules/content';
import data from './modules/data';
import ad from './modules/ad';
import market from './modules/market';
import master from './modules/master';
import monitor from './modules/monitor';
import boost from './modules/boost';

Vue.use(Vuex);

const state = {
    debug:
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost' ||
        window.location.href.indexOf('http://1') === 0
};

const getters = {
    serverRoot(state) {
        const localServerPort = '5050';
        const { hostname, protocol } = window.location;
        return `${protocol}//${hostname}${state.debug ? ':' + localServerPort : ''}`;
    }
};

state.is_development = state.debug || window.location.hostname === 'client.minitable.link';

export default new Vuex.Store({
    modules: {
        app,
        login,
        guest,
        feedback,
        draw,
        order,
        content,
        data,
        ad,
        market,
        master,
        monitor,
        boost
    },
    state,
    getters,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
});
