<template>
    <div class="container">
        <div class="row justify-content-center">
            <main class="col-12 col-md-5 col-xl-4 my-5">
                <div class="bg-white rounded shadow-7 w-400 mw-100 p-6">
                    <h1 class="display-4 text-center mb-3">
                        Sign in
                    </h1>
                    <p class="text-muted text-center mb-5">
                        Minitable restaurant dashboard
                    </p>
                    <div>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input class="form-control" v-model="user_id" type="text" name="username" placeholder="Username">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" v-model="pswd" type="password" name="password" placeholder="Password">
                        </div>
                        <button style="height: 48px;" class="btn btn-lg btn-block btn-primary mb-3" @click="do_login">
                            Sign in
                        </button>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<style>
</style>

<script>
    import '../assets/css/theme.min.css';
    import md5 from 'md5';
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'login',
        components: {
        },
        data() {
            return {
                sid: this.$route.query.sid || '',
                user_id: '',
                pswd: '',
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            // console.log('sid', _sid, _token)

            if (_token) {
                this.$router.replace({ path: "/guests" });
            }
        },
        async created() {
        },
        watch: {},
        mounted() {
            
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            do_login() {
                if (!this.user_id || !this.pswd) {
                    this.set_msg({ title: '请输入用户名或密码' });
                    return;
                }
                this.do_login_aj({
                    user_id: this.user_id,
                    pswd: md5(this.pswd),
                    router: this.$router
                });
            },
            ...mapActions({
                do_login_aj: 'login/do_login_aj'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
        }
    };
</script>
