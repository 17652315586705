<template>
    <div class="main_bd">
        <div class="head_title">
            Bird Message Status
            <span>(in the past 1 hours)</span>
            <span>
                <a-spin v-if="is_loading" style="margin-left: 20px;"/>
            </span>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="4">
                <a-statistic title="Total" :value="count_dict.total || 0" style="margin-right: 50px"></a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sending" :value="count_dict.sending || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Delivered" :value-style="{ color: '#3f8600' }" :value="count_dict.delivered || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Failed" :value-style="{ color: '#cf1322' }" :value="count_dict.failed || 0" class="demo-class"></a-statistic>
            </a-col>
        </a-row>
        <div class="action_content" style="margin-top: 20px;">
            <div>
                <span style="padding-right: 0px;">Merchant ID: </span>
                <a-input class="custom_input" v-model="sid" placeholder="Merchant id"></a-input>

                <span style="padding-left: 30px;">From Phone: </span>
                <a-input class="custom_input" v-model="from_phone_num" placeholder="+1123456789"></a-input>

                <span style="padding-left: 30px;">To Phone: </span>
                <a-input class="custom_input" v-model="to_phone_num" placeholder="+1123456789"></a-input>

                <span style="padding-left: 30px;">Status: </span>
                <a-select v-model="status" style="width: 150px">
                    <a-select-option value="">All</a-select-option>
                    <a-select-option value="0">Sending</a-select-option>
                    <a-select-option value="1">Delivered</a-select-option>
                    <a-select-option value="2">Failed</a-select-option>
                </a-select>

                <a-button type="primary" @click="doGetMessageList" style="margin-left: 20px;">Search</a-button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="message_list" >
                <template slot="id_zone" slot-scope="text, data">
                    <p>{{ `Merchant ID: ${data.sid}` }}</p>
                    <p>{{ `Message ID: ${data.message_id}` }}</p>
                </template>
                <template slot="status_zone" slot-scope="text, data">
                    <a-tag color="green" v-if="data.status == 1">
                        Delivered
                    </a-tag>
                    <a-tag color="grey" v-else-if="data.status == 0">
                        Sending
                    </a-tag>
                    <a-tag color="red" v-else-if="data.status == 2">
                        Failed
                    </a-tag>
                    <span v-if="data.status == 2">({{ data.platform_status }})</span>
                    <span v-if="data.status == 2">({{ 'details: ' + data.msg }})</span>
                </template>
                <template slot="action_zone" slot-scope="text, data">
                    <span>{{ data.action }}</span>
                    <p v-if="data.action == 'two-way-message'">{{ `Response: ${data.response === '0' ? '' : data.response}` }}</p>
                </template>
                <template slot="time_zone" slot-scope="text, data">
                    {{ moment(data.createtimestamp * 1).format('HH:mm:ss') }}
                </template>
                <template slot="phone_zone" slot-scope="text, data">
                    <p>{{ `From: ${data.from_phone_num}` }}</p>
                    <p>{{ `To: ${data.to_phone_num}` }}</p>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment  from 'moment-timezone';

    const columns = [
        {
            title: "Time",
            dataIndex: "createtimestamp",
            scopedSlots: { customRender: 'time_zone' },
        },
        {
            title: "IDs",
            dataIndex: "id",
            scopedSlots: { customRender: 'id_zone' },
        },
        {
            title: "Message",
            dataIndex: "message"
        },
        {
            title: "Phone",
            dataIndex: "from_phone_num",
            scopedSlots: { customRender: 'phone_zone' },
            width: 200
        },
        {
            title: "Status",
            dataIndex: "platform_status",
            scopedSlots: { customRender: 'status_zone' },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 200,
            scopedSlots: { customRender: 'action_zone' },
        },
        {
            title: "Business id",
            dataIndex: "foreign_id"
        }
    ];

    export default {
        name: 'bird_message_list',
        components: {
        },
        data() {
            return {
                columns,
                message_list: [],
                moment,
                sid: '',
                from_phone_num: '',
                to_phone_num: '',
                foreign_id: '',
                status: '',
                is_loading: false,
                timer: null
            };
        },
        async created() {
            await this.doGetMessageList();

            this.timer = setInterval(() => {
                this.doGetMessageList();
            }, 60000);
        },
        computed: {
            ...mapGetters({}),
            ...mapState({}),
            count_dict() {
                let dict = {
                    sending: 0,
                    delivered: 0,
                    failed: 0,
                    total: this.message_list.length
                };

                this.message_list.map(item => {
                    if (item.status == 0) {
                        dict.sending += 1;
                    } else if (item.status == 1) {
                        dict.delivered += 1;
                    } else if (item.status == 2) {
                        dict.failed += 1;
                    }
                });

                return dict;
            }
        },
        methods: {
            ...mapActions({
                getBirdMessageList: 'monitor/getBirdMessageList'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async doGetMessageList() {
                this.is_loading = true;
                let res = await this.getBirdMessageList({
                    sid: this.sid,
                    from_phone_num: this.from_phone_num,
                    to_phone_num: this.to_phone_num,
                    foreign_id: this.foreign_id,
                    status: this.status
                });
                this.message_list = res.data.list;
                this.is_loading = false;
            },
        }
    };
</script>

<style>
    .custom_input{
        width: 220px !important;
    }
</style>