import querystring from 'querystring';

const interface_list = {
    get_draw_list: { url: '/weapp/minipass/draw/list/get', method: 'get' },
    get_draw_by_id: { url: '/weapp/minipass/draw/get_draw_by_id/get', method: 'get' },
    draw_action_log: {url: '/weapp/minipass/draw/draw_action_log/get', method: 'get'},
    add_draw: { url: '/weapp/minipass/draw/add/get', method: 'get' },
    update_draw: { url: '/weapp/minipass/draw/update/get', method: 'get' },
    update_event_status: { url: '/weapp/minipass/draw/update_event_status/get', method: 'get' },
    del_event: { url: '/weapp/minipass/draw/del_event/get', method: 'get' },
    get_prize_list: { url: '/weapp/minipass/prize/list/get', method: 'get' },
    update_prize_status: { url: '/weapp/minipass/prize/update_prize_status/get', method: 'get' },
    get_draw_enrollment_count: { url: '/weapp/minipass/draw/enrollment/count/get', method: 'get'},
    get_feedback_list: { url: '/weapp/minipass/feedback/list/get', method: 'get' },
    get_content_list: { url: '/weapp/minipass/content/list/get', method: 'get' },
    get_guest_list: {url: '/weapp/minipass/guest/list/get', method: 'get'},
    get_guest_by_id: { url: '/weapp/minipass/guest/get_guest_by_id/get', method: 'get' },
    get_count_param: { url: '/weapp/minipass/guest/wait_reserve_count/get', method: 'get' },
    get_pass_count: {url: '/weapp/minipass/guest/get_pass_count/get', method: 'get'},
    action_log: {url: '/weapp/minipass/guest/action_log/get', method: 'get'},
    get_order_list: { url: '/weapp/minipass/order/list/get', method: 'get' },
    get_order_by_id: { url: '/weapp/minipass/order/get_order_by_id/get', method: 'get' },
    to_refund: { url: '/weapp/minipass/order/to_refund/get', method: 'get' },

    loginIn: { url: '/weapp/business/login/get', method: 'get' },
    get_survey_list: { url: '/weapp/minipass/survey/list/get', method: 'get' },
    get_survey_data: { url: '/weapp/minipass/survey/data/get', method: 'get' },
};

function fetchApi({ request_name, params, customHeaders = {}, aj_host = '' }) {
    const conf = JSON.parse(JSON.stringify(interface_list[request_name]));
    const token = window.localStorage.getItem('token') || '';

    if (!conf) {
        return Promise.reject({
            code: 0,
            msg: '',
            data: {
                code: 100010,
                msg: 'no ajax register',
                data: {}
            }
        });
    }

    const headers = new Headers();

    Object.keys(customHeaders).forEach((k) => {
        headers.append(k, customHeaders[k]);
    });

    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    headers.append('Authorization', 'Bearer ' + token);

    const fetch_optins = {
        method: conf.method,
        headers,
        credentials: 'omit'
    };

    if (conf.method == 'get') {
        conf.url = conf.url + '?' + querystring.stringify(params);
    } else if (conf.method == 'post') {
        const searchParams = Object.keys(params)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

        fetch_optins.body = searchParams;
    }

    return fetch(aj_host + conf.url, fetch_optins)
        .then((response) => {
            if (response.status >= 400) {
                throw new Error('Bad response from server');
            }

            if (response.headers.get('Authorization')) {
                let token = response.headers.get('Authorization').split(' ')[1];
                if (token) {
                    window.localStorage.setItem('token', token);
                }
            }
            return response.json();
        })
        .then((data) => {
            if (data.code == -1) {
                Promise.reject({
                    code: 0,
                    msg: '',
                    data: {
                        code: 100010,
                        msg: response.error,
                        data: {}
                    }
                });
                return;
            }

            if (data.data.code === 100000) {
                return Promise.resolve(data);
            } else if (data.data.code === 100002) {
                window.localStorage.removeItem('sid');
                window.localStorage.removeItem('token');
                window.location.replace('/login');
                return Promise.reject(data);
            } else {
                return Promise.reject(data);
            }
        })
        .catch((err) => {
            // console.log(err);
            return Promise.reject(err);
        });
}

export default ({ commit }, opts) => {
    return fetchApi(opts)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((res) => {
            console.log('res===', res)
            commit && commit('app/APP_SET_MSG', { msg: res.data.msg, type: 'error' }, { root: true });
            return Promise.reject(res);
        });
};
