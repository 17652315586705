<template>
    <div class="main_bd">
        <div class="head_title">Boost</div>
        <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="merchant">Merchant management </a-menu-item>
            <a-menu-item key="boost"> Boost Today  </a-menu-item>
            <a-menu-item key="boost_history"> Boost History </a-menu-item>
            <a-menu-item key="transaction" > IAP Transaction </a-menu-item>
            <a-menu-item key="robot"> Robot Management  </a-menu-item>
        </a-menu>
        <div class="mod_default_box permission">
            <a-button style="margin-bottom: 10px;" type="primary" @click="show_add_layer = true">Add New Robot</a-button>
            <a-table :pagination="false" :rowKey="data => data.sid" :columns="columns" :data-source="robot_list" >
                <div slot="action" slot-scope="text, data">
                    <a-button type="primary" @click="del_robot(data)">Delete</a-button>
                </div>
            </a-table>
        </div>
        <a-modal
            title="Add New Robot"
            :visible="show_add_layer"
            @ok="handleOk"
            @cancel="handleCancel"
            :width="800"
            style="height: 300px; overflow: auto;"
            :confirm-loading="is_adding"
            >
            <a-form-model ref="ruleForm"  :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-form-model-item ref="phone" label="Phone" prop="phone">
                    <a-select style="width: 100px;" v-model="form.code" :maxLength="30">
                        <a-select-option value="+1">+1</a-select-option>
                        <a-select-option value="+86">+86</a-select-option>
                    </a-select>
                    <a-input style="width: 70%; margin-left: 10px;" v-model="form.phone" placeholder="Please input phone num" :maxLength="30" />
                </a-form-model-item>
                <a-form-model-item ref="name" label="Name" prop="name">
                    <a-input v-model="form.name" placeholder="Please input name" :maxLength="30" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            width: 320
        },
        {
            title: "Phone",
            dataIndex: "phone"
        },
        {
            title: "name",
            dataIndex: "name"
        },
        {
            title: 'Action',
            dataIndex: '',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'robot_merchant_list',
        components: {
        },
        data() {
            return {
                is_adding: false,
                search: '',
                current: ['robot'],
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                robot_list: [],
                show_add_layer: false,
                form: {
                    phone: '',
                    name: '',
                    code: '+1'
                },
                rules: {
                    phone: [{ required: true, message: 'Please input phone', trigger: 'blur' } ],
                    name: [{ required: true, message: 'Please input customer name', trigger: 'blur' }],
                },
                formLayout: 'horizontal',
            };
        },
        beforeMount() {},
        async created() {
            this.get_robot_list();
        },
        watch: {
            current() {
                if (this.current == 'boost') {
                    this.$router.push({
                        path: '/market/boost/sales'
                    });
                } else if (this.current == 'merchant') {
                    this.$router.push({
                        path: '/market/boost/merchants'
                    });
                } else if (this.current == 'boost_history') {
                    this.$router.push({
                        path: '/market/boost/history'
                    });
                } else if (this.current == 'transaction') {
                    this.$router.push({
                        path: '/market/boost/transaction'
                    });
                }
            }
        },
        mounted() {
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getBoostUserList: 'boost/getBoostUserList',
                addBoostUser: 'boost/addBoostUser',
                delBoostUser: 'boost/delBoostUser',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_robot_list() {
                let { list } = await this.getBoostUserList({});

                this.robot_list = list;
            },
            async add_robot() {
                this.is_adding = true;

                let post_data = {
                    phone: this.form.code + ' ' + this.form.phone,
                    name: this.form.name
                };

                let res = await this.addBoostUser(post_data);
                this.is_adding = false;

                if (res.code == 100000) {
                    this.$message.success('Add robot success');
                    this.show_add_layer = false;
                    this.get_robot_list();
                } else {
                    this.$message.error(res.msg || 'Create boost failed');
                }
            },
            handleCancel() {
                this.show_add_layer = false;
            },
            handleOk() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        this.add_robot();
                    } else {
                        return false;
                    }
                });
            },
            show_create_boost_layer(data) {
                this.show_add_layer = true;
                this.layer_data = data;
            },
            async del_robot(data) {
                this.$confirm({
                    title: 'Delete Robot',
                    content: 'Are you sure to delete this robot?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk: async () => {
                        let res = await this.delBoostUser({ phone: data.phone });
                        if (res.code == 100000) {
                            this.$message.success('Delete robot success');
                            this.get_robot_list();
                            this.handleCancel();
                        } else {
                            this.$message.error(res.msg || 'Delete robot failed');
                        }
                    },
                    onCancel() {
                    },
                });
            },
        }
    };
</script>

<style>
</style>
