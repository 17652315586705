<template>
    <div class="main_bd">
        <div class="head_title">Survey List</div>
        <div class="fileter">
            <div style="margin-top: 10px">
                <span class="date">
                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                </span>
                <span style="margin: 0 10px">to</span>
                <span class="date">
                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" :disabled-date="disabledEndDate" />
                </span>
                <button class="done_btn" @click="fliterData">Done</button>
            </div>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="6">
                <a-statistic title="Total Count" :value="survey_data.total_count || 0" style="margin-right: 50px"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Survey Count" :value="survey_data.survey_count || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Quick Review Count" :value="survey_data.quick_review_count || 0" class="demo-class"></a-statistic>
            </a-col>
            <a-col :span="6">
                <a-statistic title="Merchants Count" :value="survey_data.merchant_count || 0" class="demo-class"></a-statistic>
            </a-col>
        </a-row>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey='data => data.id' :columns="columns" :data-source="survey_list">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.idx }}</p>
                </a>
                <template slot="action" slot-scope="text, data">
                    <a-button type="primary" size="small" style="background-color: #6200eed9; border-color: #6200eed9"
                        @click="toDetail(data)">
                        details
                    </a-button>
                </template>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="10" v-model="page" :total="total_count"
                show-less-items />
        </div>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Survey result details</div>
                        <div>Phone: <span>{{ item.tel }}</span></div>
                    </div>
                    <div style="padding-top: 38px;">Survey id: {{ item.fb_id }}</div>
                    <div style="padding-top: 38px;">{{ item.create_time }}</div>
                </div>
            </template>
            <div v-if="item.source != 'quick review'">
                <a-descriptions>
                    <div slot="title">How did you get to know about our restaurant?</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.known_method }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">Was this your first time visiting us?</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.first_time == 1 ? 'Yes' : 'No' }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">How was the food today?</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.food_star }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">How was our service today?</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.service_star }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">Suggestion</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.suggestion }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <div v-else>
                <a-descriptions>
                    <div slot="title">Oid</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.oid + '   ' + (item.scan_table_star >= 0 ? '(Scan Table)' : '(Online Ordering)') }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">Table Name</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.tname }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">Star</div>
                    <a-descriptions-item v-if="item.scan_table_star" label="" :span="3">
                        {{ item.scan_table_star }}
                    </a-descriptions-item>
                    <a-descriptions-item v-if="item.online_ordering_star" label="" :span="3">
                        {{ item.online_ordering_star }}
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions>
                    <div slot="title">Comment</div>
                    <a-descriptions-item label="" :span="3">
                        {{ item.scan_table_comment || item.online_ordering_comment }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <template slot="footer">
                <a-button type="primary" @click="handleOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 60px;margin-right: 300px;">
                    Done
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<style>
.in_title {
    font-size: 16px;
    text-align: center;
}
.fileter {
    text-align: right;
    margin-top: 30px;
    font-size: 16px;
}
.date {
    display: inline-block;
    width: 200px;
}
.done_btn {
    width: 82px;
    height: 28px;
    border: none;
    border-radius: 8px;
    background-color: rgba(116, 39, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Helvetica';
    font-size: 16px;
    margin-left: 10px;
}
</style>

<script>
import Vue from 'vue';

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import moment from "moment";

const columns = [
    {
        title: "No.",
        dataIndex: "idx",
        scopedSlots: { customRender: "idx" },
        width: 200
    },
    {
        title: "Merchant Name",
        dataIndex: "sname"
    },
    {
        title: "User Phone",
        dataIndex: "tel"
    },
    {
        title: "Customer Name",
        dataIndex: "customer_name"
    },
    {
        title: "Submit date",
        dataIndex: "create_time",
        width: 300
    },
    {
        title: "Source",
        dataIndex: "source"
    },
    {
        title: "Action",
        dataIndex: "action",
        scopedSlots: { customRender: "action" }
    }
];

export default {
    name: 'survey',
    components: {
    },
    data() {
        return {
            columns,
            page: 1,
            start_date: '',
            end_date: '',
            item: {},
            detailVisible: false
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    async created() {
        this.getSurveyList({
            page: 1
        });

        this.getSurveyData();
    },
    watch: {
    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            survey_list: "feedback/get_survey_list",
            total_page: "feedback/get_total_survey_page",
            total_count: "feedback/get_total_survey_count",
            survey_data: "feedback/get_survey_data"
        }),
        ...mapState({
        })
    },
    methods: {
        ...mapActions({
            getSurveyList: 'feedback/getSurveyList',
            getSurveyData: 'feedback/getSurveyData'
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        }),
        disabledEndDate(current) {
            return current && current.valueOf() <= moment(this.start_date).valueOf();
        },
        fliterData(){
            this.getSurveyList({
                page: this.page,
                start_date: this.start_date ? moment(this.start_date).valueOf() : null,
                end_date: this.end_date ? moment(this.end_date).valueOf() : null
            });
        },
        toDetail(data) {
            this.item = { ...data };
            this.detailVisible = true;
        },
        handleOk() {
            this.detailVisible = false;
        },
        turnPage(e) {
            this.getSurveyList({
                page: this.page,
                start_date: this.start_date ? moment(this.start_date).valueOf() : null,
                end_date: this.end_date ? moment(this.end_date).valueOf() : null
            });
        }
    }
};
</script>
