<template>
    <div class="main_bd">
        <div style="margin: 40px 0">
            <a-breadcrumb>
                <span slot="separator" style="color: #000; font-size: 22px">></span>
                <router-link :to="'/adlist'">
                    <a-breadcrumb-item class="b-item-1">Marketing Center</a-breadcrumb-item>
                </router-link>
                <a-breadcrumb-item class="b-item-2">{{ ad_id ? 'Edit the Ad campaign' : 'Create a new Ad campaign' }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="permission">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
                <div class="form-item">
                    <div class="form-title">Basic settings</div>
                    <a-form-model-item ref="ad_name" label="Campaign Name" prop="ad_name">
                        <a-input v-model="form.ad_name" placeholder="No more than 75 characters" @blur="
                            () => {
                                $refs.ad_name.onFieldBlur();
                            }
                        " :maxLength="75" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item ref="ad_type" label="Campaign Type" prop="ad_type">
                        <a-radio-group v-model="form.ad_type" :disabled="status != 0">
                            <a-radio value="general">
                                General
                            </a-radio>
                            <a-radio value="poster">
                                Custom Poster
                            </a-radio>
                            <a-radio value="system_coupon">
                                System coupon
                            </a-radio>
                            <a-radio value="external_coupon">
                                External coupon
                            </a-radio>
                            <a-radio value="full_screen">
                                Full screen
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </div>
                <div class="form-item">
                    <div class="form-title">Campaign details</div>
                    <a-form-model-item v-if="this.form.ad_type == 'general' || this.form.ad_type == 'full_screen' || this.form.ad_type == 'external_coupon' || this.form.ad_type == 'system_coupon'" ref="ad_general_tilte" label="Ad title" prop="ad_general_tilte">
                        <a-input v-model="form.ad_general_tilte" placeholder="No more than 75 characters" @blur="
                            () => {
                                $refs.ad_general_tilte.onFieldBlur();
                            }
                        " :maxLength="75" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'general' || this.form.ad_type == 'full_screen' || this.form.ad_type == 'external_coupon' || this.form.ad_type == 'system_coupon'" ref="ad_general_cta" label="CTA Copy Text" prop="ad_general_cta">
                        <a-input v-model="form.ad_general_cta" placeholder="No more than 20 characters" @blur="
                            () => {
                                $refs.ad_general_cta.onFieldBlur();
                            }
                        " :maxLength="20" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_merchant_username" label="Merchant username" prop="ad_merchant_username">
                        <a-input v-model="form.ad_merchant_username" placeholder="Merchant username" @blur="
                            () => {
                                $refs.ad_merchant_username.onFieldBlur();
                            }
                        " :maxLength="256" :disabled="status != 0" />
                        <a-button type="link" @click="get_store_info">Check</a-button>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_merchant_sid" label="Merchant Sid" prop="ad_merchant_sid">
                        <a-input v-model="form.ad_merchant_sid" placeholder="Merchant sid" :disabled="true || status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_merchant_sname" label="Merchant name" prop="ad_merchant_sname">
                        <a-input v-model="form.ad_merchant_sname" placeholder="Merchant name" :disabled="true || status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_merchant_slogo" label="Merchant logo" prop="ad_merchant_slogo">
                        <a-input v-model="form.ad_merchant_slogo" placeholder="Merchant logo" :disabled="true || status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_coupon_name" label="Reward Name" prop="ad_coupon_name">
                        <a-input v-if="false" v-model="form.ad_coupon_id" placeholder="reward id" @blur="
                            () => {
                                $refs.ad_coupon_id.onFieldBlur();
                            }
                        " :maxLength="128" :disabled="status != 0" />
                        <a-select :value="this.form.ad_coupon_name" style="width: 100%" placeholder="Select coupon" @change="handleChange">
                            <a-select-option v-for="item in coupon_list" :key="item.id" :value="item.coupon_id">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'system_coupon'" ref="ad_coupon_id" label="Reward id" prop="ad_coupon_id">
                        <a-input v-model="form.ad_coupon_id" placeholder="Reward id" :disabled="true || status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'general' || this.form.ad_type == 'full_screen' || this.form.ad_type == 'external_coupon' || this.form.ad_type == 'system_coupon'" label="Tumbnail Image" prop="ad_general_image" extra="image up to 5MB, square only. JPG, PNG only">
                        <upload-img :disabled="status != 0" @upload="upload_general" :photo="form.ad_general_image"></upload-img>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'poster'" label="Poster Image" prop="ad_poster_image" extra="image up to 5MB, image size: 375 * 150. JPG, PNG only">
                        <upload-img :disabled="status != 0" :cutwidth="375" :cutheight="150" @upload="upload_poster" :photo="form.ad_poster_image"></upload-img>
                    </a-form-model-item>

                    <a-form-model-item v-if="this.form.ad_type == 'external_coupon'" ref="ad_external_coupon_title" label="Coupon title" prop="ad_external_coupon_title">
                        <a-input v-model="form.ad_external_coupon_title" placeholder="No more than 25 characters" @blur="
                            () => {
                                $refs.ad_external_coupon_title.onFieldBlur();
                            }
                        " :maxLength="25" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'external_coupon'" ref="ad_external_coupon_subtitle" label="Coupon subtitle" prop="ad_external_coupon_subtitle">
                        <a-input v-model="form.ad_external_coupon_subtitle" placeholder="No more than 40 characters" @blur="
                            () => {
                                $refs.ad_external_coupon_subtitle.onFieldBlur();
                            }
                        " :maxLength="40" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'external_coupon'" ref="ad_external_coupon_source" label="Coupon source" prop="ad_external_coupon_source">
                        <a-input v-model="form.ad_external_coupon_source" placeholder="No more than 40 characters" @blur="
                            () => {
                                $refs.ad_external_coupon_source.onFieldBlur();
                            }
                        " :maxLength="40" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.ad_type == 'external_coupon'" ref="ad_external_coupon_theme_color" label="Theme color code" prop="ad_external_coupon_theme_color">
                        <a-input v-model="form.ad_external_coupon_theme_color" placeholder="No more than 40 characters" @blur="
                            () => {
                                $refs.ad_external_coupon_theme_color.onFieldBlur();
                            }
                        " :maxLength="40" :disabled="status != 0" />
                    </a-form-model-item>
                </div>

                <div class="form-item">
                    <div class="form-title">Redirect setting</div>
                    <a-form-model-item ref="redirect_type" label="Redirect Type" prop="redirect_type">
                        <a-radio-group v-model="form.redirect_type" :disabled="status != 0">
                            <a-radio value="in_app">
                                in-app
                            </a-radio>
                            <a-radio value="external">
                                external link
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'external'" ref="redirect_ex_link" label="Redirect page link" prop="redirect_ex_link">
                        <a-input v-model="form.redirect_ex_link" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" ref="redirect_app_title" label="Page title" prop="redirect_app_title">
                        <a-input v-model="form.redirect_app_title" placeholder="No more than 75 characters" @blur="
                            () => {
                                $refs.redirect_app_title.onFieldBlur();
                            }
                        " :maxLength="75" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" ref="redirect_app_subtitle" label="Page subtitle" prop="redirect_app_subtitle">
                        <a-input v-model="form.redirect_app_subtitle" placeholder="No more than 100 characters" @blur="
                            () => {
                                $refs.redirect_app_subtitle.onFieldBlur();
                            }
                        " :maxLength="100" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" ref="redirect_app_desc" label="Description paragraph" prop="redirect_app_desc">
                        <a-textarea v-model="form.redirect_app_desc" placeholder="No more than 500 characters" @blur="
                            () => {
                                $refs.redirect_app_desc.onFieldBlur();
                            }
                        " :maxLength="500" :disabled="status != 0"/>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" ref="redirect_app_footnote" label="Footnote" prop="redirect_app_footnote">
                        <a-input v-model="form.redirect_app_footnote" placeholder="No more than 50 characters" :maxLength="50" :disabled="status != 0"/>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" label="Page Main Image" prop="redirect_app_main_image" extra="image up to 5MB, image size: 375 * 150. JPG, PNG only">
                        <upload-img :disabled="status != 0" :cutwidth="375" :cutheight="250" @upload="upload_pape_main_image" :photo="form.redirect_app_main_image"></upload-img>
                    </a-form-model-item>

                    <a-form-model-item v-if="this.form.redirect_type == 'in_app'" label="Use second redirect link" prop="redirect_app_second_redirect">
                        <a-switch v-model="form.redirect_app_second_redirect" :disabled="status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app' && this.form.redirect_app_second_redirect" ref="redirect_app_second_link" label="Redirect page link" prop="redirect_app_second_link">
                        <a-input v-model="form.redirect_app_second_link" :maxLength="256" :disabled="status != 0"/>
                    </a-form-model-item>
                    <a-form-model-item v-if="this.form.redirect_type == 'in_app' && this.form.redirect_app_second_redirect" ref="redirect_app_second_cta" label="CTA copy text" prop="redirect_app_second_cta">
                        <a-input v-model="form.redirect_app_second_cta" placeholder="No more than 20 characters" :maxLength="20" :disabled="status != 0"/>
                    </a-form-model-item>
                </div>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 14 }">
                    <a-space>
                        <a-button type="link" class="" @click="cancel"> {{ status == 0 ? 'Cancel' : 'Back' }} </a-button>
                        <a-button v-if="status == 0" @click="submitDraft"> Save to draft </a-button>
                        <a-button v-if="status == 0" type="primary" class="create-btn" @click="submitPublish"> Save and publish </a-button>
                    </a-space>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import UploadImg from '../../components/UploadImg.vue';

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment";

    export default {
        name: 'adCreate',
        components: { UploadImg },
        data() {
            return {
                sid: window.localStorage.getItem('sid'),
                labelCol: { span: 3 },
                wrapperCol: { span: 12 },
                ad_id: '',
                form: {
                    ad_name: '',
                    ad_type: 'general',
                    ad_general_tilte: '',
                    ad_general_cta: '',
                    ad_general_image: '',
                    ad_poster_image: '',
                    ad_external_coupon_title: '',
                    ad_external_coupon_subtitle: '',
                    ad_external_coupon_source: '',
                    ad_external_coupon_theme_color: '',
                    ad_merchant_username: '',
                    ad_merchant_sid: '',
                    ad_merchant_sname: '',
                    ad_merchant_slogo: '',
                    ad_coupon_id: '',
                    ad_coupon_name: '',
                    ad_coupon_token: '',
                    ad_coupon_tags: '',
                    ad_coupon_describe: '',

                    redirect_type: 'in_app',
                    redirect_ex_link: '',
                    redirect_app_title: '',
                    redirect_app_subtitle: '',
                    redirect_app_desc: '',
                    redirect_app_footnote: '',
                    redirect_app_main_image: '',
                    redirect_app_second_redirect: false,
                    redirect_app_second_link: '',
                    redirect_app_second_cta: '',
                },
                rules: {
                    ad_name: [
                        { required: true, message: 'Please Campaign Name', trigger: 'blur' },
                        { min: 1, max: 75, message: 'No more than 75 characters', trigger: 'blur' }
                    ],
                    ad_general_tilte: [
                        { required: true, message: 'Please input title', trigger: 'blur' },
                        { min: 1, max: 75, message: 'No more than 75 characters', trigger: 'blur' }
                    ],
                    ad_general_cta: [
                        { required: true, message: 'Please input CTA Copy Text', trigger: 'blur' },
                        { min: 1, max: 20, message: 'No more than 20 characters', trigger: 'blur' }
                    ],
                    ad_poster_image: [
                        { required: true, message: 'Please upload poster image' },
                    ],
                    redirect_ex_link: [
                        { required: true, message: 'Please input link', trigger: 'blur' },
                    ],
                    redirect_app_title: [
                        { required: true, message: 'Please input page title', trigger: 'blur' },
                        { min: 1, max: 75, message: 'No more than 75 characters', trigger: 'blur' }
                    ],
                    redirect_app_subtitle: [
                        { required: true, message: 'Please input page subtitle', trigger: 'blur' },
                        { min: 1, max: 100, message: 'No more than 100 characters', trigger: 'blur' }
                    ],
                    redirect_app_desc: [
                        { required: true, message: 'Please input Description paragraph', trigger: 'blur' },
                        { min: 1, max: 500, message: 'No more than 500 characters', trigger: 'blur' }
                    ],
                    redirect_app_second_link: [
                        { required: true, message: 'Please input link', trigger: 'blur' },
                    ],
                    redirect_app_second_cta: [
                        { required: true, message: 'Please input second cta copy text', trigger: 'blur' },
                        { min: 1, max: 20, message: 'No more than 20 characters', trigger: 'blur' }
                    ],
                    ad_external_coupon_title: [
                        { required: true, message: 'Please input coupon title', trigger: 'blur' },
                        { min: 1, max: 25, message: 'No more than 25 characters', trigger: 'blur' }
                    ],
                    ad_external_coupon_subtitle: [
                        { required: true, message: 'Please input coupon subtitle', trigger: 'blur' },
                        { min: 1, max: 40, message: 'No more than 40 characters', trigger: 'blur' }
                    ],
                    ad_external_coupon_source: [
                        { required: true, message: 'Please input coupon source', trigger: 'blur' },
                        { min: 1, max: 40, message: 'No more than 40 characters', trigger: 'blur' }
                    ],
                    ad_external_coupon_theme_color: [
                        { required: true, message: 'Please input coupon theme color code', trigger: 'blur' },
                    ],
                    ad_merchant_username: [
                        { required: true, message: 'Please input merchant username', trigger: 'blur' },
                    ],
                    // ad_merchant_sid: [
                    //     { required: true, message: 'Please input merchant sid', trigger: 'blur' },
                    // ],
                    ad_coupon_id: [
                        { required: true, message: 'Please input reward id', trigger: 'blur' },
                    ],
                },
                status: 0,
                coupon_list: []
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            const { ad_id } = this.$route.query
            if(ad_id){
                this.ad_id = ad_id;
                let res = await this.getAdInfo({
                    ad_id: this.ad_id
                });

                let { ad_name, status, ad_type, ad_details_setting, redirect_type, redirect_setting } = res.info;

                this.form.ad_name = ad_name;
                this.form.ad_type = ad_type;

                this.form.ad_general_tilte = ad_details_setting.ad_tilte || '';
                this.form.ad_general_cta = ad_details_setting.ad_cta_text || '';
                this.form.ad_general_image = ad_details_setting.tumbnail_image_url || '';

                this.form.ad_poster_image = ad_details_setting.poster_image_url || '';

                if (this.form.ad_type == 'external_coupon') {
                    this.form.ad_external_coupon_title = ad_details_setting.coupon_title || '';
                    this.form.ad_external_coupon_subtitle = ad_details_setting.coupon_subtitle || '';
                    this.form.ad_external_coupon_source = ad_details_setting.coupon_source || '';
                    this.form.ad_external_coupon_theme_color = ad_details_setting.coupon_theme_color || '';
                } else if (this.form.ad_type == 'system_coupon') {
                    this.form.ad_merchant_username = ad_details_setting.merchant_username || '';
                    this.form.ad_merchant_sid = ad_details_setting.merchant_sid || '';
                    this.form.ad_merchant_sname = ad_details_setting.merchant_sname || '';
                    this.form.ad_merchant_slogo = ad_details_setting.merchant_slogo || '';
                    this.form.ad_coupon_id = ad_details_setting.coupon_id || '';
                    this.form.ad_coupon_name = ad_details_setting.coupon_name || '';
                    this.form.ad_coupon_token = ad_details_setting.token || '';
                    this.form.ad_coupon_tags = ad_details_setting.tags || '';
                    this.form.ad_coupon_describe = ad_details_setting.describe || '';

                    this.coupon_list.push({
                        coupon_id: ad_details_setting.coupon_id,
                        name: ad_details_setting.coupon_name,
                        token: ad_details_setting.token
                    });
                }

                this.form.redirect_type = redirect_type;
                this.form.redirect_ex_link = redirect_setting.external_link || '';

                this.form.redirect_app_title = redirect_setting.redirect_app_title || '';
                this.form.redirect_app_subtitle = redirect_setting.redirect_app_subtitle || '';
                this.form.redirect_app_desc = redirect_setting.redirect_app_desc || '';
                this.form.redirect_app_footnote = redirect_setting.redirect_app_footnote || '';
                this.form.redirect_app_main_image = redirect_setting.redirect_app_main_image || '';
                this.form.redirect_app_second_redirect = redirect_setting.redirect_app_second_redirect == 1 ? true : false;
                this.form.redirect_app_second_link = redirect_setting.redirect_app_second_link || '';
                this.form.redirect_app_second_cta = redirect_setting.redirect_app_second_cta || '';

                this.status = status;
            }
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getAdList: 'ad/getAdList',
                getAdInfo: 'ad/getAdInfo',
                submitAd: 'ad/submitAd',
                updateAdStatus: 'ad/updateAdStatus',
                getStoreInfo: 'ad/getStoreInfo',
                getCouponListBySid: 'ad/getCouponListBySid'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),

            upload_general(url) {
                this.form.ad_general_image = url;
            },
            upload_poster(url) {
                this.form.ad_poster_image = url;
            },
            upload_pape_main_image(url) {
                this.form.redirect_app_main_image = url;
            },
            cancel() {
                if (this.status != 0) {
                    this.$router.go(-1);
                    return;
                }

                this.$confirm({
                    title: 'Are you sure you want to cancel?',
                    content: 'Cannot be restored after cancellation',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        this.$router.go(-1);
                    },
                    onCancel: async () => {},
                });
            },
            async submitDraft() {
                let {
                    ad_name,
                    ad_type,
                    ad_general_tilte,
                    ad_general_cta,
                    ad_general_image,
                    ad_poster_image,
                    redirect_type,
                    redirect_ex_link,
                    ad_external_coupon_title,
                    ad_external_coupon_subtitle,
                    ad_external_coupon_source,
                    ad_external_coupon_theme_color,
                    ad_merchant_username,
                    ad_merchant_sid,
                    ad_merchant_sname,
                    ad_merchant_slogo,
                    ad_coupon_id,
                    ad_coupon_name,
                    ad_coupon_token,
                    ad_coupon_tags,
                    ad_coupon_describe,
                    ...others
                } = this.form;

                let ad_details_setting = {};
                if (ad_type == 'general') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;
                } else if (ad_type == 'full_screen') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;
                } else if (ad_type == 'poster') {
                    ad_details_setting.poster_image_url = ad_poster_image;
                } else if (ad_type == 'external_coupon') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;

                    ad_details_setting.coupon_title = ad_external_coupon_title;
                    ad_details_setting.coupon_subtitle = ad_external_coupon_subtitle;
                    ad_details_setting.coupon_source = ad_external_coupon_source;
                    ad_details_setting.coupon_theme_color = ad_external_coupon_theme_color;
                } else if (ad_type == 'system_coupon') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;

                    ad_details_setting.merchant_username = ad_merchant_username;
                    ad_details_setting.merchant_sid = ad_merchant_sid;
                    ad_details_setting.merchant_sname = ad_merchant_sname;
                    ad_details_setting.merchant_slogo = ad_merchant_slogo;
                    ad_details_setting.coupon_id = ad_coupon_id;
                    ad_details_setting.coupon_name = ad_coupon_name;
                    ad_details_setting.token = ad_coupon_token;
                    ad_details_setting.tags = ad_coupon_tags;
                    ad_details_setting.describe = ad_coupon_describe;
                }

                let redirect_setting = {};
                if (redirect_type == 'in_app') {
                    others.redirect_app_second_redirect = others.redirect_app_second_redirect ? 1 : 0;
                    others.page_type = 'template';
                    redirect_setting = others;
                } else if (redirect_type == 'external') {
                    redirect_setting.external_link = redirect_ex_link;
                }

                let post_data = {
                    ad_id: this.ad_id,
                    ad_name,
                    ad_type,
                    redirect_type,
                    redirect_setting,
                    ad_details_setting
                };

                let res = await this.submitAd(post_data);
                if (res) {
                    this.$message.success('Save successfully');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error('Save failed');
                }
            },
            async submitPublish() {
                let { ad_name, ad_type, ad_general_tilte, ad_general_cta, ad_general_image, ad_poster_image, redirect_type, redirect_ex_link, ad_external_coupon_title, ad_external_coupon_subtitle, ad_external_coupon_source, ad_external_coupon_theme_color,
                ad_merchant_username, ad_merchant_sid, ad_merchant_sname, ad_merchant_slogo, ad_coupon_id, ad_coupon_name, ad_coupon_token, ad_coupon_tags, ad_coupon_describe, ...others } = this.form;
                
                if (ad_name == '') {
                    this.$message.error('Please input AD name');
                    return;
                }

                if (ad_type == 'general' || ad_type == 'full_screen') {
                    if (ad_general_tilte == '') {
                        this.$message.error('Please input AD title');
                        return;
                    }

                    if (ad_general_cta == '') {
                        this.$message.error('Please input AD CTA');
                        return;
                    }
                } else if (ad_type == 'poster') {
                    if (ad_poster_image == '') {
                        this.$message.error('Please upload AD image');
                        // return;
                    }
                } else if (ad_type == 'system_coupon') {
                    if (ad_general_tilte == '') {
                        this.$message.error('Please input AD title');
                        return;
                    }

                    if (ad_general_cta == '') {
                        this.$message.error('Please input AD CTA');
                        return;
                    }

                    if (ad_merchant_username == '') {
                        this.$message.error('Please input merchant username');
                        return;
                    }

                    if (ad_merchant_sid == '') {
                        this.$message.error('Please input merchant sid');
                        return;
                    }

                    if (ad_coupon_id == '') {
                        this.$message.error('Please input coupon id');
                        return;
                    }
                }

                if (redirect_type == 'in_app') {
                    if (others.redirect_app_title == '') {
                        this.$message.error('Please input App title');
                        return;
                    }

                    if (others.redirect_app_subtitle == '') {
                        this.$message.error('Please input App subtitle');
                        return;
                    }

                    if (others.redirect_app_desc == '') {
                        this.$message.error('Please input App description');
                        return;
                    }

                    if (others.redirect_app_second_redirect) {
                        if (others.redirect_app_second_link == '') {
                            this.$message.error('Please input App second link');
                            return;
                        }

                        if (others.redirect_app_second_cta == '') {
                            this.$message.error('Please input App second CTA');
                            return;
                        }
                    }
                } else if (redirect_type == 'ex_link') {
                    if (redirect_ex_link == '') {
                        this.$message.error('Please input external link');
                        return;
                    }
                }

                let ad_details_setting = {};
                if (ad_type == 'general') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;
                } else if (ad_type == 'full_screen') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;
                } else if (ad_type == 'poster') {
                    ad_details_setting.poster_image_url = ad_poster_image;
                } else if (ad_type == 'external_coupon') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;
                    
                    ad_details_setting.coupon_title = ad_external_coupon_title;
                    ad_details_setting.coupon_subtitle = ad_external_coupon_subtitle;
                    ad_details_setting.coupon_source = ad_external_coupon_source;
                    ad_details_setting.coupon_theme_color = ad_external_coupon_theme_color;
                } else if (ad_type == 'system_coupon') {
                    ad_details_setting.ad_tilte = ad_general_tilte;
                    ad_details_setting.ad_cta_text = ad_general_cta;
                    ad_details_setting.tumbnail_image_url = ad_general_image;

                    ad_details_setting.merchant_username = ad_merchant_username;
                    ad_details_setting.merchant_sid = ad_merchant_sid;
                    ad_details_setting.merchant_sname = ad_merchant_sname;
                    ad_details_setting.merchant_slogo = ad_merchant_slogo;
                    ad_details_setting.coupon_id = ad_coupon_id;
                    ad_details_setting.coupon_name = ad_coupon_name;
                    ad_details_setting.token = ad_coupon_token;
                    ad_details_setting.tags = ad_coupon_tags;
                    ad_details_setting.describe = ad_coupon_describe;
                }

                let redirect_setting = {};
                if (redirect_type == 'in_app') {
                    others.redirect_app_second_redirect = others.redirect_app_second_redirect ? 1 : 0;
                    others.page_type = 'template';
                    redirect_setting = others;
                } else if (redirect_type == 'external') {
                    redirect_setting.external_link = redirect_ex_link;
                }

                let post_data = {
                    ad_id: this.ad_id,
                    ad_name,
                    ad_type,
                    ad_details_setting,
                    redirect_type,
                    redirect_setting
                };

                let res = await this.submitAd(post_data);
                this.ad_id = res.data.ad_id;

                if (res && this.ad_id) {
                    let status_res = await this.updateAdStatus({
                        ad_id: this.ad_id,
                        status: 1
                    });

                    if (status_res) {
                        this.$message.success('Submit success');
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 1000);
                    } else {
                        this.$message.error('Submit failed');
                    }
                } else {
                    this.$message.error('Submit failed');
                }
            },
            async get_store_info() {
                let { ad_merchant_username } = this.form;
                if (ad_merchant_username == '') {
                    this.$message.error('Please input merchant username');
                    return;
                }

                let res = await this.getStoreInfo({
                    user_name: ad_merchant_username
                });

                if (res && res.info) {
                    let { sid, sname, slogo } = res.info;
                    this.form.ad_merchant_sid = sid;
                    this.form.ad_merchant_sname = sname;
                    this.form.ad_merchant_slogo = slogo;
                    this.$message.success('Merchant found');

                    this.get_coupon_list();
                } else {
                    this.$message.error('Merchant not found');
                }
            },
            async get_coupon_list() {
                let { ad_merchant_sid } = this.form;

                let res = await this.getCouponListBySid({
                    sid: ad_merchant_sid
                });

                if (res && res.list) {
                    this.coupon_list = res.list;
                } else {
                    this.$message.error('Coupon not found');
                }
            },
            handleChange(coupon_id) {
                let coupon = this.coupon_list.find(item => item.coupon_id == coupon_id);
                if (coupon) {
                    this.form.ad_coupon_name = coupon.name;
                    this.form.ad_coupon_token = coupon.token;
                    this.form.ad_coupon_id = coupon_id;
                    this.form.ad_coupon_describe = coupon.describe;
                    this.form.ad_coupon_tags = coupon.tags;
                }
            }
        }
    };
</script>

<style>
    
</style>