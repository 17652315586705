<template>
    <div class="main_bd">
        <div class="head_title">Draw orders</div>
        <div class="frm_controls search" style="margin: 30px 0">
            <span class="frm_input_box vcode with_counter counter_in append"
                style="display: inline-block; width: 300px; padding-right: 10px">
                <input class="frm_input" style="width: 100%" v-model="order_info"
                    placeholder="search by order, winner, merchant" @keyup.enter="search_order" />
            </span>
            <a-button type="primary" @click="search_order"> Search </a-button>
            <a style="font-size: 18px; color: rgba(98, 0, 238, 0.85); margin-left: 20px"
                @click="showFilter = !showFilter">
                filter
                <a-icon :type="showFilter ? 'caret-up' : 'caret-down'" />
            </a>
        </div>
        <div v-if="showFilter" class="mod_default_box permission" style="background-color: #6200ee14; padding: 30px">
            <div class="mod_default_bd" style="margintop: 30px">
                <div class="form-group">
                    <div class="row" style="padding-bottom: 10px">
                        <div class="col-auto" style="padding-bottom: 10px">
                            <div class="frm_controls">
                                <span style="display: inline-block; width: 150px;color: #12263f;
    font-size: .9375rem;">Type:</span>
                                <span>
                                    <a-checkbox-group v-model="checkedType" :options="order_type" @change="typeChange" />
                                </span>
                            </div>
                        </div>
                        <div class="col-auto" style="padding-bottom: 10px">
                            <div class="frm_controls">
                                <span style="display: inline-block; width: 150px;color: #12263f;
    font-size: .9375rem;">Status:</span>
                                <a-checkbox-group v-model="checkedStatus"
                                    :options="order_status" :disabled="checkedType.indexOf('Paid') == -1" />
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="frm_controls">
                                <span style="display: inline-block; width: 150px;color: #12263f;
    font-size: .9375rem;">Create time:</span>
                                <span style="display: inline-block; width: 200px; padding-right: 10px">
                                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                                </span>
                                <span>to</span>
                                <span style="display: inline-block; width: 200px; padding-left: 10px">
                                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="create">
            <download-excel class="export-excel-wrapper" :data="export_list" :fields="json_fields"
                name="order_summary.xls">
                <button class="create_btn">Export report</button>
            </download-excel>
        </div>
        <div class="mod_default_box permission">
            <a-table :rowKey="(data) => data.idx" :columns="columns" :data-source="order_list" :pagination="false">
                <template slot="index" slot-scope="text, data">
                    <p>{{ data.idx }}</p>
                </template>
                <template slot="status" slot-scope="text, data">
                    <a-tag
                        :color="data.transaction_status == 'captured' || data.transaction_status == 'completed' ? 'geekblue' : 'red'">
                        {{ data.transaction_status }}
                    </a-tag>
                </template>
                <template slot="action" slot-scope="text, data">
                    <a-button type="primary" size="small" style="background-color: #6200eed9; border-color: #6200eed9"
                        @click="toDetail(data)">
                        details
                    </a-button>
                </template>
            </a-table>
            <a-pagination style="margin-top: 25px" @change="turnPage" :pageSize="page_size" v-model="page"
                :total="total_count" show-less-items show-quick-jumper
                :show-total="total => `Total ${total_count} items`" />
        </div>
        <div class="loading_layer" v-if="is_loading">
            <a-spin />
        </div>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Order id - {{ curOrder.oid }}</div>
                        <div>order create time {{ curOrder.create_time }}</div>
                    </div>
                    <div>
                        <a-tag color="grey">
                            {{ curOrder.need_pay }}
                        </a-tag>
                    </div>
                </div>
            </template>
            <a-descriptions>
                <div class="detail_title" slot="title">Order details</div>
                <a-descriptions-item label="Related event" :span="3">
                    <a @click="toDrawDetail" style="border-bottom: 1px solid #333">{{ curOrder.event_name }}</a>
                </a-descriptions-item>
                <a-descriptions-item label="Winner id" :span="2">
                    {{ curOrder.winner_phone_num }}
                </a-descriptions-item>
                <a-descriptions-item label="Merchant id" :span="1">
                    {{ curOrder.user_name }}
                </a-descriptions-item>
                <a-descriptions-item label="Prize id" :span="2">
                    {{ curOrder.prize_code }}
                </a-descriptions-item>
                <a-descriptions-item label="Prize status" :span="1">
                    {{ prizeStatus(curOrder.status) }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions v-if="curOrder.need_pay == 'Paid'">
                <div class="detail_title" slot="title">Transaction details</div>
                <a-descriptions-item label="Transaction id" :span="2">
                    {{ curOrder.transaction_id }}
                </a-descriptions-item>
                <a-descriptions-item label="Transaction status" :span="1">
                    <span
                        :style="(curOrder.transaction_status == 'onhold' || curOrder.transaction_status == 'closed' || curOrder.transaction_status == 'refunded') ? 'color: red;' : ''">{{
                            curOrder.transaction_status
                        }}</span>
                </a-descriptions-item>
                <a-descriptions-item label="Payment" :span="3">
                    {{ curOrder.opay_type + curOrder.opay_info }}
                </a-descriptions-item>
                <a-descriptions-item label="Summary" :span="3" v-if="curOrder.transaction_status !== 'refunded'">
                    <div style="display: flex;">
                        <div class="summary">
                            <div><span>Subtotal</span> <span> $ {{ curOrder.price }}</span></div>
                            <div v-if="curOrder.tax !== '0' && curOrder.tax !== '0.00'"><span>Tax</span> <span> $ {{
                                curOrder.tax
                            }}</span></div>
                            <div v-if="curOrder.service_fee_value !== '0' && curOrder.service_fee_value !== '0.00'">
                                <span>Service fee</span> <span> $ {{ curOrder.service_fee_value }}</span>
                            </div>
                            <div style="border-top: 1px solid #333; padding: 8px 0;"><span>Total</span> <span> $ {{
                                curOrder.total
                            }}</span></div>
                        </div>
                        <a style="margin-left: 100px;border-bottom: 1px solid;color: rgba(116, 39, 255, 1);height: 20px"
                            href="javascript:;"
                            @click="turnToReceipt(curOrder.oid, curOrder.sid)" target="_blank">View online receipt</a>
                    </div>
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions v-if="curOrder.transaction_status == 'refunded'">
                <div class="detail_title" slot="title">Refund details</div>
                <a-descriptions-item label="Refund create time" :span="2">
                    {{ curOrder.refund_create_time }}
                </a-descriptions-item>
                <a-descriptions-item label="Refund initiator" :span="1">
                    {{ curOrder.refund_initiator }}
                </a-descriptions-item>
                <a-descriptions-item label="Refund reason" :span="3">
                    {{ curOrder.refund_reason }}
                </a-descriptions-item>
                <a-descriptions-item label="Refund method" :span="2">
                    {{ curOrder.refund_method }}
                </a-descriptions-item>
                <a-descriptions-item label="Refund amount" :span="1">
                    $ {{ curOrder.refund_value }}
                </a-descriptions-item>
                <a-descriptions-item label="Refund id" :span="3">
                    {{ curOrder.refund_id }}
                </a-descriptions-item>
                <a-descriptions-item label="Summary" :span="3">
                    <div style="display: flex;">
                        <div class="summary">
                            <div><span>Subtotal</span> <span> $ {{ curOrder.price }}</span></div>
                            <div v-if="curOrder.tax !== '0' && curOrder.tax !== '0.00'"><span>Tax</span> <span> $ {{
                                curOrder.tax
                            }}</span></div>
                            <div v-if="curOrder.service_fee_value !== '0' && curOrder.service_fee_value !== '0.00'">
                                <span>Service fee</span> <span> $ {{ curOrder.service_fee_value }}</span>
                            </div>
                            <div style="border-bottom: 1px solid #333; border-top: 1px solid #333; padding: 8px 0;">
                                <span>Total</span> <span> $ {{ curOrder.total }}</span>
                            </div>
                            <div><span>Refund</span> <span> $ {{ curOrder.refund_value }}</span></div>
                            <div><span>Net payment</span> <span> $ {{ curOrder.net_payment }}</span></div>
                        </div>
                        <a style="margin-left: 100px;border-bottom: 1px solid;color: rgba(116, 39, 255, 1);height: 20px"
                            href="javascript:;"
                            @click="turnToReceipt(curOrder.oid, curOrder.sid)"
                            target="_blank">View online receipt</a>
                    </div>
                </a-descriptions-item>
            </a-descriptions>
            <template slot="footer">
                <a-button type="danger" @click="handleRefund"
                    v-if="curOrder.transaction_status == 'onhold' || curOrder.transaction_status == 'captured' || curOrder.transaction_status == 'completed'">
                    Refund
                </a-button>
                <a-button type="primary" @click="handleOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 60px;margin-right: 300px;">
                    Done
                </a-button>
            </template>
        </a-modal>
        <a-modal :width="600" v-model="refundVisible" @ok="handleRefundOk">
            <div style="text-align: center;">
                <h4>Refund this transaction ?</h4>
                <div>Select the item that will be refunded</div>
                <div style="margin: 20px 0">
                    <a-checkbox v-model="refundInfo.subtotal">
                        Subtotal $ {{ curOrder.price }}
                    </a-checkbox>
                    <br />
                    <a-checkbox v-if="curOrder.tax !== '0' && curOrder.tax !== '0.00'" v-model="refundInfo.tax">
                        Tax $ {{ curOrder.tax }}
                    </a-checkbox>
                    <br />
                    <a-checkbox v-if="curOrder.service_fee_value !== '0' && curOrder.service_fee_value !== '0.00'"
                        v-model="refundInfo.service_fee">
                        Service fee $ {{ curOrder.service_fee_value }}
                    </a-checkbox>
                </div>
                <h5>Refund reason</h5>
                <a-textarea style="width: 400px;" v-model="refundInfo.reason" placeholder="No more than 300 characters"
                    :auto-size="{ minRows: 3, maxRows: 5 }" />
            </div>
            <template slot="footer">
                <a-button type="danger" @click="handleRefundConfirm">
                    Continue
                </a-button>
                <a-button type="primary" @click="handleRefundOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 60px;margin-right: 160px;">
                    No, cancel
                </a-button>
            </template>
        </a-modal>
        <a-modal :width="600" v-model="refundConfirmVisible" @ok="handleConfirmRefundOk">
            <div>
                <a-descriptions title="Refund summary">
                    <a-descriptions-item label="Refund total" :span="3">
                        $ {{ refundTotal() }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Refund method" :span="3">
                        {{ curOrder.opay_type + curOrder.opay_info }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Refund reason" :span="3">
                        {{ refundInfo.reason ? refundInfo.reason : 'N/A' }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
            <template slot="footer">
                <a-button type="danger" @click="handleRefundContinue">
                    Yes, refund
                </a-button>
                <a-button type="primary" @click="handleConfirmRefundOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 60px;margin-right: 160px;">
                    Back to edit
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<style scoped>
.loading_layer {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    line-height: 500px;
    background: #fff;
    z-index: 100;
    opacity: 0.5;
}

.col_main .col_main_inner {
    width: 100%;
    margin: 0 auto;
}

.main_bd {
    padding: 0 45px;
    width: 100%;
    margin: 0 auto;
}

.mod_default_box {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px hsl(0deg 0% 59% / 30%);
    padding: 20px 30px 30px;
}

.col-auto {
    width: 100%;
}

.create {
    height: 28px;
    margin: 26px 0;
}

.create_btn {
    width: 145px;
    height: 28px;
    border: none;
    border-radius: 8px;
    background-color: rgba(116, 39, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Helvetica';
    font-size: 16px;
    position: absolute;
    right: 45px;
}

.modal_head {
    display: flex;
    justify-content: space-between;
    margin: 35px 35px 0 0;
}

.event_name {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 24px;
    margin-bottom: 15px;
}

.detail_title {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 20px;
}

.summary {
    display: flex;
    flex-flow: column;
    background-color: #eee;
    padding: 10px;
    width: 300px;
}

.summary div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
const moment = require('moment-timezone');
const columns = [
    {
        title: 'No.',
        dataIndex: 'idx',
        scopedSlots: { customRender: 'index' },
        width: 100
    },
    {
        title: 'Create time',
        dataIndex: 'create_time',
        width: 250
    },
    {
        title: 'Order id',
        dataIndex: 'oid',
        width: 300
    },
    {
        title: 'Type',
        dataIndex: 'need_pay'
    },
    {
        title: 'Winner',
        dataIndex: 'winner_phone_num'
    },
    {
        title: 'Transaction id',
        dataIndex: 'transaction_id'
    },
    {
        title: 'Status',
        dataIndex: 'transaction_status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: 'More',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];
export default {
    name: 'drawOrder',
    components: {},
    data() {
        return {
            order_id: undefined,
            showFilter: false,
            order_info: '',
            checkedType: ['Free', 'Paid'],
            checkedStatus: ['onhold', 'captured', 'completed', 'refunded', 'closed'],
            start_date: '',
            end_date: '',
            order_type: [
                {
                    label: 'Free',
                    value: 'Free'
                },
                {
                    label: 'Paid',
                    value: 'Paid'
                }
            ],
            order_status: [
                {
                    label: 'Onhold',
                    value: 'onhold'
                },
                {
                    label: 'Captured',
                    value: 'captured'
                },
                {
                    label: 'Completed',
                    value: 'completed'
                },
                {
                    label: 'Refunded',
                    value: 'refunded'
                },
                {
                    label: 'Closed',
                    value: 'closed'
                }
            ],
            prize_status: [
                {
                    label: 'Inactive',
                    value: '0'
                },
                {
                    label: 'Active',
                    value: '1'
                },
                {
                    label: 'Expired',
                    value: '2'
                },
                {
                    label: 'Used',
                    value: '3'
                },
                {
                    label: 'Withdraw',
                    value: '4'
                }
            ],
            is_loading: false,
            detailVisible: false,
            refundVisible: false,
            refundConfirmVisible: false,
            columns,
            page: 1,
            page_size: 20,
            curOrder: {},
            refundInfo: {
                subtotal: false,
                tax: false,
                service_fee: false,
                reason: ''
            },
            json_fields: {
                'No.': "idx",
                'Create time': "create_time",
                'Order id': "oid",
                'Type': "need_pay",
                'Winner': "winner_phone_num",
                'Transaction id': "transaction_id",
                'Transaction Status': "transaction_status"
            }
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    mounted() {
        this.order_id = this.$route.query.order_id;
        if (this.order_id){
            this.getOrderById({
                id: this.order_id
            }).then(res => {
                this.toDetail(res.data);
            })     
        }
        this.is_loading = true;
        this.getOrderList({
            page: 1,
            page_size: this.page_size,
            cb: this.set_loading_cb
        });
    },
    computed: {
        ...mapGetters({
            order_list: 'order/get_order_list',
            export_list: 'order/get_export_list',
            total_page: 'order/get_total_page',
            total_count: 'order/get_total_count'
        }),
        ...mapState({})
    },
    methods: {
        set_loading_cb() {
            this.is_loading = false;
        },
        ...mapActions({
            getOrderList: 'order/getOrderList',
            getOrderById: 'order/getOrderById',
            toRefund: 'order/toRefund'
        }),
        typeChange(val){
            if(val.indexOf('Paid') == -1){
                this.checkedStatus = [];
            } else {
                this.checkedStatus = ['onhold', 'captured', 'completed', 'refunded', 'closed']
            }
        },
        search_order(sorder) {
            let serchObj = this.checkFilter();
            this.getOrderList({
                ...serchObj,
                page_size: this.page_size,
                cb: this.set_loading_cb
            });
        },
        checkFilter(page_num) {
            page_num = page_num ? page_num : 1;
            this.page = page_num;

            let serchObj = {};
            if (this.checkedType.length <= 0) {
                this.$message.error('Please select at least one！');
                return;
            } else {
                serchObj.type = this.checkedType.join(',');
            }

            if (this.checkedType.indexOf('Paid') == -1) {
                serchObj.status = '';
            } else {
                if (this.checkedStatus.length <= 0) {
                    this.$message.error('Please select at least one！');
                    return;
                } else {
                    serchObj.status = this.checkedStatus.join(',');
                }
            }

            if (this.order_info) {
                serchObj.search = this.order_info;
            }

            let date_min = this.start_date ? moment(this.start_date).valueOf() : null;
            let date_max = this.end_date ? moment(this.end_date).valueOf() : null;
            serchObj.create_stime = date_min;
            serchObj.create_etime = date_max;

            serchObj.page = page_num;

            return serchObj;
        },
        //prize_status
        prizeStatus(status) {
            if (status === '') {
                return '';
            }
            for (let index = 0; index < this.prize_status.length; index++) {
                if (this.prize_status[index].value == status) {
                    return this.prize_status[index].label;
                }
            }
            return '';
        },
        toDetail(data) {
            this.curOrder = { ...data };
            this.detailVisible = true;
        },
        handleOk() {
            this.detailVisible = false;
        },
        handleRefund() {
            this.detailVisible = false;
            this.refundVisible = true;
        },
        handleRefundOk() {
            this.refundVisible = false;
        },
        handleRefundConfirm() {
            if (!this.refundInfo.subtotal && !this.refundInfo.tax && !this.refundInfo.service_fee) {
                this.$message.error('Please select at least one！');
                return;
            }
            this.refundConfirmVisible = true;
            console.log(this.refundInfo)
        },
        handleConfirmRefundOk() {
            this.refundConfirmVisible = false;
        },
        refundTotal() {
            let total = 0;
            if (this.refundInfo.subtotal) {
                total += Number(this.curOrder.price);
            }
            if (this.refundInfo.tax) {
                total += Number(this.curOrder.tax);
            }
            if (this.refundInfo.service_fee) {
                total += Number(this.curOrder.service_fee_value);
            }
            return total;
        },
        handleRefundContinue() {
            const obj = {
                oid: this.curOrder.oid,
                refund_create_time: Date.parse(new Date()),
                refund_initiator: window.localStorage.getItem('uname') || '',
                refund_reason: this.refundInfo.reason,
                refund_method: this.curOrder.opay_type + this.curOrder.opay_info,
                refund_value: this.refundTotal(),
                transaction_status: 'refunded',
                transaction_id: this.curOrder.transaction_id,
                net_payment: Number(this.curOrder.total) - this.refundTotal()
            }
            this.toRefund({
                ...obj,
                afterdata: {
                    page: this.page,
                    page_size: this.page_size
                },
                cb: this.set_loading_cb
            });
            this.refundVisible = false;
            this.refundConfirmVisible = false;
        },
        turnPage(page) {
            let serchObj = this.checkFilter(page);
            console.log('serchObj', serchObj);
            //this.page = page;
            this.getOrderList({
                ...serchObj,
                //page,
                page_size: this.page_size,
                cb: this.set_loading_cb
            });
        },
        toDrawDetail() {
            this.$router.push({
                path: '/drawManagement',
                query: { draw_id: this.curOrder.id }
            });
        },
        turnToReceipt(oid, sid) {
            var _admin_host = window.location.host;
            var _client_host = _admin_host.replace(/minipassadmin\./, 'minipassapp.');

            let _url = `https://${_client_host}/prize/${oid}/${sid}`;
            window.open(_url);
        }
    }
};
</script>
