<template>
    <div class="main_bd">
        <div class="head_title">
            Minitable System Monitor
            <span style="font-size: 16px;">(refresh every minute)</span>
            <span>
                <a-spin v-if="is_loading" style="margin-left: 20px;"/>
            </span>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="10">
                <a-card title="Message Status (total / success / sending / fail)" style="width: 100%;">
                    <span slot="extra">
                        <a-tag color="green" v-if="message_all_health">
                            Healthy
                        </a-tag>
                        <a-tag color="red" v-else>
                            Error
                        </a-tag>
                    </span>
                    <a-list
                        class="demo-loadmore-list"
                        item-layout="horizontal"
                        :data-source="message_data"
                    >
                        <a-list-item slot="renderItem" slot-scope="item">
                            <span slot="actions">{{ item.check_time }}</span>
                            <a-list-item-meta :description="item.name"></a-list-item-meta>
                            <div>
                                <a-tag v-if="item.status === 'success'" slot="actions" color="green">
                                    Healthy
                                </a-tag>
                                <a-tag v-else slot="actions" color="red">
                                    Error
                                </a-tag>
                                <span>{{ `${item.total} / ${item.success} / ${item.wait} / ${item.fail}` }}</span>
                            </div>
                        </a-list-item>
                    </a-list>
                </a-card>
                <a-card title="Business System Status" style="margin-top: 10px; width: 100%;">
                    <span slot="extra">
                        <a-tag color="green" v-if="business_all_health">
                            Healthy
                        </a-tag>
                        <a-tag color="red" v-else>
                            Error
                        </a-tag>
                    </span>
                    <a-list
                        class="demo-loadmore-list"
                        item-layout="horizontal"
                        :data-source="business_data"
                    >
                        <a-list-item slot="renderItem" slot-scope="item">
                            <span slot="actions">{{ item.check_time }}</span>
                            <a-list-item-meta :description="item.name">
                            </a-list-item-meta>
                            <div>
                                <a-tag v-if="item.status === 'success'" slot="actions" color="green">
                                    Healthy
                                </a-tag>
                                <a-tag v-else slot="actions" color="red">
                                    Error
                                </a-tag>
                                <span v-if="item.status === 'error'">{{ item.msg }}</span>
                            </div>
                        </a-list-item>
                    </a-list>
                </a-card>
            </a-col>
            <a-col :span="14" style="margin-top: 0px;">
                <a-card title="Automation Task Status" style="width: 100%;">
                    <span slot="extra">
                        <a-tag color="green" v-if="crontab_all_health">
                            Healthy
                        </a-tag>
                        <a-tag color="red" v-else>
                            Error
                        </a-tag>
                    </span>
                    <a-list
                        class="demo-loadmore-list"
                        item-layout="horizontal"
                        :data-source="crontab_data"
                    >
                        <a-list-item slot="renderItem" slot-scope="item">
                            <span v-if="item.task_status === 'success' || item.task_status == 'fail'" slot="actions">
                                {{ item.log.execution_time }}
                                <a-button type="link" @click="showCrontabSetting(item)">Detail</a-button>
                            </span>
                            <span v-if="item.task_status === 'pending'" slot="actions">
                                {{ 'Estimated execution time: ' + item.check_time }}
                                <a-button type="link" @click="showCrontabSetting(item)">Detail</a-button>
                            </span>
                            <a-list-item-meta :description="item.duty_name"></a-list-item-meta>
                            <div>
                                <a-tag v-if="item.task_status === 'success'" slot="actions" color="green">
                                    Healthy
                                </a-tag>
                                <a-tag v-else-if="item.task_status === 'fail'" slot="actions" color="red">
                                    Error
                                </a-tag>
                                <a-tag v-else slot="actions" color="blue">
                                    Pending
                                </a-tag>
                                <span>
                                    {{ `${item.log && item.log.message || ''}` }}
                                </span>
                            </div>
                        </a-list-item>
                    </a-list>
                </a-card>
            </a-col>
        </a-row>
        <a-modal
            :title="'Edit Crontab Setting - ' + layer_data.duty_id"
            :visible="show_detail_layer"
            @ok="handleOk"
            ok-text="Save"
            @cancel="handleCancel"
            :width="900"
            style="height: 600px; overflow: auto;"
            >
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">{{ layer_data.duty_name }}</div>
                    </div>
                    <div>
                        <a-tag :color="layer_data.status == 1 ? 'green' : 'grey'">
                            {{ layer_data.status == 1 ? 'Active' : 'Deactivated' }}
                        </a-tag>
                    </div>
                </div>
            </template>
            <a-descriptions>
                <a-descriptions-item label="Affect Business" :span="6">
                    {{ layer_data.affect_business == 1 ? 'Yes' : 'No' }}
                    <a-rate v-model="layer_data.important" />
                </a-descriptions-item>
                <a-descriptions-item label="Duty Name" :span="6">
                    <a-input style="width: 300px;" v-model="layer_data.duty_name"></a-input>
                </a-descriptions-item>
                <a-descriptions-item label="Execution Cycle" :span="6">
                    {{ layer_data.execution_cycle }}
                </a-descriptions-item>
                <a-descriptions-item label="Execution time" :span="6">
                    {{ layer_data.execution_time }} (America/New_York)
                </a-descriptions-item>
                <a-descriptions-item label="Duty Description" :span="6">
                    {{ layer_data.desc }}
                </a-descriptions-item>
                <a-descriptions-item label="Use Place" :span="6">
                    {{ layer_data.use_place }}
                </a-descriptions-item>
                <a-descriptions-item label="Check Time" :span="6">
                    <a-input placeholder="美东时间" style="width: 300px;" v-model="layer_data.check_time"></a-input>
                </a-descriptions-item>
            </a-descriptions>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'system_monitor',
        components: {
        },
        data() {
            return {
                system_data: {},
                is_loading: false,
                show_detail_layer: false,
                layer_data: {}
            };
        },
        async created() {
            await this.doGetSystemData();

            this.timer = setInterval(() => {
                this.doGetSystemData();
            }, 60000);
        },
        computed: {
            ...mapGetters({}),
            ...mapState({}),
            business_data() {
                let service_status = this.system_data.service_status;
                let list = [];

                for (let name in service_status) {
                    list.push({
                        name: name,
                        status: service_status[name].status,
                        msg: service_status[name].msg,
                        check_time: service_status[name].check_time
                    });
                }

                return list;
            },
            business_all_health() {
                let service_status = this.system_data.service_status;
                let all_health = true;

                for (let name in service_status) {
                    if (service_status[name].status === 'error') {
                        all_health = false;
                        break;
                    }
                }

                return all_health;
            },
            message_data() {
                let list = [
                ];
                let message_status = this.system_data.message_status;
                if (!message_status) return list;

                const { t: twilio_status, b: bird_status } = message_status;
                let twilio_status_flag = false;
                let bird_status_flag = false;

                if (twilio_status) {
                    const { t: total_twilio, w: wait_twilio, s: success_twilio, f: fail_twilio } = twilio_status;
                    
                    if (total_twilio > 0 && fail_twilio > 0 && fail_twilio / total_twilio < 0.1) {
                        twilio_status_flag = true;
                    } else if (total_twilio == 0) {
                        twilio_status_flag = true;
                    } else if (total_twilio > 0 && fail_twilio == 0) {
                        twilio_status_flag = true;
                    }

                    list.push({
                        name: 'Twilio',
                        status: twilio_status_flag ? 'success' : 'error',
                        check_time: twilio_status ? twilio_status.ctime : '',
                        total: total_twilio,
                        wait: wait_twilio,
                        success: success_twilio,
                        fail: fail_twilio
                    });
                }

                if (bird_status) {
                    const { t: total_bird, w: wait_bird, s: success_bird, f: fail_bird } = bird_status;

                    if (total_bird > 0 && fail_bird > 0 && fail_bird / total_bird < 0.1) {
                        bird_status_flag = true;
                    } else if (total_bird == 0) {
                        bird_status_flag = true;
                    } else if (total_bird > 0 && fail_bird == 0) {
                        bird_status_flag = true;
                    }

                    list.push({
                        name: 'Bird',
                        status: bird_status_flag ? 'success' : 'error',
                        check_time: bird_status ? bird_status.ctime : '',
                        total: total_bird,
                        wait: wait_bird,
                        success: success_bird,
                        fail: fail_bird
                    });
                }

                return list;
            },
            message_all_health() {
                let message_status = this.system_data.message_status;
                if (!message_status) return true;

                const { t: twilio_status, b: bird_status } = message_status;
                let twilio_status_flag = false;
                let bird_status_flag = false;

                if (twilio_status) {
                    const { t: total_twilio, w: wait_twilio, s: success_twilio, f: fail_twilio } = twilio_status;
                    
                    if (total_twilio > 0 && fail_twilio > 0 && fail_twilio / total_twilio < 0.1) {
                        twilio_status_flag = true;
                    } else if (total_twilio == 0) {
                        twilio_status_flag = true;
                    } else if (total_twilio > 0 && fail_twilio == 0) {
                        twilio_status_flag = true;
                    }
                }

                if (bird_status) {
                    const { t: total_bird, w: wait_bird, s: success_bird, f: fail_bird } = bird_status;

                    if (total_bird > 0 && fail_bird > 0 && fail_bird / total_bird < 0.1) {
                        bird_status_flag = true;
                    } else if (total_bird == 0) {
                        bird_status_flag = true;
                    } else if (total_bird > 0 && fail_bird == 0) {
                        bird_status_flag = true;
                    }
                }

                return twilio_status_flag && bird_status_flag;
            },
            crontab_data() {
                return this.system_data.crontab_list;
            },
            crontab_all_health() {
                let crontab_list = this.system_data.crontab_list;
                let all_health = true;

                for (let name in crontab_list) {
                    if (crontab_list[name].task_status === 'fail') {
                        all_health = false;
                        break;
                    }
                }

                return all_health;
            }
        },
        methods: {
            ...mapActions({
                getSystemData: 'monitor/getSystemData',
                updateCrontabSetting: 'monitor/updateCrontabSetting'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async doGetSystemData() {
                this.is_loading = true;
                let res = await this.getSystemData({});
                this.system_data = res.data;
                this.is_loading = false;
            },
            showCrontabSetting(data) {
                this.layer_data = JSON.parse(JSON.stringify(data));
                this.show_detail_layer = true;
            },
            async handleOk() {
                if (/\d\d\:00/.test(this.layer_data.check_time) === false && /\d\d\:30/.test(this.layer_data.check_time) === false){
                    this.$message.error('Please input correct check time[HH:mm]');
                    return;
                }

                if (this.layer_data.duty_name == '') {
                    this.$message.error('Please input duty name');
                    return;
                }

                await this.updateCrontabSetting({
                    duty_id: this.layer_data.duty_id,
                    check_time: this.layer_data.check_time,
                    duty_name: this.layer_data.duty_name
                });

                this.show_detail_layer = false;

                this.layer_data = {};
                this.doGetSystemData();
            },
            handleCancel() {
                this.show_detail_layer = false;
                this.layer_data = {};
            }
        }
    };
</script>

<style>
    .custom_input{
        width: 220px !important;
    }
</style>