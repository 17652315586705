<template>
    <div class="main_bd">
        <a-spin :spinning="loading">
            <div class="head_title">Guest Data Export</div>
            <div class="in_title">Export data by sid, email, or other else.</div>
            <div class="">
                <div style="margin-top: 10px">
                    <span style="padding-right: 20px;">Filtered by something</span>
                    <a-radio-group v-model="filterBy">
                        <a-radio-button value="sid">
                            Sid
                        </a-radio-button>
                        <a-radio-button value="user_name">
                            User name
                        </a-radio-button>
                        <a-radio-button value="postal_code">
                            Postal
                        </a-radio-button>
                        <a-radio-button v-if="false" value="Borough">
                            Borough
                        </a-radio-button>
                    </a-radio-group>
                </div>
                <div style="margin-top: 10px">
                    <span style="padding-right: 20px;">Visit count limit (0 means no limit)</span>
                    <a-input v-model="limit_visit_count" size="large" placeholder="visit count limit" />
                </div>
                <div style="margin-top: 10px">
                    <span style="padding-right: 20px;">Input sid, user name or postal. You can paste from excel, it will automatically remove the newline.</span>
                    <a-textarea
                        v-model="content"
                        placeholder="Please input something"
                        :auto-size="{ minRows: 5, maxRows: 20 }"
                        @keyup="onKeyup"
                    />
                </div>
                <div style="margin-top: 10px">
                    <span style="padding-right: 20px;">Custom file name (Default: guest_data)</span>
                    <a-input v-model="file_name" size="large" placeholder="input custom file name" />
                </div>
                <div style="margin-top: 10px">
                    <button class="done_btn" @click="submit">Submit</button>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'guest-export',
        components: {
        },
        data() {
            return {
                filterBy: 'user_name',
                content: '',
                limit_visit_count: 0,
                loading: false,
                file_name: ''
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                doExport: 'data/doExport',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            submit() {
                this.loading = true;
                this.doExport({
                    filterBy: this.filterBy,
                    content: this.content,
                    limit_visit_count: this.limit_visit_count
                }).then(res => {
                    let uploadExcel = fileName => {
                        const blob = new Blob([res], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };

                    let file_name = this.file_name ? this.file_name : 'guest_data';
                    uploadExcel(file_name + '.xlsx');
                    
                    this.loading = false;
                });
            },
            onKeyup(evt) {
                this.content = this.content.replace(/\n/g, ',');
                this.content = this.content.replace(/\s/g, '');
            }
        }
    };
</script>
