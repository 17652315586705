<template>
    <div class="main_bd">
        <div class="head_title">Custom Survey Management</div>
        <div class="action_content">
            <div>
                <button style="width: 260px;" class="action_btn active_btn" @click="turn_to_create">Create new survey</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="survey_list" >
                <template slot="task_status" slot-scope="text, data">
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.task_status == 0">
                        unPublished
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_status == 1">
                        Publishing
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_status == 4">
                        Done
                    </span>
                </template>
                <template slot="question" slot-scope="text">
                    <a-button type="link" @click="do_show_questions(text)">Show Questions</a-button>
                </template>
                <div slot="action" slot-scope="text, data">
                    <!-- <a-popover title="" trigger="click" placement="bottom" @visibleChange="handleClickChange(data)"> -->
                        <!-- <template slot="content"> -->
                            <div>
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="copy_h5_url(data)">Copy H5 URL</a>
                            </div>
                            <div v-if="data.task_status == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="turn_to_create(data)">Edit</a>
                            </div>
                            <div v-if="data.task_status == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="publish_task(data)">Publish</a>
                            </div>
                            <div v-if="data.task_status == 1">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="finish_task(data)">Finish</a>
                            </div>
                            <div v-if="data.task_status == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="delete_task(data)">Delete</a>
                            </div>
                            <div>
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="show_detail(data)">Show details</a>
                            </div>
                            <div v-if="data.task_status == 4">
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="turn_to_create(data)">Show setting</a>
                            </div>
                        <!-- </template> -->
                        <!-- <a-button type="link">
                            ...
                        </a-button> -->
                    <!-- </a-popover> -->
                </div>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count" show-less-items />
        </div>
        <a-modal
            title="Questions"
            :visible="show_question_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="800"
            style="height: 300px; overflow: auto;"
            >
            <a-list v-if="false" item-layout="horizontal" :data-source="show_questions">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-list-item-meta description="">
                        <span slot="title">{{ 'Q' + (index + 1) + ' ' + item.name }}</span>
                    </a-list-item-meta>
                    <div>{{ item.content }}</div>
                </a-list-item>
            </a-list>
            <a-collapse accordion>
                <a-collapse-panel v-bind:key="index" :header="'Q' + (index + 1) + ' ' + item.name" v-for="item,index in show_questions">
                    <p>{{ item.content }}</p>
                    <p v-if="item.type == 'rating'">
                        <a-rate :value="4" disabled />
                    </p>
                    <p v-if="item.type == 'choice' || item.type == 'multiple_choice'">
                        <a-radio-group>
                            <a-radio v-for="option in item.answer" :key="option" :value="option">{{ option }}</a-radio>
                            <a-radio v-if="item.with_other_option" key="other" value="other">Other</a-radio>
                        </a-radio-group>
                    </p>
                    <p v-if="item.type == 'text'">
                        <a-input readonly="true" disabled />
                    </p>
                </a-collapse-panel>
            </a-collapse>
        </a-modal>
        <a-modal
            :visible="show_content_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="1250"
            style="height: 600px; overflow: auto;"
            >
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Detail - {{ survey_detail.task_name }} - {{ survey_detail.task_id }}</div>
                    </div>
                    <div>
                        <a-tag color="grey" v-if="survey_detail.task_status == 0">
                            unPublished
                        </a-tag>
                        <a-tag color="green" v-else-if="survey_detail.task_status == 1">
                            Publishing
                        </a-tag>
                        <a-tag color="blue" v-else-if="survey_detail.task_status == 4">
                            Done
                        </a-tag>
                        <a-button type="link" @click="doDownload(survey_detail.task_id)" v-if="survey_detail.task_status > 0">
                            Export
                        </a-button>
                    </div>
                </div>
            </template>
            <a-descriptions>
                <a-descriptions-item label="Survey id" :span="2" v-if="false">
                    {{ survey_detail.task_id }}
                </a-descriptions-item>
                <a-descriptions-item label="Channel" v-if="false" :span="1">
                    {{ survey_detail.channel }}
                </a-descriptions-item>
                <a-descriptions-item label="App postion" v-if="false" :span="2">
                    {{ survey_detail.app_position }}
                </a-descriptions-item>
                <a-descriptions-item label="Create Time" v-if="survey_detail.create_time" :span="1">
                    {{ survey_detail.create_time }}
                </a-descriptions-item>
                <a-descriptions-item label="Start Time" v-if="survey_detail.start_time" :span="1">
                    {{ survey_detail.start_time }}
                </a-descriptions-item>
                <a-descriptions-item label="End Time" v-if="survey_detail.end_time" :span="1">
                    {{ survey_detail.end_time }}
                </a-descriptions-item>
            </a-descriptions>
            <a-row style="margin-bottom: 0px;">
                <a-col style="margin-right: 10px;" :span="4" v-for="(data_item, data_index) in summary_data" v-bind:key="data_index + '_data'">
                    <a-card v-if="data_item.type == 'rating'" :title="data_item.name" :bordered="false" style="width: auto;">
                        <a-statistic title="" :value="data_item.score" style="margin-right: 5px">
                            <template #suffix>
                                <a-icon type="star" />
                            </template>
                        </a-statistic>
                    </a-card>
                    <a-card v-if="data_item.type == 'choice'" :title="data_item.name" :bordered="false" style="width: auto;">
                        <p style="font-size: 16px;" v-for="answer_item in data_item.count_arr" v-bind:key="answer_item.name">
                            {{ answer_item.name }}: {{ answer_item.count + ' / ' + data_item.total_count + ' (' + (answer_item.count / data_item.total_count * 100).toFixed(2) + '%)' }}
                        </p>
                    </a-card>
                </a-col>
            </a-row>
            <span v-if="survey_detail.task_log && survey_detail.task_log.length === 100">（Only the latest 100 pieces of data are displayed. If you need all the data, please export it to view locally.）</span>
            <a-table :scroll="{ x: 1000, y: 500 }" :pagination="false" :rowKey="data => data.id" :columns="columns_log" :data-source="survey_detail.task_log" >
            </a-table>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "Name",
            dataIndex: "task_name",
            width: 200
        },
        {
            title: "Status",
            dataIndex: "task_status",
            scopedSlots: { customRender: 'task_status' }
        },
        {
            title: "Task id",
            dataIndex: "task_id"
        },
        {
            title: "Count",
            dataIndex: "total_count"
        },
        {
            title: "Questions",
            dataIndex: "custom_question",
            scopedSlots: { customRender: 'question' }
        },
        {
            title: "Operator",
            dataIndex: "operator"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'survey_list',
        components: {
        },
        data() {
            return {
                task_status: '',
                sid: window.localStorage.getItem('sid'),
                columns,
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                survey_list: [],
                show_content_layer: false,
                show_question_layer: false,
                show_questions: [],
                survey_detail: {},
                columns_log: [
                    {
                        title: "Phone Number",
                        dataIndex: "phone_num"
                    },
                    {
                        title: "Time",
                        dataIndex: "create_time"
                    }
                ],
                summary_data: []
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.get_survey_list();
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getSurveyList: 'market/getSurveyList',
                updateSurveyTask: 'market/updateSurveyTask',
                getSurveyDetail: 'market/getSurveyDetail',
                exportSurveyLog: 'market/exportSurveyLog',
                getSurveySummaryData: 'market/getSurveySummaryData'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            do_show_questions(questions) {
                this.show_questions = JSON.parse(questions);
                this.show_question_layer = true;
            },
            async publish_task(data) {
                this.$confirm({
                    title: 'Are you sure you want to publish?',
                    content: '',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.updateSurveyTask({
                            task_id: data.task_id,
                            task_status: 1
                        });
                        this.get_survey_list();
                    },
                    onCancel: async () => {},
                });
            },
            async finish_task(data) {
                this.$confirm({
                    title: 'Are you sure you want to finish?',
                    content: '',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.updateSurveyTask({
                            task_id: data.task_id,
                            task_status: 4
                        });
                        this.get_survey_list();
                    },
                    onCancel: async () => {},
                });
            },
            handleOk() {
                this.show_question_layer = false;
                this.show_content_layer = false;
            },
            async show_detail(data) {
                this.columns_log = [
                    {
                        title: "Phone Number",
                        dataIndex: "phone_num"
                    },
                    {
                        title: "Time",
                        dataIndex: "create_time"
                    }
                ];

                let res = await this.getSurveyDetail({
                    task_id: data.task_id
                });

                this.survey_detail = res.info || {};
                this.columns_log = this.columns_log.concat(res.info.headers || []);
                if (res.info.headers.length > 5) {
                    // this.columns_log[0].fixed = 'left';
                    // this.columns_log[this.columns_log.length - 1].fixed = 'right';
                }
                this.show_content_layer = true;

                let summary_res = await this.getSurveySummaryData({
                    task_id: data.task_id
                });

                this.summary_data = summary_res.summary || {};
            },
            async get_survey_list() {
                let { list, total_count, total_page } = await this.getSurveyList({
                    page: this.cur_page,
                    page_size: 20
                });

                this.survey_list = list;
                this.total_page = total_page;
                this.total_count = total_count;
            },
            turnPage(e) {
                this.get_survey_list();
            },
            turn_to_create(data) {
                this.$router.push({
                    path: data.task_id ? '/market/survey/create?task_id=' + data.task_id : '/market/survey/create'
                });
            },
            async delete_task(data) {
                this.$confirm({
                    title: 'Are you sure you want to delete?',
                    content: 'Unable to recover after deletion',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.updateSurveyTask({
                            task_id: data.task_id,
                            task_status: -1
                        });
                        this.get_survey_list();
                    },
                    onCancel: async () => {},
                });
            },
            handleClickChange(a, b) {},
            doDownload(task_id) {
                this.exportSurveyLog({
                    task_id
                }).then(res => {
                    let uploadExcel = fileName => {
                        const blob = new Blob([res], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };
                    uploadExcel('survey_' + task_id + ".xlsx");
                });
            },
            copy_h5_url(data) {
                let host = window.location.origin;
                host = host.replace('minipassadmin', 'minipassapp');

                let url = host + '/survey/' + data.task_id + '?phone=';
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                oInput.style.display = 'none';
                this.$message.success('Copy success');
            }
        }
    };
</script>

<style>
    .action_content {
        display: flex;
        justify-content: space-between;
    }
    .action_btn {
        width: 102px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: #7427FF;
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
    .action_btn.delete_btn {
        background-color: #FD7858;
    }
    .action_btn.deactivate_btn {
        background-color: #7D7D7D;
    }
    .action_btn.active_btn {
        background-color: #2C9100;
    }
    .been_activated {
        text-align: center;
        line-height: 26px;
        font-size: 14px;
    }
    .been_activated.activated_orange {
        color: #FD7858;
    }
    .content_layer h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #7427FF;
    }
    .content_layer .content_item {
        display: flex;
        color: #333;
        margin: 0;
        line-height: 28px;
    }
    .content_layer .content_item dt {
        font-weight: 500;
        margin-right: 10px;
    }
    .content_layer .content_item dd {
        font-size: 12px;
        margin-right: 20px;
    }
    .content_layer .content_item a {
        color: #7427FF;
        cursor: text;
    }
    .content_layer_bottom {
        border-top: 1px solid #d8d8d8;
        margin-top: 10px;
    }
    .content_layer_top {
        position: relative;
    }
    .content_layer_btn {
        position: absolute;
        top: -5px;
        right: 20px;
        line-height: 24px;
    }
    .content_layer_btn .action_btn {
        border-radius: 12px;
        height: 24px;
        font-size: 13px;
        width: 90px;
        cursor: auto;
    }
    .image_box a {
        display: block;
    }
</style>