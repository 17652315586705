import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    getMasterList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/master/acount/list`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getMasterDetail({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/master/acount/detail?chain_id=` + post_data.chain_id)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    checkMerchantAccount({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/master/merchant/check`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    editMasterAccount({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/master/acount/edit`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    createMasterAccount({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/master/acount/create`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    linkMerchantAccount({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .post( aj_host + `/weapp/minipass/master/acount/link`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
