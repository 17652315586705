<template>
    <div class="main_bd">
        <div class="head_title">Boost</div>
        <a-row style="margin: 10px 0;">
            <a-col :span="4">
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Count" :value="summary_data.boost_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Success Count" :value="summary_data.boost_success_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Fail Count" :value="summary_data.boost_fail_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Amount" :value="summary_data.boost_sales_amount || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Count" :value="summary_data.boost_sales_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
        </a-row>
        <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="merchant">Merchant management </a-menu-item>
            <a-menu-item key="boost"> Boost Today </a-menu-item>
            <a-menu-item key="boost_history"> Boost History </a-menu-item>
            <a-menu-item key="transaction"> IAP Transaction </a-menu-item>
            <a-menu-item key="robot"> Robot Management </a-menu-item>
        </a-menu>
        <div class="mod_default_box permission">
            <span style="margin-right: 10px; margin-left: 2px;">Date Range: </span><a-range-picker @change="onChange" />
            <a-table style="margin-top: 10px" :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="boost_list" >
                <template slot="data_zone" slot-scope="text">
                    <p v-if="boost_waiting_data_dict && boost_waiting_data_dict[text]">{{ boost_waiting_data_dict[text].ahead }} ahead</p>
                    <p v-if="boost_waiting_data_dict && boost_waiting_data_dict[text]">{{ boost_waiting_data_dict[text].behind }} behind</p>
                </template>
                <template slot="boost_zone" slot-scope="text, data">
                    <p>{{ data.wait_code + ' / ' + data.boost_phone_num + ' / ' + data.boost_name }}</p>
                </template>
                <template slot="status_zone" slot-scope="text">
                    <a-tag v-if="text == 0" color="pink"> Available </a-tag>
                    <a-tag v-if="text == 1" color="green"> On Sale </a-tag>
                    <a-tag v-if="text == 10" color="blue"> Sold </a-tag>
                    <a-tag v-if="text == 11" color="red"> Unavailable </a-tag>
                    <a-tag v-if="text == 16" color="orange"> Expired </a-tag>
                </template>
                <template slot="on_sale_zone" slot-scope="text, data">
                    <div v-for="item in data.sales_data" v-bind:key="item.id">
                        <span>{{ item.target_wait_code + ' / ' + item.target_phone_num + ' / ' + item.status_text }}</span>
                    </div>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-button type="info" @click="show_boost_detail(data)">Show Sales Detail</a-button>
                </div>
            </a-table>
            <a-pagination style="margin-top: 10px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count" show-less-items />
        </div>
        <a-modal
            title="Boost Sales Detail"
            :visible="show_sales_log_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="1200"
            style="height: 400px; overflow: auto;"
            >
            <a-alert style="margin-bottom: 10px;" :message="layer_data.wait_code + ' / ' + layer_data.boost_name" type="info" :close-text="layer_data.sname" />
            <a-table :loading="is_loading_detail" :pagination="false" :rowKey="data => data.id" :columns="columns_log" :data-source="boost_sales_log_list" >
                <div slot="action" slot-scope="text, data">
                    <a-button v-if="data.status < 10" type="link" @click="cancel_sale(data)">Cancel</a-button>
                    <a-button v-if="data.status != 10 && data.paid == 1" type="link" @click="refund(data)">Refund</a-button>
                </div>
            </a-table>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Create time",
            dataIndex: "create_time",
        },
        {
            title: "Boost Info",
            dataIndex: "wait_code",
            scopedSlots: { customRender: "boost_zone" }
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Price",
            dataIndex: "amount_text"
        },
        {
            title: "Status",
            dataIndex: "status",
            scopedSlots: { customRender: "status_zone" }
        },
        {
            title: 'Action',
            dataIndex: '',
            scopedSlots: { customRender: 'action' }
        }
    ];

    const columns_log = [
        {
            title: "Sales Wait Code",
            dataIndex: "target_wait_code"
        },
        {
            title: "Sales Phone",
            dataIndex: "target_phone_num"
        },
        {
            title: "Already Sold Time",
            dataIndex: "sold_waiting_time"
        },
        {
            title: "Expire Time",
            dataIndex: "expire_time"
        },
        {
            title: "Waiting Time",
            dataIndex: "waiting_time"
        },
        {
            title: "Status",
            dataIndex: "status_text",
        },
        {
            title: "Payment Status",
            dataIndex: "paid_status_text",
        },
        {
            title: "Payment Info",
            dataIndex: "pay_id",
            scopedSlots: { customRender: "pay_zone" }
        },
        {
            title: "Action",
            dataIndex: '',
            scopedSlots: { customRender: "action" }
        }
    ];

    export default {
        name: 'boost_sales_history',
        components: {
        },
        data() {
            return {
                current: ['boost_history'],
                boost_list: [],
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                layer_data: {},
                show_sales_log_layer: false,
                show_sale_action_layer: false,
                boost_sales_log_list: [],
                columns_log,
                is_loading_detail: false,
                summary_data: {},
                start_date: '',
                end_date: ''
            };
        },
        beforeMount() {},
        async created() {
            await this.get_boost_list();
            this.get_summary_data();
        },
        watch: {
            current() {
                if (this.current == 'merchant') {
                    this.$router.push({
                        path: '/market/boost/merchants'
                    });
                } else if (this.current == 'robot') {
                    this.$router.push({
                        path: '/market/boost/robots'
                    });
                } else if (this.current == 'boost') {
                    this.$router.push({
                        path: '/market/boost/sales'
                    });
                } else if (this.current == 'transaction') {
                    this.$router.push({
                        path: '/market/boost/transaction'
                    });
                }
            },
        },
        mounted() {
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getBoostHistory: 'boost/getBoostHistory',
                getBoostSalesDetail: 'boost/getBoostSalesDetail',
                getBoostSummaryData: 'boost/getBoostSummaryData',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_boost_list() {
                let { list, total_count, total_page } = await this.getBoostHistory({
                    page: this.cur_page,
                    page_size: 20,
                    status: '',
                    start_date: this.start_date,
                    end_date: this.end_date
                });

                this.boost_list = list;
                this.total_count = total_count * 1;
                this.total_page = total_page * 1;
            },
            show_boost_detail(data) {
                this.layer_data = data;
                this.show_sales_log_layer = true;
                this.get_boost_sales_detail(data);
            },
            async get_boost_sales_detail(data) {
                this.is_loading_detail = true;
                let res = await this.getBoostSalesDetail({
                    boost_id: data.boost_id
                });

                if (res && res.list) {
                    this.boost_sales_log_list = res.list;
                }

                this.is_loading_detail = false;
            },
            handleOk() {
                this.show_sales_log_layer = false;
                this.layer_data = {};
            },
            async get_summary_data() {
                let res = await this.getBoostSummaryData({
                    start_date: this.start_date,
                    end_date: this.end_date,
                    type: 'custom'
                });

                if (res && res.code == 100000) {
                    this.summary_data = res.data;
                }
            },
            turnPage(e) {
                this.get_boost_list();
            },
            onChange(date, dateString) {
                this.start_date = dateString[0];
                this.end_date = dateString[1];
                
                this.get_boost_list();
                this.get_summary_data();
            },
        }
    };
</script>

<style>
</style>
