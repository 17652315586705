<template>
    <div class="main_bd">
        <div class="head_title">Boost</div>
        <a-row style="margin: 10px 0;">
            <a-col :span="4">
            </a-col>
            <a-col :span="4">
                <a-statistic title="Today Boost Count" :value="summary_data.boost_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Success Count" :value="summary_data.boost_success_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Fail Count" :value="summary_data.boost_fail_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Amount" :value="summary_data.boost_sales_amount || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Count" :value="summary_data.boost_sales_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
        </a-row>
        <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="merchant">Merchant management </a-menu-item>
            <a-menu-item key="boost"> Boost Today  </a-menu-item>
            <a-menu-item key="boost_history"> Boost History </a-menu-item>
            <a-menu-item key="transaction"> IAP Transaction </a-menu-item>
            <a-menu-item key="robot"> Robot Management  </a-menu-item>
        </a-menu>
        <div class="mod_default_box permission">
            <div style="margin-bottom: 10px;">
                <a-input v-model="search" @keyup="doKeyUp" placeholder="Search" style="width: 200px; margin-right: 10px;"></a-input>
                <a-button @click="get_boost_merchant_list">Search</a-button>
            </div>
            <a-table :pagination="false" :rowKey="data => data.sid" :columns="columns" :data-source="boost_merchant_list" >
                <template slot="waiting_zone" slot-scope="text, data">
                    <div v-if="boost_data_dict.waiting_data && boost_data_dict.waiting_data[data.sid]">
                        <p v-bind:key="waiting_data.party_type + '_' + data.sid" v-for="waiting_data in boost_data_dict.waiting_data[data.sid]">
                            {{ waiting_data.party_type + ' : ' + waiting_data.waiting_count }}
                        </p>
                    </div>
                </template>
                <template slot="boost_zone" slot-scope="text, data">
                    <div v-if="boost_data_dict.boost_data && boost_data_dict.boost_data[data.sid]">
                        {{ boost_data_dict.boost_data[data.sid].join(',') }}
                    </div>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-button type="primary" @click="show_create_boost_layer(data)">Create new boost</a-button>
                </div>
            </a-table>
        </div>
        <a-modal
            title="Add New Robot"
            :visible="show_add_layer"
            @ok="handleOk"
            @cancel="handleCancel"
            :width="800"
            style="height: 300px; overflow: auto;"
            :confirm-loading="is_adding"
            >
            <a-form-model ref="ruleForm"  :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
                <a-form-model-item label="Merchant Name">
                    <span>{{ layer_data && layer_data.sname || '' }}</span>
                </a-form-model-item>
                <a-form-model-item label="Join Channel">
                    <a-radio-group v-model="channel">
                        <a-radio value="qr_guest"> Customer Online </a-radio>
                        <a-radio value="manual_guest"> Customer Manual </a-radio>
                        <a-radio value="manual_admin"> Admin Manual </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item ref="phone_num" label="Phone" prop="phone_num">
                    <a-input v-model="form.phone_num" :readOnly="true" placeholder="Please input phone num +1 1234567890" :maxLength="30" />
                    <a-button type="primary" @click="randomRobotUser">Random</a-button>
                </a-form-model-item>
                <a-form-model-item ref="customer_name" label="Customer Name" prop="customer_name">
                    <a-input v-model="form.customer_name" :readOnly="true" placeholder="Please input name" :maxLength="30" />
                </a-form-model-item>
                <a-form-model-item ref="people_num" label="Party Size" prop="people_num">
                    <a-input type="number" v-model="form.people_num" placeholder="Please input people number" @blur="
                        () => {
                            $refs.people_num.onFieldBlur();
                        }
                    " :maxLength="30" />
                </a-form-model-item>
                <a-form-model-item ref="amount" label="Amount" prop="amount">
                    <a-input type="number" :readOnly="true" v-model="form.amount" placeholder="Please input amount"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "Sid",
            dataIndex: "sid",
            width: 320
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Waiting",
            dataIndex: "waiting_count",
            scopedSlots: { customRender: 'waiting_zone' }
        },
        {
            title: "Boost",
            dataIndex: "boost",
            scopedSlots: { customRender: 'boost_zone' }
        },
        {
            title: 'Action',
            dataIndex: '',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'boost_merchant_list',
        components: {
        },
        data() {
            return {
                is_adding: false,
                search: '',
                current: ['merchant'],
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                boost_merchant_list: [],
                boost_data_dict: {
                    waiting_data: {},
                    boost_data: {}
                },
                show_add_layer: false,
                layer_data: {},
                form: {
                    phone_num: '',
                    people_num: 4,
                    customer_name: '',
                    amount: 19.99
                },
                rules: {
                    phone_num: [{ required: true, message: 'Please input phone', trigger: 'blur' } ],
                    people_num: [{ required: true, message: 'Please input party size', trigger: 'blur' }],
                    customer_name: [{ required: true, message: 'Please input customer name', trigger: 'blur' }],
                    amount: [{ required: true, message: 'Please input amount', trigger: 'blur' }],
                },
                formLayout: 'horizontal',
                timer: null,
                robot_list: [],
                summary_data: {},
                channel: 'qr_guest'
            };
        },
        beforeMount() {},
        async created() {
            this.get_robot_list();
            this.get_summary_data();
            await this.get_boost_merchant_list();
            this.timer = setInterval(() => {
                this.sync_data(this.boost_merchant_list);
                this.get_summary_data();
            }, 60 * 1000);
        },
        watch: {
            current() {
                if (this.current == 'boost') {
                    this.$router.push({
                        path: '/market/boost/sales'
                    });
                } else if (this.current == 'robot') {
                    this.$router.push({
                        path: '/market/boost/robots'
                    });
                } else if (this.current == 'boost_history') {
                    this.$router.push({
                        path: '/market/boost/history'
                    });
                } else if (this.current == 'transaction') {
                    this.$router.push({
                        path: '/market/boost/transaction'
                    });
                }
            }
        },
        mounted() {
        },
        computed: {
            new_boost_merchant_list() {
                return this.boost_merchant_list.filter(item => {
                    return item.sname.indexOf(this.search) > -1;
                });
            },
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getBoostMerchantList: 'boost/getBoostMerchantList',
                getBoostMerchantData: 'boost/getBoostMerchantData',
                createBoost: 'boost/createBoost',
                getBoostSummaryData: 'boost/getBoostSummaryData',
                getBoostUserList: 'boost/getBoostUserList',
                addBoostUser: 'boost/addBoostUser',
                deleteBoostUser: 'boost/deleteBoostUser',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_boost_merchant_list() {
                let { list } = await this.getBoostMerchantList({
                    sname: this.search
                });

                this.boost_merchant_list = list;
                this.sync_data(this.boost_merchant_list);
            },
            async get_robot_list() {
                let { list } = await this.getBoostUserList({});

                this.robot_list = list;
            },
            async sync_data(temp_list) {
                if (temp_list.length == 0) {
                    return;
                }

                let sid_list = temp_list.map(item => item.sid);

                for (let i = 0; i < 5; i++) {
                    let now_sids = sid_list.slice(i * 10, (i + 1) * 10);
                    if (now_sids.length == 0) {
                        break;
                    }

                    let { data_dict } = await this.getBoostMerchantData({
                        sids: now_sids.join(',')
                    });

                    let { boost_data, waiting_data } = data_dict;

                    for (let sid in waiting_data) {
                        this.$set(this.boost_data_dict, 'waiting_data', Object.assign(this.boost_data_dict.waiting_data, { [sid]: waiting_data[sid] }));
                    }

                    for (let sid in boost_data) {
                        this.$set(this.boost_data_dict, 'boost_data', Object.assign(this.boost_data_dict.boost_data, { [sid]: boost_data[sid] }));
                    }

                    this.$forceUpdate();
                }
            },
            show_create_boost_layer(item) {
                this.layer_data = item;
                this.show_add_layer = true;
            },
            async create_boost() {
                this.is_adding = true;
                let post_data = {
                    sid: this.layer_data.sid,
                    phone_num: this.form.phone_num,
                    people_num: this.form.people_num,
                    customer_name: this.form.customer_name,
                    amount: this.form.amount,
                    operator: this.uname,
                    channel: this.channel
                };

                let res = await this.createBoost(post_data);
                this.is_adding = false;

                if (res.code == 100000) {
                    await this.sync_data([{sid: this.layer_data.sid}]);

                    this.$message.success('Create boost success');
                    this.show_add_layer = false;
                    this.layer_data = null;
                    this.form = {
                        phone_num: '',
                        people_num: 4,
                        customer_name: '',
                        amount: 19.99
                    };
                } else {
                    this.$message.error(res.msg || 'Create boost failed');
                }
            },
            handleCancel() {
                this.show_add_layer = false;
            },
            async handleOk() {
                await this.create_boost();
            },
            randomRobotUser() {
                if (this.robot_list.length == 0) {
                    this.get_robot_list();
                    return;
                }

                let random_index = Math.floor(Math.random() * this.robot_list.length);
                let random_user = this.robot_list[random_index];

                this.form.phone_num = random_user.phone;
                this.form.customer_name = random_user.name;
            },
            async get_summary_data() {
                let res = await this.getBoostSummaryData({ type: 'today', start_date: '', end_date: '' });

                if (res && res.code == 100000) {
                    this.summary_data = res.data;
                }
            },
            doKeyUp(e) {
                if (e.keyCode === 13) {
                    this.get_boost_merchant_list();
                }
            }
        }
    };
</script>

<style>
</style>
