<template>
    <div class="main_bd">
        <div style="margin: 40px 0">
            <a-breadcrumb>
                <span slot="separator" style="color: #000; font-size: 22px">></span>
                <router-link :to="'/market/list'">
                    <a-breadcrumb-item class="b-item-1">Push List</a-breadcrumb-item>
                </router-link>
                <a-breadcrumb-item class="b-item-2">{{ task_id ? 'Edit the push task' : 'Create a new push task' }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="permission">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
                <div class="form-item">
                    <a-form-model-item ref="task_name" label="Task Name" prop="task_name">
                        <a-input v-model="form.task_name" placeholder="No more than 128 characters" @blur="
                            () => {
                                $refs.task_name.onFieldBlur();
                            }
                        " :maxLength="128" :disabled="task_status != 0" />
                    </a-form-model-item>
                    <a-form-model-item ref="task_type" label="Task Type" prop="task_type">
                        <a-radio-group v-model="form.task_type" :disabled="task_status != 0">
                            <a-radio value="message">
                                Message
                            </a-radio>
                            <a-radio value="app_push" v-if="false">
                                App Push
                            </a-radio>
                            <a-radio value="email" v-if="false">
                                Email
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item ref="content" label="Content" prop="content">
                        <a-textarea v-model="form.content" placeholder="No more than 500 characters" @blur="
                            () => {
                                $refs.content.onFieldBlur();
                            }
                        " :maxLength="500" :disabled="task_status != 0" :auto-size="{ minRows: 4, maxRows: 20 }"/>
                    </a-form-model-item>
                    <a-form-model-item label="Image" prop="image_url" extra="image up to 5MB, image size: 375 * 150. JPG, PNG only">
                        <upload-img :disabled="task_status != 0" :cutwidth="375" :cutheight="150" @upload="upload_poster" :photo="form.image_url"></upload-img>
                    </a-form-model-item>
                    <a-form-model-item label="Phone List File" prop="phone_numbers_file">
                        <div>
                            <input style="height: 40px; display: inline-block;" class="frm_input" ref="phone_numbers_file" @change="onChangeFile" type="file">
                            <a-button v-if="phone_numbers.length > 0" type="link" class="" @click="show_phone_layer"> Show all phone numbers </a-button>
                        </div>
                        <div>
                            <span>csv only. Only one column, no column header required. Ex: +1 123456789</span>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 14 }">
                        <a-space>
                            <a-button type="link" class="" @click="cancel"> Back </a-button>
                            <a-button v-if="task_status == 0" @click="submitDraft"> Save </a-button>
                            <a-button v-if="task_status == 0" type="primary" class="create-btn" @click="submitPublish"> Save & Run </a-button>
                        </a-space>
                    </a-form-model-item>
                </div>
            </a-form-model>
        </div>
        <a-modal
            title="Phone number list"
            :visible="if_phone_layer_show"
            @ok="handleOk"
            @cancel="handleOk"
            style="height: 300px; overflow: auto;"
            >
            <p v-bind:key="item" v-for="item in phone_numbers">
                {{ item }}
            </p>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import UploadImg from '../../components/UploadImg.vue';

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from "moment";
    import axios from 'axios';

    export default {
        name: 'NotificationCreate',
        components: { UploadImg },
        data() {
            return {
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                labelCol: { span: 3 },
                wrapperCol: { span: 10 },
                phone_numbers: [],
                task_id: '',
                form: {
                    task_name: '',
                    task_type: 'message',
                    content: '',
                    image_url: ''
                },
                rules: {
                    task_name: [
                        { required: true, message: 'Please Task Name', trigger: 'blur' },
                        { min: 1, max: 128, message: 'No more than 128 characters', trigger: 'blur' }
                    ],
                    task_type: [
                        { required: true, message: 'Please Choose Task Type', trigger: 'blur' },
                    ],
                    content: [
                        { required: true, message: 'Please Content', trigger: 'blur' },
                        { min: 1, max: 512, message: 'No more than 512 characters', trigger: 'blur' }
                    ],
                    phone_numbers_file: [
                        { required: true, message: 'Please Upload phone list file' },
                    ],
                },
                task_status: 0,
                if_phone_layer_show: false
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            const { task_id } = this.$route.query
            if(task_id){
                this.task_id = task_id;
                let res = await this.getNotificationDetail({
                    task_id: this.task_id
                });

                let { task_name, task_type, task_status, content, image_url, phone_numbers } = res.info;

                this.form.task_name = task_name;
                this.form.task_type = task_type;
                this.form.content = content;
                this.form.image_url = image_url;

                this.task_status = task_status;
                this.phone_numbers = phone_numbers && phone_numbers.split(',') || [];
            }
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getNotificationDetail: 'market/getNotificationDetail',
                createNotificationTask: 'market/createNotificationTask',
                runNotification: 'market/runNotification',
                updateNotificationTask: 'market/updateNotificationTask'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            onChangeFile(e) {
                let filePath = this.$refs.phone_numbers_file.value;
                let arr = filePath.split('\\');
                let nameArr = arr[arr.length - 1].split('.');
                let fileName = nameArr[0];
                let pic_file = this.$refs.phone_numbers_file.files;
                let formData = new FormData();
                formData.append('file', pic_file[0]);
                formData.append('name', fileName);

                if(filePath.indexOf("csv") === -1) {
                    this.$message.error('Please upload csv file');
                    return false;
                }

                let aj_host = `https://${window.location.host}`;
                // let aj_host = 'http://127.0.0.1:5050';

                const token = this.$store.state.app.token;

                axios({
                    method: 'POST',
                    url: aj_host + '/weapp/minipass/market/notification/file/upload',
                    data: formData,
                    headers: { 
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then((res) => {
                        if (res.data.data.code == 100000) {
                            let { list } = res.data.data.data;
                            this.phone_numbers = list;
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            upload_poster(url) {
                this.form.image_url = url;
            },
            cancel() {
                this.$router.go(-1);
            },
            async submitDraft() {
                let {
                    task_name,
                    task_type,
                    content,
                    image_url
                } = this.form;

                let post_data = {
                    task_id: this.task_id,
                    task_name,
                    task_type,
                    content,
                    image_url,
                    operator: this.uname,
                    phone_numbers: this.phone_numbers && this.phone_numbers.join(',') || ''
                };

                let res = null;
                if (this.task_id) {
                    res = await this.updateNotificationTask(post_data);
                } else {
                    res = await this.createNotificationTask(post_data);
                }

                if (res && res.code == 100000) {
                    this.$message.success('Save successfully');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error('Save failed');
                }
            },
            async submitPublish() {
                let {
                    task_name,
                    task_type,
                    content,
                    image_url
                } = this.form;

                if (task_name == '') {
                    this.$message.error('Please Task name');
                    return;
                }

                if (this.phone_numbers.length == 0) {
                    this.$message.error('Please upload phone list');
                    return;
                }

                if (content == '') {
                    this.$message.error('Please Content');
                    return;
                }

                if (task_type == '') {
                    this.$message.error('Please choose task type');
                    return;
                }

                let post_data = {
                    task_id: this.task_id,
                    task_name,
                    task_type,
                    content,
                    image_url,
                    operator: this.uname,
                    phone_numbers: this.phone_numbers && this.phone_numbers.join(',') || ''
                };

                let res = null;

                if (this.task_id) {
                    res = await this.updateNotificationTask(post_data);
                } else {
                    res = await this.createNotificationTask(post_data);
                }

                if (res && res.data.task_id) {
                    let run_res = await this.runNotification({
                        task_id: res.data.task_id
                    });

                    if (run_res) {
                        this.$message.success('Submit success');
                        setTimeout(() => {
                            this.$router.go(-1);
                        }, 1000);
                    } else {
                        this.$message.error('Submit failed');
                    }
                } else {
                    this.$message.error('Submit failed');
                }
            },
            show_phone_layer() {
                this.if_phone_layer_show = true;
            },
            handleOk() {
                this.if_phone_layer_show = false;
            }
        }
    };
</script>

<style>
    
</style>