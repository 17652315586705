<template>
    <div class="main_bd">
        <div class="head_title">Marketing Push Management</div>
        <div class="action_content">
            <div>
                <button style="width: 260px;" class="action_btn active_btn" @click="turn_to_create">Create new push task</button>
            </div>
        </div>
        <div class="action_content" style="margin-top: 20px;">
            <div>
                <span style="padding-right: 20px;">Push list</span>
                <a-radio-group v-model="task_status" button-style="solid" @change="onChangeStatus">
                    <a-radio-button value="">
                        All
                    </a-radio-button>
                    <a-radio-button :value="0">
                        unPublished
                    </a-radio-button>
                    <a-radio-button :value="1">
                        Publishing
                    </a-radio-button>
                    <a-radio-button :value="3">
                        Pausing
                    </a-radio-button>
                    <a-radio-button :value="4">
                        Done
                    </a-radio-button>
                </a-radio-group>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="notification_list" >
                <template slot="task_type" slot-scope="text, data">
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.task_type == 'message'">
                        Message
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_type == 'app_push'">
                        App Push
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_type == 'email'">
                        Email
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_type == 'all'">
                        All
                    </span>
                </template>
                <template slot="schedule" slot-scope="text, data">
                    <p style="width: 90px; border-radius: 12px; text-align: center;">
                        Total: {{data.total_count}}
                    </p>
                    <p style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.task_status >= 1">
                        Sent: {{data.sent_count}}
                    </p>
                    <p style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.task_status >= 1">
                        Success: {{data.success_count}}
                    </p>
                </template>
                <template slot="task_status" slot-scope="text, data">
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.task_status == 0">
                        unPublished
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_status == 1">
                        Publishing
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_status == 3">
                        Pausing
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.task_status == 4">
                        Done
                    </span>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-popover title="" trigger="click" placement="bottom" @visibleChange="handleClickChange(data)">
                        <template slot="content">
                            <div v-if="data.task_status == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="turn_to_create(data)">Edit</a>
                            </div>
                            <div v-if="data.task_status == 1">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="pause_task(data)">Pause</a>
                            </div>
                            <div v-if="data.task_status == 3">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="continue_task(data)">Continue</a>
                            </div>
                            <div v-if="data.task_status == 0 || data.task_status == 3">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="delete_task(data)">Delete</a>
                            </div>
                            <div>
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="show_detail(data)">Show details</a>
                            </div>
                        </template>
                        <a-button type="link">
                            ...
                        </a-button>
                    </a-popover>
                </div>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count"
                show-less-items />
        </div>
        <a-modal
            :visible="show_content_layer"
            @ok="cancel_content_layer"
            @cancel="cancel_content_layer"
            :width="1000"
            style="height: 500px; overflow: auto;"
            >
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Notification Log - {{ notification_detail.task_name }}</div>
                    </div>
                    <div>
                        <a-tag color="grey" v-if="notification_detail.task_status == 0">
                            unPublished
                        </a-tag>
                        <a-tag color="green" v-else-if="notification_detail.task_status == 1">
                            Publishing
                        </a-tag>
                        <a-tag color="red" v-else-if="notification_detail.task_status == 3">
                            Pausing
                        </a-tag>
                        <a-tag color="blue" v-else-if="notification_detail.task_status == 4">
                            Done
                        </a-tag>
                        <a-button type="link" @click="doDownload(notification_detail.task_id)" v-if="notification_detail.task_status == 4">
                            Export
                        </a-button>
                    </div>
                </div>
            </template>
            <a-table :scroll="{ x: 1000, y: 500 }" :pagination="false" :rowKey="data => data.id" :columns="notification_columns" :data-source="notification_log_list" >
                <template slot="status_zone" slot-scope="text, data">
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.status == 0">
                        Sending
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.status == 1">
                        Delivered
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.status == 2">
                        Failed
                    </span>
                </template>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="logTurnPage" :pageSize="20" v-model="log_cur_page" :total="log_total_count"
                show-less-items />
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: "Name",
            dataIndex: "task_name",
            width: 150
        },
        {
            title: "Type",
            dataIndex: "task_type",
            scopedSlots: { customRender: 'task_type' }
        },
        {
            title: "Status",
            dataIndex: "task_status",
            scopedSlots: { customRender: 'task_status' }
        },
        {
            title: "Task id",
            dataIndex: "task_id"
        },
        {
            title: "Content",
            dataIndex: "content",
            width: 300,
            ellipsis: true
        },
        {
            title: "Schedule",
            dataIndex: "schedule",
            scopedSlots: { customRender: 'schedule' }
        },
        {
            title: "Operator",
            dataIndex: "operator"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    const notification_columns = [
        {
            title: "Phone Number",
            dataIndex: "phone_number",
        },
        {
            title: "Status",
            dataIndex: "status",
            scopedSlots: { customRender: 'status_zone' }
        },
        {
            title: "From",
            dataIndex: "from_phone_number",
        },
        {
            title: "Message status",
            dataIndex: "msg_status",
            width: 150
        },
        {
            title: "Message Id",
            dataIndex: "msg_id"
        },
        {
            title: "Create time",
            dataIndex: "create_time",
        },
    ];

    export default {
        name: 'notification_list',
        components: {
        },
        data() {
            return {
                task_status: '',
                sid: window.localStorage.getItem('sid'),
                columns,
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                notification_list: [],
                show_content_layer: false,

                notification_log_list: [],
                log_cur_page: 1,
                log_total_page: 0,
                log_total_count: 0,
                notification_columns,
                notification_detail: {}
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.get_notification_list();
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getNotificationList: 'market/getNotificationList',
                updateNotificationTask: 'market/updateNotificationTask',
                runNotification: 'market/runNotification',
                getNotificationDetail: 'market/getNotificationDetail',
                getNotificationLogList: 'market/getNotificationLogList',
                exportNotificationLog: 'market/exportNotificationLog'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            onChangeStatus(e) {
                this.get_notification_list();
            },
            cancel_content_layer() {
                this.show_content_layer = false;
            },
            async show_detail(data) {
                await this.get_notification_log_list();
                this.notification_detail = data;
                this.show_content_layer = true;
            },
            async get_notification_list() {
                let { list, total_count, total_page } = await this.getNotificationList({
                    page: this.cur_page,
                    page_size: 20,
                    task_status: this.task_status
                });

                this.notification_list = list;
                this.total_page = total_page;
                this.total_count = total_count;
            },
            turnPage(e) {
                this.get_notification_list();
            },
            turn_to_create(data) {
                this.$router.push({
                    path: data.task_id ? '/market/create?task_id=' + data.task_id : '/market/create'
                });
            },
            async delete_task(data) {
                this.$confirm({
                    title: 'Are you sure you want to delete?',
                    content: 'Unable to recover after deletion',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.updateNotificationTask({
                            task_id: data.task_id,
                            task_status: -1
                        });
                        this.get_notification_list();
                    },
                    onCancel: async () => {},
                });
            },
            async do_run(data) {
                this.$confirm({
                    title: 'Are you sure you want to run?',
                    content: 'You can stop by pausing',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        let res = await this.doRunTask({
                            task_id: data.task_id
                        });

                        if (res && res.code != 100000) {
                            this.$message.error(res.msg);
                            return;
                        }

                        this.get_ad_list();
                    },
                    onCancel: async () => {},
                });
            },
            async pause_task(data) {
                this.$confirm({
                    title: 'Are you sure you want to pause this task?',
                    content: 'You can continue by clicking continue',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.updateNotificationTask({
                            task_id: data.task_id,
                            task_status: 3
                        });
                        this.get_notification_list();
                    },
                    onCancel: async () => {},
                });
            },
            async continue_task(data) {
                await this.updateNotificationTask({
                    task_id: data.task_id,
                    task_status: 1
                });
                this.get_notification_list();
            },
            handleClickChange(a, b) {},
            async get_notification_log_list() {
                let { list, total_count, total_page } = await this.getNotificationLogList({
                    page: this.log_cur_page,
                    page_size: 20
                });

                this.notification_log_list = list;
                this.log_total_page = total_page;
                this.log_total_count = total_count;
            },
            logTurnPage(e) {
                this.get_notification_log_list();
            },
            doDownload(task_id) {
                this.exportNotificationLog({
                    task_id
                }).then(res => {
                    let uploadExcel = fileName => {
                        const blob = new Blob([res], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };
                    uploadExcel('notification_' + task_id + ".xlsx");
                });
            }
        }
    };
</script>

<style>
    .action_content {
        display: flex;
        justify-content: space-between;
    }
    .action_btn {
        width: 102px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: #7427FF;
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
    .action_btn.delete_btn {
        background-color: #FD7858;
    }
    .action_btn.deactivate_btn {
        background-color: #7D7D7D;
    }
    .action_btn.active_btn {
        background-color: #2C9100;
    }
    .been_activated {
        text-align: center;
        line-height: 26px;
        font-size: 14px;
    }
    .been_activated.activated_orange {
        color: #FD7858;
    }
    .content_layer h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #7427FF;
    }
    .content_layer .content_item {
        display: flex;
        color: #333;
        margin: 0;
        line-height: 28px;
    }
    .content_layer .content_item dt {
        font-weight: 500;
        margin-right: 10px;
    }
    .content_layer .content_item dd {
        font-size: 12px;
        margin-right: 20px;
    }
    .content_layer .content_item a {
        color: #7427FF;
        cursor: text;
    }
    .content_layer_bottom {
        border-top: 1px solid #d8d8d8;
        margin-top: 10px;
    }
    .content_layer_top {
        position: relative;
    }
    .content_layer_btn {
        position: absolute;
        top: -5px;
        right: 20px;
        line-height: 24px;
    }
    .content_layer_btn .action_btn {
        border-radius: 12px;
        height: 24px;
        font-size: 13px;
        width: 90px;
        cursor: auto;
    }
    .image_box a {
        display: block;
    }
</style>