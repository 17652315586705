<template>
    <div class="main_bd">
        <div class="head_title">User Feedback</div>
        <div class="in_title">The user experience survey results</div>
        <div class="fileter">
            <div>Filtered by submit date</div>
            <div style="margin-top: 10px">
                <span class="date">
                    <a-date-picker v-model="start_date" placeholder="Start date" style="width: 100%" />
                </span>
                <span style="margin: 0 10px">to</span>
                <span class="date">
                    <a-date-picker v-model="end_date" placeholder="End date" style="width: 100%" :disabled-date="disabledEndDate" />
                </span>
                <button class="done_btn" @click="fliterData">Done</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey='data => data.id' :columns="columns" :data-source="feedback_list">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.idx }}</p>
                </a>
                <template slot="action" slot-scope="text, data">
                    <a-button type="primary" size="small" style="background-color: #6200eed9; border-color: #6200eed9"
                        @click="toDetail(data)">
                        details
                    </a-button>
                </template>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="10" v-model="page" :total="total_count"
                show-less-items />
        </div>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">Survey result details</div>
                        <div>Phone <a @click="toUserDetail" style="border-bottom: 1px solid #333">{{ item.phone }}</a></div>
                    </div>
                    <div style="padding-top: 38px;">Case id: {{ item.case_id }}</div>
                    <div style="padding-top: 38px;">{{ item.create_time }}</div>
                </div>
            </template>
            <a-descriptions>
                <div slot="title">How would you rate your experience with Minipass?</div>
                <a-descriptions-item label="" :span="3">
                    {{ item.experience_star }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div slot="title">Did the app help you manage dine-in schdeule?</div>
                <a-descriptions-item label="" :span="3">
                    {{ item.help_schedule }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div slot="title">Did the app help you manage dine-in schdeule?</div>
                <a-descriptions-item label="" :span="3">
                    {{ item.suggestion ? item.suggestion : 'N/A' }}
                </a-descriptions-item>
            </a-descriptions>
            <template slot="footer">
                <a-button type="primary" @click="handleOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 60px;margin-right: 300px;">
                    Done
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<style>
.in_title {
    font-size: 16px;
    text-align: center;
}
.fileter {
    text-align: right;
    margin-top: 30px;
    font-size: 16px;
}
.date {
    display: inline-block;
    width: 200px;
}
.done_btn {
    width: 82px;
    height: 28px;
    border: none;
    border-radius: 8px;
    background-color: rgba(116, 39, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Helvetica';
    font-size: 16px;
    margin-left: 10px;
}
</style>

<script>
import Vue from 'vue';

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import moment from "moment";

const columns = [
    {
        title: "No.",
        dataIndex: "index",
        scopedSlots: { customRender: "index" },
        width: 200
    },
    {
        title: "Submit date",
        dataIndex: "create_time",
        width: 300
    },
    {
        title: "User phone",
        dataIndex: "phone"
    },
    {
        title: "Case id",
        dataIndex: "case_id"
    },
    {
        title: 'More',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];

export default {
    name: 'feedback',
    components: {
    },
    data() {
        return {
            columns,
            page: 1,
            start_date: '',
            end_date: '',
            item: {},
            detailVisible: false
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    async created() {
        this.getFeedbackList({
            page: 1
        });
    },
    watch: {
    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            feedback_list: "feedback/get_feedback_list",
            total_page: "feedback/get_total_page",
            total_count: "feedback/get_total_count",
        }),
        ...mapState({
        })
    },
    methods: {
        ...mapActions({
            getFeedbackList: 'feedback/getFeedbackList',
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        }),
        disabledEndDate(current) {
            return current && current.valueOf() <= moment(this.start_date).valueOf();
        },
        fliterData(){
            console.log(this.start_date, this.end_date)
            this.getFeedbackList({
                page: this.page,
                start_date: this.start_date ? moment(this.start_date).valueOf() : null,
                end_date: this.end_date ? moment(this.end_date).valueOf() : null
            });
        },
        toDetail(data) {
            this.item = { ...data };
            this.detailVisible = true;
        },
        handleOk() {
            this.detailVisible = false;
        },
        toUserDetail() {
            console.log(this.item)
            this.$router.push({
                path: '/guests',
                query: { user_id: this.item.cid }
            });
        },
        turnPage(e) {
            this.getFeedbackList({
                page: this.page,
                start_date: this.start_date ? moment(this.start_date).valueOf() : null,
                end_date: this.end_date ? moment(this.end_date).valueOf() : null
            });
        }
    }
};
</script>
