<template>
    <div class="main_bd">
        <div class="head_title">Ad management</div>
        <div class="frm_controls" style="margin: 30px 0">
            <a-menu v-model="current" mode="horizontal">
                <a-menu-item key="ad_campaign">Ad campaign management </a-menu-item>
                <a-menu-item key="ad_space"> Ad Space & Ad Display Management  </a-menu-item>
            </a-menu>
        </div>
        <div class="">
            <div v-bind:key="item.id" v-for="item,index in space_list" style="margin-top: 20px;">
                <a-card :title="item.space_name" style="width: 100%;" :bordered="false">
                    <span slot="extra">
                        <span v-if="item.space_type === 'schedule_bottom' || item.space_type === 'open_app_ad'">
                            Ad Type: 
                            <a-select v-model="item.ad_source" style="width: 150px" @change="handleChangeSource(item.id, $event)">
                                <a-select-option value="google_admob">
                                    Google Admob
                                </a-select-option>
                                <a-select-option value="minipass">
                                    Minipass
                                </a-select-option>
                            </a-select>
                        </span>
                        Display status {{ item.status ? 'On' : 'Off' }}
                        <a-switch :disabled="is_editing > -1 || (item.ad_list.length == 0 && item.ad_source != 'google_admob')" v-model="item.status" @change="update_status(item, $event)" />
                    </span>
                    <p v-if="item.ad_source === 'minipass'">
                        Campaigns: <span style="color: #7427ff; padding-right: 20px;">max display qty: {{ item.max_display_qty}}</span>
                        <a-button type="primary" @click="do_add_ads(item)" v-if="item.editing">Edit list</a-button>
                        <a-button type="primary" @click="do_edit(item, index)" style="float: right;" v-if="!item.editing" :disabled="item.status">Edit</a-button>
                        <a-button type="primary" @click="do_save(item, index)" style="float: right;" v-if="item.editing">Save Edit</a-button>
                        <a-button type="link" @click="do_cancel(item, index)" style="float: right;margin-left: 10px;" v-if="item.editing">Cancel</a-button>
                    </p>
                    <div v-if="item.ad_source == 'google_admob' && item.space_type == 'open_app_ad'">
                        <p>
                            Display Ad on first launch
                            <a-switch v-model="item.google_admob_display_first_launch" @change="onOpenAppChange" />
                        </p>
                        <p style="display: inline-block; width: 500px;">
                            Interval between ads:
                            <a-input style="display: inline-block; width: 150px;" v-model="item.google_admob_between_ads_seconds" suffix="sec(s)" />
                            <a-button style="display: inline-block; margin-left: 20px;" type="primary" @click="do_google_admob_save(item, index)">Save Edit</a-button>
                        </p>
                    </div>
                    <a-table v-if="item.ad_source === 'minipass'" :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="item.ad_edit_list || item.ad_list" >
                        <template slot="type" slot-scope="text, data">
                            <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.ad_type == 'general'">
                                General
                            </span>
                            <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'poster'">
                                Custom poster
                            </span>
                            <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'external_coupon'">
                                External coupon
                            </span>
                            <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'system_coupon'">
                                System coupon
                            </span>
                            <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'full_screen'">
                                Full screen
                            </span>
                        </template>
                        <div slot="action" slot-scope="text, data">
                            <a v-if="item.editing" style="color: #FD7858; line-height: 46px; vertical-align: top; display: inline-block;" @click="do_remove(data, index)">Remove</a>
                            <span v-if="item.editing" style="display: inline-block; padding-left: 10px;">
                                <p @click="changePos('up', index, data)" style="margin: 0; cursor: pointer;"><a-icon :style="{ fontSize: '18px' }" type="caret-up" /></p>
                                <p @click="changePos('down', index, data)" style="margin: 0; cursor: pointer;"><a-icon :style="{ fontSize: '18px' }" type="caret-down" /></p>
                            </span>
                        </div>
                    </a-table>
                </a-card>
            </div>
        </div>
        <a-modal
            title="Edit ad campaign from the ad list"
            :visible="show_adding_layer"
            @ok="do_layer_add"
            @cancel="do_layer_cancel"
            okText="Confirm"
            cancelText="Cancel"
            centered
            width="900px"
        >
            <div>
                <div style="text-align: left;" class="been_activated">Compatible type: {{ layer_data.ad_type }}</div>
                <div style="text-align: left;" class="been_activated">Max display qty: {{ layer_data.max_display_qty }}</div>
                <a-table 
                    :pagination="false"
                    :rowKey="data => data.id"
                    :columns="columns_layer"
                    :data-source="ad_list"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                    <template slot="type" slot-scope="text, data">
                        <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.ad_type == 'general'">
                            General
                        </span>
                        <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'poster'">
                            Custom poster
                        </span>
                        <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'system_coupon'">
                            System coupon
                        </span>
                        <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'external_coupon'">
                            External coupon
                        </span>
                        <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'full_screen'">
                            Full screen
                        </span>
                    </template>
                </a-table>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: "Name",
            dataIndex: "ad_name",
            width: 300
        },
        {
            title: "Type",
            dataIndex: "ad_type",
            scopedSlots: { customRender: 'type' }
        },
        {
            title: "Ad id",
            dataIndex: "ad_id"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    const columns_layer = [
        {
            title: "Name",
            dataIndex: "ad_name",
            width: 300
        },
        {
            title: "Type",
            dataIndex: "ad_type",
            scopedSlots: { customRender: 'type' }
        },
        {
            title: "Ad id",
            dataIndex: "ad_id"
        }
    ];

    export default {
        name: 'adSpace',
        components: {
        },
        data() {
            return {
                current: ['ad_space'],
                sid: window.localStorage.getItem('sid'),
                columns,
                columns_layer,
                space_list: [],
                show_adding_layer: false,
                ad_list: [],
                layer_data: {},
                layer_selected: [],
                onSelectChange:  (selectedRowKeys, selectedRows) => {
                    // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    this.layer_selected = selectedRows;
                    this.selectedRowKeys = selectedRowKeys;

                    this.$forceUpdate();
                },
                selectedRowKeys: [],
                is_editing: -1
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.get_space_list();
        },
        watch: {
            current() {
                if (this.current.indexOf('ad_campaign') > -1) {
                    this.$router.push({
                        path: '/adlist'
                    });
                }
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getSpaceList: 'ad/getSpaceList',
                updateSpaceAds: 'ad/updateSpaceAds',
                updateSpaceStatus: 'ad/updateSpaceStatus',
                getAdList: 'ad/getAdList',
                updateSource: 'ad/updateSource',
                updateGoogleAdmobSetting: 'ad/updateGoogleAdmobSetting'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_space_list() {
                let { list } = await this.getSpaceList();

                list = list.map(item => {
                    item.editing = 0;
                    item.status = item.status == 1 ? true : false;

                    item.google_admob_display_first_launch = item.google_admob_setting.display_first_launch || false;
                    item.google_admob_between_ads_seconds = item.google_admob_setting.between_ads_seconds || 1;

                    delete item.google_admob_setting;

                    return item;
                });

                this.space_list = list;
            },

            do_remove(data, index) {
                let ad_list = this.space_list[index].ad_list;
                let child_index = ad_list.findIndex(item => item.id == data.id);
                ad_list = [...ad_list.splice(0, child_index), ...ad_list.splice(child_index + 1)];

                this.$set(this.space_list, index, {
                    ...this.space_list[index],
                    ad_edit_list: ad_list
                });
            },
            changePos(type, index, data) {
                let ad_list = this.space_list[index].ad_list;
                let child_index = ad_list.findIndex(item => item.id == data.id);

                if (type == 'up') {
                    if (child_index == 0) {
                        return;
                    }

                    let temp = ad_list[child_index - 1];
                    ad_list[child_index - 1] = ad_list[child_index];
                    ad_list[child_index] = temp;
                } else {
                    if (child_index == ad_list.length - 1) {
                        return;
                    }

                    let temp = ad_list[child_index + 1];
                    ad_list[child_index + 1] = ad_list[child_index];
                    ad_list[child_index] = temp;
                }

                this.$set(this.space_list, index, {
                    ...this.space_list[index],
                    ad_edit_list: ad_list
                });
            },
            findChildIndex(data, index) {
                let ad_list = this.space_list[index].ad_list;
                let child_index = ad_list.findIndex(item => item.id == data.id);

                return child_index;
            },
            async update_status(data, evt) {
                let res = await this.updateSpaceStatus({
                    status: evt ? 1 : 0,
                    id: data.id
                });

                if (res) {
                    this.$message.success('Updated successfully');
                }
            },
            do_edit(data, index) {
                this.$set(this.space_list, index, {
                    ...data,
                    editing: 1
                });

                this.is_editing = index;
            },
            do_cancel(data, index) {
                this.$set(this.space_list, index, {
                    ...this.space_list[index],
                    ad_edit_list: '',
                    editing: 0
                });

                this.is_editing = -1;
                this.selectedRowKeys = [];
            },
            async do_save(data, index) {
                this.is_editing = -1;
                this.selectedRowKeys = [];

                let now_ad_list = this.space_list[index].ad_edit_list;

                if (!now_ad_list) {
                    this.get_space_list();
                    this.$message.success('Updated successfully');
                    return;
                }
                let res = await this.updateSpaceAds({
                    ad_list: now_ad_list,
                    id: data.id
                });

                if (res) {
                    this.get_space_list();
                    this.$message.success('Updated successfully');
                }
            },
            do_layer_add() {
                if (this.layer_selected.length > this.layer_data.max_display_qty) {
                    this.$message.error('You can not select more than ' + this.layer_data.max_display_qty + ' ads');

                    return false;
                }

                this.show_adding_layer = false;

                this.$set(this.space_list, this.is_editing, {
                    ...this.space_list[this.is_editing],
                    ad_edit_list: JSON.parse(JSON.stringify(this.layer_selected)),
                });

                this.layer_selected = [];
            },
            do_layer_cancel() {
                this.show_adding_layer = false;
                this.selectedRowKeys = [];
                this.layer_selected = [];
            },
            do_add_ads(data) {
                this.layer_data = data;
                this.layer_selected = [];
                this.get_ad_list(data.ad_type);
                this.show_adding_layer = true;
            },
            async get_ad_list(ad_type) {
                let { list } = await this.getAdList({
                    page: 0,
                    status: 1,
                    page_size: 20,
                    ad_type: ad_type
                });

                let layer_dict = {};
                this.layer_data && this.layer_data.ad_list.map(item => {
                    layer_dict[item.ad_id] = 1;
                });

                list = list.map((item, index) => {
                    if (layer_dict[item.ad_id]) {
                        this.selectedRowKeys.push(item.id);

                        delete item.displaying;
                        
                        this.layer_selected.push(item);
                    }

                    return item;
                });

                this.ad_list = list;
            },
            async handleChangeSource(id, val) {
                let res = await this.updateSource({
                    ad_source: val,
                    id: id
                });

                if (res) {
                    this.$message.success('Updated successfully');
                }
            },
            async do_google_admob_save(data, index) {
                let setting = {
                    display_first_launch: data.google_admob_display_first_launch,
                    between_ads_seconds: data.google_admob_between_ads_seconds
                };

                let res = await this.updateGoogleAdmobSetting({
                    google_admob_setting: JSON.stringify(setting),
                    id: data.id
                });

                if (res) {
                    this.$message.success('Updated successfully');
                }
            },
            onOpenAppChange() {

            }
        }
    };
</script>

<style>
    .action_content {
        display: flex;
        justify-content: space-between;
    }
    .action_btn {
        width: 102px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: #7427FF;
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
    .action_btn.delete_btn {
        background-color: #FD7858;
    }
    .action_btn.deactivate_btn {
        background-color: #7D7D7D;
    }
    .action_btn.active_btn {
        background-color: #2C9100;
    }
    .been_activated {
        text-align: center;
        line-height: 26px;
        font-size: 14px;
    }
    .been_activated.activated_orange {
        color: #FD7858;
    }
    .content_layer h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #7427FF;
    }
    .content_layer .content_item {
        display: flex;
        color: #333;
        margin: 0;
        line-height: 28px;
    }
    .content_layer .content_item dt {
        font-weight: 500;
        margin-right: 10px;
    }
    .content_layer .content_item dd {
        font-size: 12px;
        margin-right: 20px;
    }
    .content_layer .content_item a {
        color: #7427FF;
        cursor: text;
    }
    .content_layer_bottom {
        border-top: 1px solid #d8d8d8;
        margin-top: 10px;
    }
    .content_layer_top {
        position: relative;
    }
    .content_layer_btn {
        position: absolute;
        top: -5px;
        right: 20px;
        line-height: 24px;
    }
    .content_layer_btn .action_btn {
        border-radius: 12px;
        height: 24px;
        font-size: 13px;
        width: 90px;
        cursor: auto;
    }
    .image_box a {
        display: block;
    }
</style>