import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {};

// getters
const getters = {
};

// actions
const actions = {
    getBoostMerchantList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/merchant/list?sname=` + post_data.sname)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    getBoostMerchantData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/merchant/data?sids=${post_data.sids}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    createBoost({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/create`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/list?status=0,1,10,11`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    getBoostHistory({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/search?status=${post_data.status}&start_date=${post_data.start_date}&end_date=${post_data.end_date}&page=${post_data.page}&page_size=${post_data.page_size}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    cancelBoost({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/cancel`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getWaitingListBySid({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/waitlist/list?sid=${post_data.sid}&rank=${post_data.rank}&party_type=${post_data.party_type}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    createBoostSalesLog({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/sales/create`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    checkMinipassMember({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/member/check`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostSalesDetail({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/sales/detail?boost_id=${post_data.boost_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    checkIfSaledByBoostId({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/sales/check`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    cancelBoostSales({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/sales/cancel`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostWaitingData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/waiting/data`, post_data)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostSummaryData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/summary/data?start_date=${post_data.start_date}&end_date=${post_data.end_date}&type=${post_data.type}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostUserList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/r/list`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    addBoostUser({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/r/add`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    delBoostUser({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/r/del`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getUserHistoryWaitlistData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/boost/history/data`, post_data)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getBoostTransactionList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/transaction/search?page=${post_data.page}&page_size=${post_data.page_size}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
    getBoostTransactionInfo({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/boost/transaction/info?pay_id=${post_data.pay_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};