var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_bd"},[_c('div',{staticStyle:{"margin":"40px 0"}},[_c('a-breadcrumb',[_c('span',{staticStyle:{"color":"#000","font-size":"22px"},attrs:{"slot":"separator"},slot:"separator"},[_vm._v(">")]),_c('router-link',{attrs:{"to":'/master/list'}},[_c('a-breadcrumb-item',{staticClass:"b-item-1"},[_vm._v("Master Account")])],1),_c('a-breadcrumb-item',{staticClass:"b-item-2"},[_vm._v(_vm._s(_vm.chain_id ? 'Edit account' : 'Create account'))])],1)],1),_c('div',{staticClass:"permission"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"labelAlign":"left"}},[_c('div',{staticClass:"form-item",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticClass:"form-title"},[_vm._v("Master Account Info")]),_c('a-form-model-item',{ref:"chain_name",attrs:{"label":"Account Name","prop":"chain_name"}},[_c('a-input',{attrs:{"autocomplete":"new-password","placeholder":"No more than 30 characters","maxLength":30},on:{"blur":() => {
                            _vm.$refs.chain_name.onFieldBlur();
                        }},model:{value:(_vm.form.chain_name),callback:function ($$v) {_vm.$set(_vm.form, "chain_name", $$v)},expression:"form.chain_name"}})],1),_c('a-form-model-item',{ref:"user_name",attrs:{"label":"Login ID","prop":"user_name"}},[_c('a-input',{attrs:{"autocomplete":"new-password","placeholder":"name@address.com","maxLength":100},on:{"blur":() => {
                            _vm.$refs.user_name.onFieldBlur();
                        }},model:{value:(_vm.form.user_name),callback:function ($$v) {_vm.$set(_vm.form, "user_name", $$v)},expression:"form.user_name"}})],1),_c('a-form-model-item',{ref:"pswd",attrs:{"label":"Login Password","prop":"pswd"}},[_c('a-input',{attrs:{"type":"password","autocomplete":"new-password","placeholder":"create password","maxLength":100},on:{"blur":() => {
                            _vm.$refs.pswd.onFieldBlur();
                        }},model:{value:(_vm.form.pswd),callback:function ($$v) {_vm.$set(_vm.form, "pswd", $$v)},expression:"form.pswd"}})],1)],1),_c('a-form-model-item',{attrs:{"wrapper-col":{ span: 14, offset: 6 }}},[_c('a-button',{staticClass:"create-btn",attrs:{"type":"primary"},on:{"click":_vm.doSave}},[_vm._v(" Save")]),_c('a-button',{staticClass:"cancel-btn",on:{"click":_vm.goBack}},[_vm._v(" Back ")])],1)],1),_c('div',{staticClass:"form-title"},[_vm._v("Linked Accounts")]),_c('a-table',{attrs:{"pagination":false,"rowKey":data => data.id,"columns":_vm.merchant_columns,"data-source":_vm.linked_accounts},scopedSlots:_vm._u([{key:"action",fn:function(text, data){return _c('div',{},[_c('div',[_c('a',{staticStyle:{"color":"#2c9100"},on:{"click":function($event){return _vm.doDelete(data)}}},[_vm._v("delete")])])])}}])}),_c('a-button',{staticClass:"create-btn",attrs:{"type":"primary"},on:{"click":_vm.doAddAccount}},[_vm._v(" Add account")])],1),_c('a-modal',{staticStyle:{"height":"200px","overflow":"auto"},attrs:{"title":"","visible":_vm.show_link_layer,"width":500},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-descriptions',[_c('div',{staticClass:"detail_title",attrs:{"slot":"title"},slot:"title"},[_vm._v("Add Link Account")]),_c('a-descriptions-item',{attrs:{"label":"Merchant Email","span":3}},[_c('a-input',{attrs:{"autocomplete":"new-password","placeholder":"name@address.com"},model:{value:(_vm.add_user_name),callback:function ($$v) {_vm.add_user_name=$$v},expression:"add_user_name"}})],1),_c('a-descriptions-item',{attrs:{"label":"Login Password","span":3}},[_c('a-input',{attrs:{"type":"password","autocomplete":"new-password","placeholder":"enter password"},model:{value:(_vm.add_pswd),callback:function ($$v) {_vm.add_pswd=$$v},expression:"add_pswd"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }