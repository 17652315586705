// initial state
const state = {
    
};

// getters
const getters = {};

// actions
const actions = {
    do_login_aj({ commit, rootState }, post_data) {
        // let aj_host = rootState.debug ? 'client.minitable.link' : '';
        let aj_host = rootState.debug ? 'http://localhost:5050' : '';
        fetch(
            aj_host +
                '/weapp/minipass/user/login?user_id=' +
                post_data.user_id +
                '&pswd=' +
                post_data.pswd ,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    // let order_list = res.data.data;
                    // console.log('order_list', order_list);
                    commit('app/APP_SET_GLOBAL_DAT', res.data.data, { root: true });

                    window.localStorage.setItem('sid', res.data.data.sid);
                    window.localStorage.setItem('uid', res.data.data.uid);
                    window.localStorage.setItem('role', res.data.data.role);
                    window.localStorage.setItem('sname', res.data.data.sname);
                    window.localStorage.setItem('slogo', res.data.data.slogo);
                    window.localStorage.setItem('token', res.data.data.token);
                    window.localStorage.setItem('uname', post_data.user_id);

                    if (res.data.data.role == 'assist') {
                        post_data.router.replace({ path: '/master/list' });
                    } else {
                        post_data.router.replace({ path: '/feedback' });
                    }
                } else {
                    commit('app/APP_SET_MSG', { title: res.data.msg }, { root: true });
                    // console.log('data', res.data);
                }
            }).catch(() => null);
    }
};

// mutations
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
