import fetchApi from '../../kits/newFetch';
import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {
    order_list: [],
    export_list: [],
    total_page: 0,
    total_count: 0
};

// getters
const getters = {
    get_order_list(state) {
        return state.order_list;
    },
    get_export_list(state) {
        return state.export_list;
    },
    get_total_page(state) {
        return state.total_page;
    },
    get_total_count(state) {
        return state.total_count;
    }
};

// actions
const actions = {
    getOrderList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_order_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_ORDER_LIST", res.data.data);
                    post_data.cb && post_data.cb();
                }
            })
    },
    getOrderById({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/order/get_order_by_id/get?id=${post_data.id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    toRefund({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'to_refund',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('refund is success!');
                    actions.getOrderList({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('refund is failed!');
                }
            }).catch((e) => {
                message.success('refund is failed!');
                console.log(e);
            });
    }
};

// mutations
const mutations = {
    INIT_ORDER_LIST(state, res_data) {
        state.order_list = res_data.list;
        state.export_list = res_data.export_list;
        state.total_page = res_data.total_page;
        state.total_count = res_data.total_count;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
