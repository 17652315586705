import fetchApi from '../../kits/newFetch';
import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {
    draw_list: [],
    total_page: 0,
    total_count: 0,
    prize_list: [],
    draw_enrollment_count: 0,
    draw_notify_count: 0,
    action_log: []
};

// getters
const getters = {
    get_draw_list(state) {
        return state.draw_list;
    },
    get_total_page(state) {
        return state.total_page;
    },
    get_total_count(state) {
        return state.total_count;
    },
    get_prize_list(state) {
        return state.prize_list;
    },
    get_enrollment_count(state) {
        return state.draw_enrollment_count;
    },
    get_notify_count(state) {
        return state.draw_notify_count;
    },
    get_action_log(state) {
        return state.action_log;
    }
};

// actions
const actions = {
    getDrawList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_draw_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_DRAW_LIST", res.data.data);
                    post_data.cb && post_data.cb();
                }
            })
    },
    getDrawById({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/draw/get_draw_by_id/get?id=${post_data.id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    addDraw({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'add_draw',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('add is success!');
                    //actions.getDrawList({ commit, rootState, rootGetters }, post_data.afterdata)
                    post_data.cb && post_data.cb();
                } else {
                    message.success('add is failed!');
                }
            }).catch((e) => {
                message.success('add is failed!');
                console.log(e);
            });
    },
    updateDraw({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_draw',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    //actions.getDrawList({ commit, rootState, rootGetters }, post_data.afterdata)
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    updateEventStatus({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_event_status',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.getDrawList({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    delEvent({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'del_event',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('delete is success!');
                    actions.getDrawList({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('delete is failed!');
                }
            }).catch((e) => {
                message.success('delete is failed!');
                console.log(e);
            });
    },
    getPrizeList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_prize_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_PRIZE_LIST", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
    updatePrizeStatus({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_prize_status',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.getDrawList({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    getDrawEnrollment({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_draw_enrollment_count',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("DRAW_ENROLLMENT_COUNT", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
    uploadImg({ rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : ''
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + '/weapp/minipass/upload/pic', params, {headers: {'Content-Type': 'multipart/form-data'}})
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getStoreInfo({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/minipass/draw/store_info/get?user_name=' +
                post_data.user_name)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    actionLog({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'draw_action_log',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("ACTION_LOG", res.data.data);
                }
            })
    },
    sendNotification({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/minipass/draw/notification/send?sid=' + post_data.sid + '&draw_id=' + post_data.draw_id + '&event_name=' + post_data.event_name + '&sname=' + post_data.sname + '&is_test=' + post_data.is_test)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    sendPushMessage({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/minipass/draw/message/send?sid=' + post_data.sid + '&draw_id=' + post_data.draw_id + '&event_name=' + post_data.event_name + '&sname=' + post_data.sname + '&is_test=' + post_data.is_test + '&active_end_time=' + post_data.active_end_time)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
    INIT_DRAW_LIST(state, res_data) {
        state.draw_list = res_data.list;
        state.total_page = res_data.total_page;
        state.total_count = res_data.total_count;
    },
    INIT_PRIZE_LIST(state, res_data) {
        state.prize_list = res_data.list;
    },
    DRAW_ENROLLMENT_COUNT(state, res_data){
        state.draw_enrollment_count = res_data.total_count;
        state.draw_notify_count = res_data.notify_total_count;
    },
    ACTION_LOG(state, res_data) {
        state.action_log = res_data.list;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
