<template>
    <div class="main_bd">
        <div class="head_title">Boost</div>
        <a-row style="margin: 10px 0;">
            <a-col :span="4">
            </a-col>
            <a-col :span="4">
                <a-statistic title="Today Boost Count" :value="summary_data.boost_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Success Count" :value="summary_data.boost_success_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Boost Fail Count" :value="summary_data.boost_fail_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Amount" :value="summary_data.boost_sales_amount || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
            <a-col :span="4">
                <a-statistic title="Sales Count" :value="summary_data.boost_sales_count || 0" style="margin-right: 5px">
                </a-statistic>
            </a-col>
        </a-row>
        <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="merchant">Merchant management </a-menu-item>
            <a-menu-item key="boost"> Boost Today </a-menu-item>
            <a-menu-item key="boost_history"> Boost History </a-menu-item>
            <a-menu-item key="transaction"> IAP Transaction </a-menu-item>
            <a-menu-item key="robot"> Robot Management </a-menu-item>
        </a-menu>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="boost_list" >
                <template slot="data_zone" slot-scope="text">
                    <p v-if="boost_waiting_data_dict && boost_waiting_data_dict[text]">{{ boost_waiting_data_dict[text].ahead }} ahead</p>
                    <p v-if="boost_waiting_data_dict && boost_waiting_data_dict[text]">{{ boost_waiting_data_dict[text].behind }} behind</p>
                </template>
                <template slot="boost_zone" slot-scope="text, data">
                    <p>{{ 'Wait Code: ' +  data.wait_code }}</p>
                    <p>{{ 'User Name: ' +  data.boost_name }}</p>
                    <p>{{ 'Phone: ' +  data.boost_phone_num }}</p>
                </template>
                <template slot="status_zone" slot-scope="text">
                    <a-tag v-if="text == 0" color="pink"> Available </a-tag>
                    <a-tag v-if="text == 1" color="green"> On Sale </a-tag>
                    <a-tag v-if="text == 10" color="blue"> Sold </a-tag>
                    <a-tag v-if="text == 11" color="red"> Unavailable </a-tag>
                    <a-tag v-if="text == 16" color="orange"> Expired </a-tag>
                </template>
                <template slot="on_sale_zone" slot-scope="text, data">
                    <div v-for="item in data.sales_data" v-bind:key="item.id">
                        <span>{{ item.target_wait_code + ' / ' + item.target_phone_num + ' / ' + item.status_text }}</span>
                    </div>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-button v-if="false" type="link" @click="edit_boost(data)">Edit</a-button>
                    <a-button v-if="data.status == 0" type="link" @click="cancel_boost(data)">Delete</a-button>
                    <a-button type="info" @click="show_boost_detail(data)">Show Sales Detail</a-button>
                    <a-button v-if="data.status == 0 || data.status == 1" style="margin-left: 5px;" type="primary" @click="show_sale_layer(data)">Sale</a-button>
                </div>
            </a-table>
        </div>
        <a-modal
            title="Boost Sales Detail"
            :visible="show_sales_log_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="1200"
            style="height: 400px; overflow: auto;"
            >
            <a-alert style="margin-bottom: 10px;" :message="layer_data.wait_code + ' / ' + layer_data.boost_name" type="info" :close-text="layer_data.sname" />
            <a-table :loading="is_loading_detail" :pagination="false" :rowKey="data => data.id" :columns="columns_log" :data-source="boost_sales_log_list" >
                <div slot="action" slot-scope="text, data">
                    <a-button v-if="data.status < 10" type="link" @click="cancel_sale(data)">Cancel</a-button>
                    <a-button v-if="data.status != 10 && data.paid == 1" type="link" @click="refund(data)">Refund</a-button>
                </div>
            </a-table>
        </a-modal>
        <a-modal
            title="Choose Sale"
            :visible="show_sale_action_layer"
            @ok="handleSale"
            @cancel="handleCancelSale"
            :width="900"
            style="height: 600px; overflow: auto;"
            :confirm-loading="generating_sale"
            >
            <a-alert style="margin-bottom: 10px;" :message="layer_data.wait_code + ' / ' + layer_data.boost_name" type="info" :close-text="layer_data.sname" />
            <a-progress :strokeWidth="20" style="margin-bottom: 10px;" v-if="generating_sale" :percent="generating_percent" size="small" />
            <div style="margin-bottom: 10px;">
                <span style="margin-right: 5px;">Filter Sold</span><a-switch v-model="filter_sold"></a-switch>
                <span style="margin-left: 20px; margin-right: 5px;">Filter non-minipass-members</span><a-switch v-model="filter_minipass_memeber"></a-switch>
                <span style="margin-left: 20px; margin-right: 5px;">Send text</span><a-switch v-model="send_text"></a-switch>
                <span style="margin-left: 20px; margin-right: 5px;">Sale expiration time</span>
                <a-select v-model="expire_minutes" style="width: 120px">
                    <a-select-option :value="1">
                        1 minute
                    </a-select-option>
                    <a-select-option :value="2">
                        2 minutes
                    </a-select-option>
                    <a-select-option :value="5">
                        5 minutes
                    </a-select-option>
                </a-select>
            </div>
            <a-transfer
                :titles="['Waitlist', 'To Sale']"
                :data-source="new_waitlist_list"
                show-search
                :filter-option="filterOption"
                :target-keys="targetKeys"
                :render="item => item.title"
                @change="handleChange"
                @search="handleSearch"
                :list-style="{
                    width: '400px',
                    height: '300px',
                }"
            />
            
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    Vue.use(VueSweetalert2);

    const columns = [
        {
            title: "Boost Info",
            dataIndex: "wait_code",
            scopedSlots: { customRender: "boost_zone" }
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Price",
            dataIndex: "amount_text"
        },
        {
            title: "Status",
            dataIndex: "status",
            scopedSlots: { customRender: "status_zone" }
        },
        {
            title: "Waiting Data",
            dataIndex: "pre_tid",
            scopedSlots: { customRender: "data_zone" }
        },
        {
            title: "On Sale Data",
            dataIndex: "id",
            scopedSlots: { customRender: "on_sale_zone" }
        },
        {
            title: 'Action',
            dataIndex: '',
            scopedSlots: { customRender: 'action' }
        }
    ];

    const columns_log = [
        {
            title: "Sales Wait Code",
            dataIndex: "target_wait_code"
        },
        {
            title: "Sales Phone",
            dataIndex: "target_phone_num"
        },
        {
            title: "Already Sold Time",
            dataIndex: "sold_waiting_time"
        },
        {
            title: "Expire Time",
            dataIndex: "expire_time"
        },
        {
            title: "Waiting Time",
            dataIndex: "waiting_time"
        },
        {
            title: "Status",
            dataIndex: "status_text",
        },
        {
            title: "Payment Status",
            dataIndex: "paid_status_text",
        },
        {
            title: "Payment Info",
            dataIndex: "pay_id",
            scopedSlots: { customRender: "pay_zone" }
        },
        {
            title: "Action",
            dataIndex: '',
            scopedSlots: { customRender: "action" }
        }
    ];

    export default {
        name: 'boost_sales_log',
        components: {
        },
        data() {
            return {
                current: ['boost'],
                boost_list: [],
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                columns,
                layer_data: {},
                show_sales_log_layer: false,
                show_sale_action_layer: false,
                waiting_list: [],
                targetKeys: [],
                generating_sale: false,
                generating_percent: 0,
                expire_minutes: 5,

                boost_sales_log_list: [],
                columns_log,
                minipass_member_dict: null,
                boost_sales_dict: null,
                filter_sold: false,
                filter_minipass_memeber: true,
                send_text: true,
                boost_waiting_data_dict: {},
                is_loading_detail: false,

                timer: null,
                list_timer: null,
                boost_detail_timer: null,

                summary_data: {},
                user_waiting_data_dict: {},
            };
        },
        beforeMount() {},
        async created() {
            await this.get_boost_list();
            this.get_summary_data();
            this.get_boost_waiting_data();
            this.timer = setInterval(() => {
                this.get_boost_waiting_data();
            }, 10 * 1000);

            this.list_timer = setInterval(() => {
                this.get_boost_list();
                this.get_summary_data();
            }, 30 * 1000);
        },
        watch: {
            current() {
                if (this.current == 'merchant') {
                    this.$router.push({
                        path: '/market/boost/merchants'
                    });
                } else if (this.current == 'robot') {
                    this.$router.push({
                        path: '/market/boost/robots'
                    });
                } else if (this.current == 'boost_history') {
                    this.$router.push({
                        path: '/market/boost/history'
                    });
                } else if (this.current == 'transaction') {
                    this.$router.push({
                        path: '/market/boost/transaction'
                    });
                }
            },
        },
        mounted() {
        },
        computed: {
            new_waitlist_list() {
                let new_list = this.waiting_list.map(item => {
                    let title = item.wait_code + ' / ' + item.customer_name + ' / ' + item.phone_num;

                    if (this.user_waiting_data_dict[item.phone_num]) {
                        title += ' / ' + this.user_waiting_data_dict[item.phone_num].total_wait_count + ' / ' + this.user_waiting_data_dict[item.phone_num].store_wait_count;
                    }

                    return {
                        key: item.pre_tid,
                        title: this.minipass_member_dict ? (this.minipass_member_dict[item.phone_num] ? title + ' (Member)' : title + ' (Not Minipass Member)') : title,
                        description: item.phone_num + ' ' + item.customer_name,
                        phone_num: item.phone_num
                    };
                });
                
                if (this.filter_sold) {
                    new_list = new_list.filter(item => {
                        return !this.boost_sales_dict || !this.boost_sales_dict[item.key];
                    });
                }

                if (this.filter_minipass_memeber) {
                    new_list = new_list.filter(item => {
                        return !this.minipass_member_dict || this.minipass_member_dict[item.phone_num];
                    });
                }

                return new_list;
            },
            waitlist_data_dict() {
                let dict = {};
                this.waiting_list.forEach(item => {
                    dict[item.pre_tid] = item;
                });

                return dict;
            },
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getBoostList: 'boost/getBoostList',
                cancelBoost: 'boost/cancelBoost',
                getWaitingListBySid: 'boost/getWaitingListBySid',
                createBoostSalesLog: 'boost/createBoostSalesLog',
                checkMinipassMember: 'boost/checkMinipassMember',
                getBoostSalesDetail: 'boost/getBoostSalesDetail',
                cancelBoostSales: 'boost/cancelBoostSales',
                checkIfSaledByBoostId: 'boost/checkIfSaledByBoostId',
                getBoostWaitingData: 'boost/getBoostWaitingData',
                getBoostSummaryData: 'boost/getBoostSummaryData',
                getUserHistoryWaitlistData: 'boost/getUserHistoryWaitlistData'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_boost_list() {
                let { list } = await this.getBoostList({});

                this.boost_list = list;
            },
            async get_boost_waiting_data() {
                if (this.boost_list.length == 0) {
                    return false;
                }

                let temp_list = this.boost_list.filter(item => item.status == 0 || item.status == 1);

                let pre_tids = temp_list.map(item => item.pre_tid).join(',');
                let party_types = temp_list.map(item => item.party_type).join(',');
                let ranks = temp_list.map(item => item.rank).join(',');
                let sids = temp_list.map(item => item.sid).join(',');
                
                if (!pre_tids) {
                    return false;
                }
                
                let res = await this.getBoostWaitingData({
                    pre_tids,
                    party_types,
                    ranks, 
                    sids
                });

                if (res && res.waiting_data_dict) {
                    this.boost_waiting_data_dict = res.waiting_data_dict;
                }
            },
            cancel_boost(data) {
                this.$confirm({
                    title: 'Are you sure you want to delete this boost?',
                    content: 'Also, the waitlist will be deleted',
                    okText: 'Confirm',
                    // okType: 'danger',
                    cancelText: 'Cancel',
                    onOk: async () => {
                        let res = await this.cancelBoost({
                            boost_id: data.boost_id,
                            sid: data.sid
                        });

                        if (res && res.code == 100000) {
                            this.$message.success('Delete boost success');
                            this.get_boost_list();
                        } else {
                            this.$message.error('Delete boost failed');
                        }
                    }
                });
            },
            show_sale_layer (data) {
                this.layer_data = data;
                this.show_sale_action_layer = true;
                this.get_waiting_list_by_sid(data);
            },
            async get_waiting_list_by_sid(data) {
                let res = await this.getWaitingListBySid({
                    sid: data.sid,
                    party_type: data.party_type,
                    rank: data.rank
                });

                if (res && res.list) {
                    this.waiting_list = res.list;
                    this.check_minipass_member();
                    this.check_if_saled();
                    this.get_user_history_data();
                }
            },
            async check_minipass_member()  {
                if (this.waiting_list.length > 0) {
                    let res = await this.checkMinipassMember({
                        phone_nums: this.waiting_list.map(item => item.phone_num).join(',')
                    });

                    if (res && res.code === 100000) {
                        this.minipass_member_dict = res.data.check_dict;
                    }
                }
            },
            async check_if_saled() {
                if (this.waiting_list.length == 0) {
                    return false;
                }

                let res = await this.checkIfSaledByBoostId({
                    boost_id: this.layer_data.boost_id,
                    target_pre_tids: this.waiting_list.map(item => item.pre_tid).join(',')
                });

                if (res && res.code === 100000) {
                    this.boost_sales_dict = res.data.check_dict;
                }
            },
            async get_user_history_data() {
                if (this.waiting_list.length == 0) {
                    return false;
                }

                let res = await this.getUserHistoryWaitlistData({
                    sid: this.layer_data.sid,
                    phone_nums: this.waiting_list.map(item => item.phone_num).join(',')
                });

                console.log(res);

                if (res && res.code === 100000) {
                    this.user_waiting_data_dict = res.data.wait_data;
                    console.log(this.user_waiting_data_dict);
                }
            },
            async handleSale() {
                this.generating_sale = true;
                let len = this.targetKeys.length;

                for (let i = 0; i < len; i++) {
                    let item = this.waitlist_data_dict[this.targetKeys[i]];

                    let post_data = {
                        operator: this.uname,
                        sid: this.layer_data.sid,
                        sname: this.layer_data.sname,
                        boost_id: this.layer_data.boost_id,
                        pre_tid: this.layer_data.pre_tid,
                        rank: this.layer_data.rank,
                        wait_code: this.layer_data.wait_code,
                        amount: this.layer_data.amount,
                        target_pre_tid: item.pre_tid,
                        target_phone_num: item.phone_num,
                        target_cid: this.minipass_member_dict[item.phone_num] || '',
                        target_wait_code: item.wait_code,
                        target_rank: item.rank,
                        target_create_timestamp: item.create_time,
                        expire_minutes: this.expire_minutes,
                        send_text: this.send_text ? 1 : 0
                    };

                    let res = await this.createBoostSalesLog(post_data);

                    if (res.code != 100000) {
                        this.$message.error(post_data.phone_num  + '/' + res.msg || 'Create boost sale failed');
                    } else {
                        this.generating_percent = (i + 1) / len * 100;
                    }
                }

                this.get_boost_list();

                setTimeout(() => {
                    this.generating_sale = false;
                    this.show_sale_action_layer = false;
                    this.waiting_list = [];
                    this.layer_data = {};
                    this.targetKeys = [];
                    this.generating_percent = 0;
                    this.minipass_member_dict = null;
                    this.send_text = true;
                }, 1500);

            },
            handleCancelSale() {
                this.send_text = true;
                this.show_sale_action_layer = false;
            },
            show_boost_detail(data) {
                this.layer_data = data;
                this.show_sales_log_layer = true;
                this.get_boost_sales_detail(data);

                this.boost_detail_timer = setInterval(() => {
                    this.get_boost_sales_detail(data);
                }, 10 * 1000);
            },
            async get_boost_sales_detail(data) {
                this.is_loading_detail = true;
                let res = await this.getBoostSalesDetail({
                    boost_id: data.boost_id
                });

                if (res && res.list) {
                    this.boost_sales_log_list = res.list;
                }

                this.is_loading_detail = false;
            },
            filterOption(inputValue, option) {
                return option.description.indexOf(inputValue) > -1;
            },
            handleChange(targetKeys, direction, moveKeys) {
                // console.log(targetKeys, direction, moveKeys);
                this.targetKeys = targetKeys;
            },
            handleSearch(dir, value) {
                console.log('search:', dir, value);
            },
            handleOk() {
                this.show_sales_log_layer = false;
                this.layer_data = {};
                clearInterval(this.boost_detail_timer);
                this.get_boost_list();
            },
            cancel_sale(data) {
                this.$confirm({
                    title: 'Are you sure you want to cancel?',
                    content: '',
                    okText: 'Confirm',
                    // okType: 'danger',
                    cancelText: 'Cancel',
                    onOk: async () => {
                        let res = await this.cancelBoostSales({
                            boost_id: data.boost_id,
                            id: data.id
                        });

                        if (res && res.code == 100000) {
                            this.$message.success('Cancel boost sales success');
                            this.get_boost_sales_detail(data);
                        } else {
                            this.$message.error('Cancel boost sales failed');
                        }
                    }
                });
            },
            async get_summary_data() {
                let res = await this.getBoostSummaryData({ type: 'today', start_date: '', end_date: '' });

                if (res && res.code == 100000) {
                    this.summary_data = res.data;
                }
            },

            refund() {

            },
            edit_boost() {

            },
        }
    };
</script>

<style>
</style>
