<template>
    <div class="main_bd">
        <div class="head_title">Content Center</div>
        <div class="frm_controls search" style="margin: 30px 0">
            <span class="frm_input_box vcode with_counter counter_in append"
                style="display: inline-block; width: 300px; padding-right: 10px">
                <input class="frm_input" style="width: 100%" v-model="merchant_id"
                    placeholder="search by merchant id" @keyup.enter="search_merchant_id" />
            </span>
            <a-button type="primary" @click="search_merchant_id"> Search </a-button>
        </div>
        <div class="action_content">
            <div>
                <button class="action_btn active_btn" @click="to_activate_all">Activate</button>
                <button class="action_btn deactivate_btn" @click="to_deactivate_all">Deactivate</button>
                <button class="action_btn delete_btn" @click="to_delete_all">Delete</button>
            </div>
            <div>
                <button class="action_btn" @click="upload_data">Upload</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="content_list" :row-selection="{onChange: onSelectChange}" >
                <a slot="index" slot-scope="text, data">
                    <p>{{ data.index }}</p>
                </a>
                <template slot="status" slot-scope="text, data">
                    <a-tag style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.status == 1" color="#2C9100">
                        Active
                    </a-tag>
                    <a-tag style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.status == -1" color="#FD7858">
                        Deleted
                    </a-tag>
                    <a-tag style="width: 90px; border-radius: 12px; text-align: center;" v-else color="#7D7D7D">
                        Deactivated
                    </a-tag>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a style="color: #7427FF" href="javascript:;" @click="open_content_layer(data)">details</a>
                    <template v-if="data.status == 0">
                        <div style="height: 10px"></div>
                        <a style="color: #7427FF" @click="to_activate(data)">activate</a>
                    </template>
                    <template v-else-if="data.status == 1">
                        <div style="height: 10px"></div>
                        <a style="color: #FD7858" @click="to_deactivate(data)">deactivate</a>
                    </template>
                    <template v-if="data.status == 0">
                        <div style="height: 10px"></div>
                        <a style="color: #FD7858" @click="to_delete(data)">delete</a>
                    </template>
                </div>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="20" v-model="page" :total="total_count"
                show-less-items />
        </div>
        <a-modal
            title="Deactivate the content?"
            :visible="show_deactivate_layer"
            @ok="handle_ok_deactivate"
            @cancel="handle_cancel_deactivate"
            okText="Yes, deactivate"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">{{item.pid}} has already been activated on Minipass App. If you deactivate it, all users won't see it any more.</div>
                    <div class="been_activated activated_orange">Act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="Activate the content?"
            :visible="show_activate_layer"
            @ok="handle_ok_activate"
            @cancel="handle_cancel_activate"
            okText="Yes, activate"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">{{item.pid}} has already been deactivated. If you activate it, this contnet will be active on Minipass App again.</div>
                    <div class="been_activated activated_orange">Act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="Delete the content?"
            :visible="show_delete_layer"
            @ok="handle_ok_delete"
            @cancel="handle_cancel_delete"
            okText="Yes, delete"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">{{item.pid}} has already been deactivated. If you delete it, this contnet will be deleted from database.</div>
                    <div class="been_activated activated_orange">Can't be undone, act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="Deactivate the content?"
            :visible="show_deactivate_all_layer"
            @ok="deactivate_all"
            @cancel="handle_cancel_deactivate_all"
            okText="Yes, deactivate"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">If you activate selected content, the content will be active on Minipass App again.</div>
                    <div class="been_activated activated_orange">Act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="Activate the content?"
            :visible="show_activate_all_layer"
            @ok="activate_all"
            @cancel="handle_cancel_activate_all"
            okText="Yes, activate"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">If you deactivate selected content, all users won’t see them anymore.</div>
                    <div class="been_activated activated_orange">Act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="Delete the content?"
            :visible="show_delete_all_layer"
            @ok="delete_all"
            @cancel="handle_cancel_delete_all"
            okText="Yes, delete"
            cancelText="No, cancel"
            centered
        >
            <a-form>
                <a-form-item>
                    <div class="been_activated">If you delete selected content, the content will be deleted from the database.</div>
                    <div class="been_activated activated_orange">Can't be undone, act with caution</div>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            :visible="show_content_layer"
            @cancel="cancel_content_layer"
            :footer="null"
            centered
            width="900px"
        >
            <a-form>
                <a-form-item>
                    <div class="content_layer">
                        <div class="content_layer_top">
                            <h2>{{item.pid}}</h2>
                            <div style="display: flex;">
                                <dl class="content_item">
                                    <dt>User</dt>
                                    <dd>{{item.phone_num}}</dd>
                                </dl>
                                <dl class="content_item">
                                    <dt>type</dt>
                                    <dd>{{item.type}}</dd>
                                </dl>
                            </div>
                            <div style="display: flex;">
                                <dl class="content_item">
                                    <dt>Merchant id</dt>
                                    <dd>{{item.user_name}}</dd>
                                </dl>
                                <dl class="content_item">
                                    <dt>Create time</dt>
                                    <dd>{{item.create_time}}</dd>
                                </dl>
                            </div>
                            <div class="content_layer_btn">
                                <button v-if="item.status == 0" class="action_btn deactivate_btn">Deactivated</button>
                                <button v-else-if="item.status == 1" class="action_btn active_btn">Active</button>
                                <button v-else-if="item.status == -1" class="action_btn delete_btn">Deleted</button>
                            </div>
                        </div>
                        <div class="content_layer_bottom">
                            <h2>Content</h2>
                            <dl class="content_item">
                                <dt>URL</dt>
                                <dd>
                                    <template v-if="item.type == 'video'">
                                        <a href="javascript:;">{{ item.video_url }}</a>
                                    </template>
                                    <div class="image_box" v-else-if="item.type == 'image'">
                                        <a
                                            href="javascript:;"
                                            v-for="(temp, index) in item.imagesList"
                                            :key="index"
                                        >
                                            {{ temp }}
                                        </a>
                                    </div>
                                </dd>
                            </dl>
                            <dl class="content_item">
                                <dt>Caption</dt>
                                <dd>{{item.content}}</dd>
                            </dl>
                        </div>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<style>
.action_content {
    display: flex;
    justify-content: space-between;
}
.action_btn {
    width: 102px;
    height: 28px;
    border: none;
    border-radius: 8px;
    background-color: #7427FF;
    color: rgba(255, 255, 255, 1);
    font-family: 'Helvetica';
    font-size: 16px;
    margin-left: 10px;
}
.action_btn.delete_btn {
    background-color: #FD7858;
}
.action_btn.deactivate_btn {
    background-color: #7D7D7D;
}
.action_btn.active_btn {
    background-color: #2C9100;
}
.been_activated {
    text-align: center;
    line-height: 26px;
    font-size: 14px;
}
.been_activated.activated_orange {
    color: #FD7858;
}
.content_layer h2 {
    margin-top: 20px;
    font-size: 16px;
    color: #7427FF;
}
.content_layer .content_item {
    display: flex;
    color: #333;
    margin: 0;
    line-height: 28px;
}
.content_layer .content_item dt {
    font-weight: 500;
    margin-right: 10px;
}
.content_layer .content_item dd {
    font-size: 12px;
    margin-right: 20px;
}
.content_layer .content_item a {
    color: #7427FF;
    cursor: text;
}
.content_layer_bottom {
    border-top: 1px solid #d8d8d8;
    margin-top: 10px;
}
.content_layer_top {
    position: relative;
}
.content_layer_btn {
    position: absolute;
    top: -5px;
    right: 20px;
    line-height: 24px;
}
.content_layer_btn .action_btn {
    border-radius: 12px;
    height: 24px;
    font-size: 13px;
    width: 90px;
    cursor: auto;
}
.image_box a {
    display: block;
}
</style>

<script>
import Vue from 'vue';

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import moment from "moment";

const columns = [
    {
        title: "No.",
        dataIndex: "index",
        scopedSlots: { customRender: "index" },
        width: 100
    },
    {
        title: "Create Time",
        dataIndex: "create_time",
        width: 300
    },
    {
        title: "Content ID",
        dataIndex: "pid"
    },
    {
        title: "Type",
        dataIndex: "type"
    },
    {
        title: "User",
        dataIndex: "phone_num"
    },
    {
        title: 'Status',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: 'More',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log('change', `selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    selectedArr = selectedRows;
  },
  onSelect: (record, selected, selectedRows) => {
    console.log('select:', record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log('selectAll:',selected, selectedRows, changeRows);
  },
};

export default {
    name: 'contentCenter',
    components: {
    },
    data() {
        return {
            sid: window.localStorage.getItem('sid'),
            columns,
            rowSelection,
            selectedArr: [],
            page: 1,
            start_date: '',
            end_date: '',
            item: {},
            show_deactivate_layer: false,
            show_delete_layer: false,
            show_activate_layer: false,
            show_deactivate_all_layer: false,
            show_delete_all_layer: false,
            show_activate_all_layer: false,
            show_content_layer: false,
            merchant_id: '',
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    async created() {
        this.getContentList({
            page: 1,
            page_size: 20,
        });
    },
    watch: {
    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            content_list: "content/get_content_list",
            total_page: "content/get_total_page",
            total_count: "content/get_total_count",
        }),
        ...mapState({
        })
    },
    methods: {
        ...mapActions({
            getContentList: 'content/getContentList',
            updateContentStatus: 'content/updateContentStatus',
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        }),
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedArr = selectedRows;
        },
        open_content_layer(data) {
            this.item = { ...data };
            this.show_content_layer = true;
        },
        cancel_content_layer() {
            this.show_content_layer = false;
        },
        activate_all() {
            this.show_activate_all_layer = false;
            if (this.selectedArr.length == 0) return;

            let indexArr = [];
            let idArr = [];
            let flag = false;

            for(let i = 0; i < this.selectedArr.length; i++) {
                let temp = this.selectedArr[i];
                if (temp.status == -1) {
                    flag = true;
                } else if (temp.status == 0) {
                    indexArr.push(temp.index);
                    idArr.push(temp.id);
                }
            }

            if (flag) return;
            if(idArr.length == 0) return;

            this.updateContentStatus({
                indexs: indexArr.join(','),
                status: 1,
                ids: idArr.join(','),
            });
        },
        deactivate_all() {
            this.show_deactivate_all_layer = false;
            if (this.selectedArr.length == 0) return;

            let indexArr = [];
            let idArr = [];
            let flag = false;

            for(let i = 0; i < this.selectedArr.length; i++) {
                let temp = this.selectedArr[i];
                if (temp.status == -1) {
                    flag = true;
                } else if (temp.status == 1) {
                    indexArr.push(temp.index);
                    idArr.push(temp.id);
                }
            }

            if (flag) return;
            if(idArr.length == 0) return;

            this.updateContentStatus({
                indexs: indexArr.join(','),
                status: 0,
                ids: idArr.join(','),
            });
        },
        delete_all() {
            this.show_delete_all_layer = false;
            if (this.selectedArr.length == 0) return;

            let indexArr = [];
            let idArr = [];
            let flag = false;

            for(let i = 0; i < this.selectedArr.length; i++) {
                let temp = this.selectedArr[i];
                if (temp.status == 1) {
                    flag = true;
                } else if (temp.status == 0) {
                    indexArr.push(temp.index);
                    idArr.push(temp.id);
                }
            }

            if (flag) {
                this.set_msg({ title: '批量操作失败' });
                return;
            }
            if(idArr.length == 0) return;

            this.updateContentStatus({
                indexs: indexArr.join(','),
                status: -1,
                ids: idArr.join(','),
            });
        },
        upload_data() {
            console.log('upload data');
        },
        to_deactivate(data) {
            this.item = { ...data };
            this.show_deactivate_layer = true;
        },
        handle_cancel_deactivate() {
            this.show_deactivate_layer = false;
        },
        handle_ok_deactivate() {
            this.show_content_layer = false;
            this.show_deactivate_layer = false;
            this.updateContentStatus({
                ids: this.item.id + '',
                status: 0,
                indexs: this.item.index + '',
            });
        },
        to_activate(data) {
            this.item = { ...data };
            this.show_activate_layer = true;
        },
        handle_cancel_activate() {
            this.show_activate_layer = false;
        },
        handle_ok_activate() {
            this.show_content_layer = false;
            this.show_activate_layer = false;
            this.updateContentStatus({
                ids: this.item.id + '',
                status: 1,
                indexs: this.item.index + '',
            });
        },
        to_delete(data) {
            this.item = { ...data };
            this.show_delete_layer = true;
        },
        handle_cancel_delete() {
            this.show_delete_layer = false;
        },
        handle_ok_delete() {
            this.show_delete_layer = false;
            this.updateContentStatus({
                ids: this.item.id + '',
                status: -1,
                indexs: this.item.index + '',
            });
        },
        to_deactivate_all() {
            this.show_deactivate_all_layer = true;
        },
        to_activate_all() {
            this.show_activate_all_layer = true;
        },
        to_delete_all() {
            this.show_delete_all_layer = true;
        },
        handle_cancel_deactivate_all() {
            this.show_deactivate_all_layer = false;
        },
        handle_cancel_activate_all() {
            this.show_activate_all_layer = false;
        },
        handle_cancel_delete_all() {
            this.show_delete_all_layer = false;
        },
        turnPage(e) {
            this.getContentList({
                page: this.page,
                page_size: 20,
                start_date: this.start_date ? moment(this.start_date).valueOf() : null,
                end_date: this.end_date ? moment(this.end_date).valueOf() : null
            });
        },
        search_merchant_id() {
            this.getContentList({
                page: 1,
                page_size: 20,
                merchant_id: this.merchant_id
            });
        }
    }
};
</script>
