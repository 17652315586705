import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {};

// getters
const getters = {
};

// actions
const actions = {
    getNotificationList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/notification/list?page=${post_data.page}&page_size=${post_data.page_size}&task_status=${post_data.task_status}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getNotificationDetail({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/notification/info?task_id=${post_data.task_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getNotificationLogList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/notification/log?task_id=${post_data.task_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    exportNotificationLog({ rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : ''
        return new Promise((resolve, reject) => {
            axiosApi({
                method: 'get',
                url: aj_host + `/weapp/minipass/market/notification/export`,
                params: params,
                responseType: 'blob'
            }).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        });
    },
    updateNotificationTask({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/market/notification/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    runNotification({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/market/notification/start`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    createNotificationTask({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/market/notification/create`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getSurveyList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/survey/list?page=${post_data.page}&page_size=${post_data.page_size}&task_status=${post_data.task_status}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getSurveyDetail({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/survey/info?task_id=${post_data.task_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateSurveyTask({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/market/survey/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    createSurveyTask({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/market/survey/create`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    exportSurveyLog({ rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : ''
        return new Promise((resolve, reject) => {
            axiosApi({
                method: 'get',
                url: aj_host + `/weapp/minipass/market/survey/export`,
                params: params,
                responseType: 'blob'
            }).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        });
    },
    getSurveySummaryData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/market/survey/summary?task_id=${post_data.task_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};