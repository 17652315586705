import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    content_list: [],
    total_page: 0,
    total_count: 0
};

// getters
const getters = {
    get_content_list(state) {
        return state.content_list;
    },
    get_total_page(state) {
        return state.total_page;
    },
    get_total_count(state) {
        return state.total_count;
    },
};

// actions
const actions = {
    getContentList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_content_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_CONTENT_LIST", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
    // updateContentStatus({ commit, rootState }, post_data) {
    //     let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
    //     fetch(aj_host + '/weapp/minipass/content/status/update?id=' +
    //         post_data.id +
    //         '&status=' +
    //         post_data.status +
    //         '&index=' +
    //         post_data.index,
    //         {
    //             method: 'GET'
    //         })
    //         .then((res) => {
    //             return res.text();
    //         })
    //         .then((res) => {
    //             let res_data = JSON.parse(res).data;

    //             if (res_data.code === 100000) {
    //                 commit('CONTENT_STATUS_EDIT', {
    //                     status_obj: post_data
    //                 });
    //                 commit('app/APP_SET_MSG', { title: 'Successfully!', type: 'success' }, { root: true });
    //             }
    //         });
    // },
    updateContentStatus({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetch(aj_host + '/weapp/minipass/content/status/update?ids=' +
            post_data.ids +
            '&status=' +
            post_data.status +
            '&indexs=' +
            post_data.indexs,
            {
                method: 'GET'
            })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('CONTENT_STATUS_EDIT', {
                        status_obj: post_data
                    });
                    commit('app/APP_SET_MSG', { title: 'Successfully!', type: 'success' }, { root: true });
                }
            });
    },
};

// mutations
const mutations = {
    INIT_CONTENT_LIST(state, res_data) {
        state.content_list = res_data.list;
        state.total_page = res_data.total_page;
        state.total_count = res_data.total_count;
    },
    // CONTENT_STATUS_EDIT: function (state, _data) {
    //     let content_info = _data.status_obj;
    //     state.content_list = [
    //         ...state.content_list.slice(0, content_info.index - 1),
    //         Object.assign({}, state.content_list[content_info.index - 1], {
    //             status: content_info.status,
    //         }),
    //         ...state.content_list.slice(content_info.index)
    //     ];
    // },
    CONTENT_STATUS_EDIT: function (state, _data) {
        let content_info = _data.status_obj;
        let indexArr = content_info.indexs.split(',');
        for(let i = 0; i < indexArr.length; i++) {
            let temp_index = indexArr[i];
            state.content_list[temp_index - 1].status = content_info.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
