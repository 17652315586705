<template>
    <div class="main_bd">
        <div style="margin: 40px 0">
            <a-breadcrumb>
                <span slot="separator" style="color: #000; font-size: 22px">></span>
                <router-link :to="'/market/survey/list'">
                    <a-breadcrumb-item class="b-item-1">Survey List</a-breadcrumb-item>
                </router-link>
                <a-breadcrumb-item class="b-item-2">{{ task_id ? 'Edit the survey' : 'Create a new survey' }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="permission">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left">
                <div class="form-item">
                    <a-form-model-item ref="task_name" label="Survey Name" prop="task_name">
                        <a-input v-model="form.task_name" placeholder="No more than 128 characters" @blur="
                            () => {
                                $refs.task_name.onFieldBlur();
                            }
                        " :maxLength="128" :disabled="task_status != 0" />
                    </a-form-model-item>
                    <a-form-model-item v-if="false" ref="channel" label="Channel" prop="channel">
                        <a-input type="text" aria-readonly="true" v-model="form.channel" :disabled="true" />
                    </a-form-model-item>
                    <a-form-model-item v-if="false" ref="app_position" label="App Position" prop="app_position">
                        <a-input type="text" aria-readonly="true" v-model="form.app_position" :disabled="true" />
                    </a-form-model-item>
                    <a-button type="primary" style="margin-bottom: 10px;" @click="add_question">
                        Add quesiton
                    </a-button>
                    <transition-group name="list" tag="ul">
                        <li @dragenter="dragenter($event, index)"
                            @dragover="dragover($event, index)"
                            @dragstart="dragstart(index)"
                            draggable
                            v-for="(item,index) in custom_question_list"
                            :key="item.id"
                            :class="{'list-item': true, 'show-error': item.show_error}"
                        >
                            <div class="row">
                                <div class="col-auto">
                                    <div>
                                        <span class="custom-setting-text">Q{{index+1}} Name:</span>
                                        <span class="custom-setting-input">
                                            <a-input v-model="item.name" type="text" :maxLength = "50"/>
                                        </span>
                                    </div>
                                    <div style="padding-bottom:5px">
                                        <span class="custom-setting-text">Question Content:</span>
                                        <span class="custom-setting-input">
                                            <a-textarea v-model="item.content" :maxLength = "200" />
                                        </span>
                                    </div>
                                    <div>
                                        <span class="custom-setting-text">Required:</span>
                                        <span class="custom-setting-input">
                                            <a-switch :checked="Boolean(item.is_required)" v-model="item.is_required"/>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="custom-setting-text">Question type:</span>
                                        <span class="custom-setting-input">
                                            <a-radio-group v-model="item.type" style="width: 600px;">
                                                <a-radio value="choice">Choice</a-radio>
                                                <a-radio value="multiple_choice">Multiple choice </a-radio>
                                                <a-radio value="rating">Rating</a-radio>
                                                <a-radio value="text">Text</a-radio>
                                                <a-radio value="textarea">Textarea</a-radio>
                                            </a-radio-group>
                                        </span>
                                    </div>
                                    <div v-if="item.type == 'rating'">
                                        <span class="custom-setting-text">Star count:</span>
                                        <span class="custom-setting-input">
                                            <a-input v-model="item.star_count" type="text" :maxLength = "1"/>
                                        </span>
                                    </div>                                    
                                    <div class="form-group" v-if="item.type == 'choice' || item.type == 'multiple_choice'">
                                        <div class="row">
                                            <div class="col-auto" style="padding-right: 0px;">
                                                <span class="custom-setting-text answear-text">
                                                    Answer:
                                                    <a-button type="primary" ghost size="small" shape="circle" icon="plus" @click="add_answear(index)" />
                                                </span>
                                            </div>
                                            <div class="col-auto" style="padding-left: 0px;">
                                                <ul v-if="item.answer && item.answer.length > 0" >
                                                    <li v-bind:key="subindex + '_sub'" v-for="(subitem, subindex) in item.answer">
                                                        <span class="custom-setting-input">
                                                            <a-input v-model="subitem.ans" :maxLength = "200" type="text" :placeholder="subindex+1" />
                                                        </span>
                                                        <span style="display: inline-block;">
                                                            <a-button type="primary" ghost size="small" shape="circle" icon="minus" @click="delete_answear(index, subindex)"/>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.type == 'choice'">
                                        <span class="custom-setting-text">With "Other" Option:</span>
                                        <span class="custom-setting-input">
                                            <a-switch :checked="Boolean(item.with_other_option)" v-model="item.with_other_option"/>
                                        </span>
                                    </div>
                                    <div v-if="item.type == 'text'">
                                        <span class="custom-setting-text">Regular expression:</span>
                                        <span class="custom-setting-input">
                                            <a-input v-model="item.regex" type="text" placeholder="Used for submission verification" :maxLength = "100"/>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto" :span="6" style="display: flex; justify-content: center; align-items: center;">
                                    <a-button type="primary" ghost icon="delete" @click="delete_custom(index)"></a-button>
                                </div>
                            </div>
                        </li>
                    </transition-group>
                </div>
            </a-form-model>
        </div>
        <div style="margin: 0 auto; text-align: center;">
            <a-space>
                <a-button type="link" class="" @click="cancel"> Back </a-button>
                <a-button type="primary" v-if="task_status == 0" @click="submit"> Save </a-button>
            </a-space>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import axios from 'axios';

    export default {
        name: 'SurveyCreate',
        data() {
            return {
                sid: window.localStorage.getItem('sid'),
                uname: window.localStorage.getItem('uname'),
                labelCol: { span: 3 },
                wrapperCol: { span: 10 },
                task_id: '',
                form: {
                    task_name: '',
                    channel: 'App',
                    app_position: 'fixed_layer',
                },
                rules: {
                    task_name: [
                        { required: true, message: 'Please Survey Name', trigger: 'blur' },
                        { min: 1, max: 128, message: 'No more than 128 characters', trigger: 'blur' }
                    ],
                },
                task_status: 0,
                custom_question_list: [],
                drag_index: '',
                count: 0
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            const { task_id } = this.$route.query;
            if(task_id){
                this.task_id = task_id;
                let res = await this.getSurveyDetail({
                    task_id: this.task_id
                });

                let { task_name, task_status, custom_question, channel, app_position } = res.info;

                this.form.task_name = task_name;
                this.form.channel = channel;
                this.form.app_position = app_position;
                this.task_status = task_status;

                for (let i = 0; i < custom_question.length; i++) {
                    let el = custom_question[i];

                    this.custom_question_list.push({
                        id: this.count++,
                        name: el.name,
                        content: el.content,
                        type: el.type,
                        answer: el.answer ? [...el.answer.map((item) => { return {ans: item}})] : [],
                        show_error: false,
                        star_count: el.star_count,
                        is_required: el.is_required,
                        with_other_option: el.with_other_option,
                        regex: el.regex
                    });
                }
            }
        },
        watch: {
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                createSurveyTask: 'market/createSurveyTask',
                updateSurveyTask: 'market/updateSurveyTask',
                getSurveyDetail: 'market/getSurveyDetail'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            cancel() {
                this.$router.go(-1);
            },
            async submit() {
                let {
                    task_name,
                } = this.form;

                if (!task_name) {
                    this.$message.error('Please enter the survey name');
                    return;
                }

                let post_data = {
                    task_id: this.task_id,
                    task_name,
                    operator: this.uname,
                    channel: this.form.channel,
                    app_position: this.form.app_position,
                    sharing_images: ''
                };
                let question_list = this.check_custom_question();

                if (question_list) {
                    post_data.custom_question = JSON.stringify(question_list);
                } else {
                    return;
                }

                let res = null;
                if (this.task_id) {
                    res = await this.updateSurveyTask(post_data);
                } else {
                    res = await this.createSurveyTask(post_data);
                }

                if (res && res.code == 100000) {
                    this.$message.success('Save successfully');
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error('Save failed');
                }
            },
            dragstart(index) {
                this.drag_index = index;
            },
            dragenter(e, index) {
                e.preventDefault();
                if (this.drag_index !== index) {
                    const source = this.custom_question_list[this.drag_index];
                    this.custom_question_list.splice(this.drag_index, 1);
                    this.custom_question_list.splice(index, 0, source);
                    this.drag_index = index;
                }
            },
            dragover(e, index) {
                e.preventDefault();
            },
            add_question () {
                this.custom_question_list.push({
                    id: this.count++,
                    name: '',
                    content: '',
                    type: "text",
                    answer: [],
                    show_error: false,
                    star_count: 5,
                    is_required: false,
                    regex: ''
                });
            },
            add_answear (index) {
                this.custom_question_list[index].answer.push({ans: ''});
            },
            delete_answear (index, subindex) {
                this.custom_question_list[index].answer.splice(subindex, 1);
            },
            delete_custom (index) {
                this.custom_question_list.splice(index, 1);
            },
            check_custom_question () {
                let isValid = true;
                this.custom_question_list.forEach((el) => {
                    let el_valid = true;

                    if(!el.name || !el.content || !el.type) {
                        el.show_error = true;
                        isValid = false;
                        el_valid = false;
                    } else if(el.type == "choice" || el.type == "multiple_choice") {
                        if (el.answer.length == 0) {
                            el.show_error = true;
                            isValid = false;
                            el_valid = false;
                        } else {
                            el.answer.forEach((item) => {
                                if(!item || !item.ans || item.ans === '') {
                                    el.show_error = true;
                                    isValid = false;
                                    el_valid = false;
                                }

                                if (item.ans == 'Other' || item.ans == 'other') {
                                    el.show_error = true;
                                    isValid = false;
                                    el_valid = false;
                                    this.$message.error('The answer can not be "Other"');
                                }
                            });
                        }
                    } else if (el.type == 'rating') {
                        if (!el.star_count || el.star_count > 5 || el.star_count == '') {
                            el.show_error = true;
                            isValid = false;
                            el_valid = false;

                            this.$message.error('Star count can not be larger than 5.');
                        }
                    }

                    if(el_valid) {
                        el.show_error = false;
                    }
                });
                
                if(!isValid) {
                    return false;
                }

                let get_ans_list = (ans_data) => {
                    let list = [];
                    if(ans_data && ans_data.length > 0) {
                        ans_data.forEach((el) => {
                            list.push(el.ans)
                        });
                    }
                    return list;
                };

                let custom_question = [];

                this.custom_question_list.forEach((el) => {
                    if(el.type == "choice") {
                        custom_question.push({
                            name: el.name || '',
                            content: el.content || '',
                            type: el.type,
                            answer: get_ans_list(el.answer),
                            is_required: el.is_required,
                            with_other_option: el.with_other_option
                        });
                    } else if(el.type == "multiple_choice") {
                        custom_question.push({
                            name: el.name || '',
                            content: el.content || '',
                            type: el.type,
                            answer: get_ans_list(el.answer),
                            is_required: el.is_required
                        });
                    } else if (el.type == 'rating') {
                        custom_question.push({
                            name: el.name || '',
                            content: el.content || '',
                            type: el.type,
                            star_count: el.star_count,
                            is_required: el.is_required
                        });
                    } else if (el.type == 'text'){
                        custom_question.push({
                            name: el.name || '',
                            content: el.content || '',
                            type: el.type,
                            is_required: el.is_required,
                            regex: el.regex
                        });
                    } else if (el.type == 'textarea'){
                        custom_question.push({
                            name: el.name || '',
                            content: el.content || '',
                            type: el.type,
                            is_required: el.is_required
                        });
                    }
                });

                return custom_question;
            },
        }
    };
</script>

<style>
    ol, ul {
        padding-left: 0;
        list-style-type: none;
    }
    .list-item {
        cursor: move;
        border-style: solid;
        border-color: #dde1eb;
        border-radius: 4px;
        color: #000;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-bottom: 6px;
        line-height: 40px;
        text-align: left;
        padding-left: 20px;
    }
    span.custom-setting-text {
        display: inline-block;
        width: 200px;
    }
    span.custom-setting-input {
        display: inline-block;
        width: 600px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-left: -12px;
    }

    .col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
</style>