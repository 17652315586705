import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {};

// getters
const getters = {
};

// actions
const actions = {
    getAdList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/ad/list?page=${post_data.page}&page_size=${post_data.page_size}&status=${post_data.status}&ad_type=${post_data.ad_type || ''}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getAdInfo({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/ad/info?ad_id=${post_data.ad_id}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    submitAd({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(params.ad_id ? aj_host + `/weapp/minipass/ad/update` : aj_host + `/weapp/minipass/ad/create`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateAdStatus({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad/status/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateSource({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad_space/source/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    deleteAd({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad/del`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getSpaceList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/ad_space/list`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateSpaceAds({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad_space/ads/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateSpaceStatus({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad_space/status/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateGoogleAdmobSetting({ commit, rootState }, params) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + `/weapp/minipass/ad_space/google_setting/update`, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getStoreInfo({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/store/info?user_name=${post_data.user_name}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCouponListBySid({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/coupon/list?sid=${post_data.sid}`)
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
};

// mutations
const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
