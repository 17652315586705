<template>
    <div class="main_bd">
        <div class="head_title">
            Live Chat Test
            <span>
                <a-spin v-if="is_loading" style="margin-left: 20px;"/>
            </span>
        </div>
        <a-row :gutter="16" style="margin: 20px 0;">
            <a-col :span="4">
                <h3 style="text-align: center;">User Test Phone</h3>
                <a-card>
                    <a href="javascript:;" :style="{fontSize: '16px', color: to_phone_num == phone ? 'blue' : '#000', display: 'block', textAlign: 'center'}" v-for="(phone, index) in user_phone_list" v-bind:key="phone">
                        <span @click="choose_user_phone(phone)">{{ phone }}</span>
                        <a-icon @click="del_user_phone(index)" style="line-height: 21px; vertical-align: top; padding-left: 5px;" type="close"></a-icon>
                    </a>
                </a-card>
                <a-button style="margin-top: 10px;" @click="add_user_phone">Add Test User Phone</a-button>
            </a-col>
            <a-col :span="16">
                <a-card>
                    <a-list
                        class="comment-list"
                        item-layout="horizontal"
                        :data-source="new_message_list"
                    >
                        <a-list-item slot="renderItem" slot-scope="item">
                            <a-comment :author="item.author" :avatar="item.avatar">
                                <p slot="content">
                                    {{ item.content }}
                                </p>
                                <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                                    <span>{{ item.datetime.fromNow() }}</span>
                                </a-tooltip>
                            </a-comment>
                        </a-list-item>
                    </a-list>
                    <div>
                        <a-form-item>
                            <a-textarea :rows="4" :value="content" />
                        </a-form-item>
                        <a-form-item>
                            <a-button html-type="submit" type="primary" :disabled="!to_phone_num || !store_phone_num" @click="handleSubmit">
                              Use {{ store_phone_num }}  Send Message To {{ to_phone_num }}
                            </a-button>
                        </a-form-item>
                    </div>
                </a-card>
            </a-col>
            <a-col :span="4">
                <h3 style="text-align: center;">Store Phone</h3>
                <a-card>
                    <a href="javascript:;" :style="{fontSize: '16px', color: store_phone_num == phone ? 'blue' : '#000', display: 'block', textAlign: 'center'}" @click="choose_store_phone(phone)" v-for="phone in store_phone_list" v-bind:key="phone">{{ phone }}</a>
                </a-card>
            </a-col>
        </a-row>
        <a-modal
            title="Add Test User Phone"
            :visible="show_add_layer"
            @ok="handleOk"
            ok-text="Add"
            @cancel="handleCancel"
            :width="500"
            style="height: 600px; overflow: auto;"
        >
            <a-input style="display: inline-block; width:70px;" value="+1" :readOnly="true"></a-input>
            <a-input style="display: inline-block; width:300px; margin-left: 5px;" type="number" v-model="test_user_phone" placeholder="User Phone"></a-input>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment  from 'moment-timezone';

    export default {
        name: 'livechat_test',
        components: {
        },
        data() {
            return {
                message_list: [],
                moment,
                sid: window.localStorage.getItem('sid') || '',
                store_phone_num: '',
                to_phone_num: '',
                user_phone_list: [],
                is_loading: false,
                timer: null,
                show_add_layer: false,
                test_user_phone: '',
                content: '',
                store_phone_list: ["+18447560645","+18334011807","+18554981747","+18339142842","+18556062171","+18557740046","+18333220915","+18446100491","+18333971560","+18554290853","+18449824463","+18446180961","+18335231461","+18665158935","+18557691703","+18337381102","+18336221097"]
            };
        },
        async created() {
            this.init_user_phone();

            this.timer = setInterval(() => {
                this.get_chat_message_list();
            }, 5000);
        },
        computed: {
            ...mapGetters({}),
            ...mapState({}),
            new_message_list() {
                return this.message_list.map(item => {
                    item.datetime = moment(item.createdate * 1)

                    return item;
                });
            }
        },
        methods: {
            ...mapActions({
                getLiveChatMessageList: 'monitor/getLiveChatMessageList',
                sendLiveChatMessage: 'monitor/sendLiveChatMessage',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            async get_chat_message_list() {
                this.is_loading = true;

                if (!this.store_phone_num || !this.to_phone_num) {
                    this.is_loading = false;
                    return;
                }

                let res = await this.getLiveChatMessageList({
                    store_phone_num: this.store_phone_num,
                    to_phone_num: this.to_phone_num,
                    sid: this.sid
                });

                this.message_list = res.data.list;
                this.is_loading = false;
            },
            choose_user_phone(user_phone) {
                this.to_phone_num = user_phone;
                this.get_chat_message_list();
            },
            del_user_phone(index) {
                let temp_list = JSON.parse(JSON.stringify(this.user_phone_list));

                let tmp_user_phone_list = [...temp_list.slice(0, index), ...temp_list.slice(index + 1)];
                window.localStorage.setItem('user_phone', JSON.stringify(tmp_user_phone_list));

                this.user_phone_list = tmp_user_phone_list;
            },
            choose_store_phone(store_phone) {
                this.store_phone_num = store_phone;
                this.get_chat_message_list();
            },
            add_user_phone() {
                this.show_add_layer = true;
            },
            init_user_phone() {
                let user_phone_list = window.localStorage.getItem('user_phone') || '';

                try {
                    this.user_phone_list = JSON.parse(user_phone_list);
                } catch (e) {
                    this.user_phone_list = [];
                }
            },
            handleOk() {
                this.show_add_layer = false;
                let user_phone = this.test_user_phone;

                if (user_phone) {
                    this.user_phone_list.push('+1 ' + user_phone);
                    window.localStorage.setItem('user_phone', JSON.stringify(this.user_phone_list));
                    
                    this.test_user_phone = '';
                }
            },
            handleCancel() {
                this.show_add_layer = false;
            },
            async handleSubmit() {
                if (!this.content) {
                    await this.sendLiveChatMessage({
                        sid: this.sid,
                        store_phone_num: this.store_phone_num,
                        to_phone_num: this.to_phone_num,
                        content: this.content,
                        platform: 'twilio'
                    });
                }
            }
        }
    };
</script>

<style>
    .custom_input{
        width: 220px !important;
    }
    .comment-list{
        height: 300px;
        overflow: auto;
    }
    .ant-comment-inner{
        padding: 0 !important;
    }
</style>