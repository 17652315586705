<template>
    <h1>1</h1>
</template>

<style>
</style>

<script>
    export default {
        name: 'dashboard',
        components: {
        },
        data() {
            return {};
        }
    };
</script>
