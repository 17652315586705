<template>
    <div class="main_bd">
        <div class="head_title">Ad Management</div>
        <div class="frm_controls" style="margin: 30px 0">
            <a-menu v-model="current" mode="horizontal">
                <a-menu-item key="ad_campaign">Ad campaign management </a-menu-item>
                <a-menu-item key="ad_space"> Ad Space & Ad Display Management  </a-menu-item>
            </a-menu>
        </div>
        <div class="action_content">
            <div>
                <button style="width: 200px;" class="action_btn active_btn" @click="turn_to_create">Create new Ad campaign</button>
            </div>
        </div>
        <div class="action_content" style="margin-top: 20px;">
            <div>
                <span style="padding-right: 20px;">Campaigns list</span>
                <a-radio-group v-model="status" button-style="solid" @change="onChangeStatus">
                    <a-radio-button :value="1">
                        Published
                    </a-radio-button>
                    <a-radio-button :value="0">
                        Draft
                    </a-radio-button>
                    <a-radio-button :value="2">
                        Archived
                    </a-radio-button>
                </a-radio-group>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.id" :columns="columns" :data-source="ad_list" >
                <template slot="type" slot-scope="text, data">
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-if="data.ad_type == 'general'">
                        General
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'poster'">
                        Custom poster
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'system_coupon'">
                        System coupon
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'external_coupon'">
                        External coupon
                    </span>
                    <span style="width: 90px; border-radius: 12px; text-align: center;" v-else-if="data.ad_type == 'full_screen'">
                        Full screen
                    </span>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a-popover title="" trigger="click" placement="bottom" @visibleChange="handleClickChange(data)">
                        <template slot="content">
                            <div>
                                <div style="height: 10px"></div>
                                <a style="color: #000" @click="turn_to_create(data)">{{ data.status == 0 ? 'View & Publish' : 'View Details'}}</a>
                            </div>
                            <div v-if="data.status == 1 && data.displaying == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="do_archive(data)">Archive</a>
                            </div>
                            <div v-if="data.status == 0">
                                <div style="height: 10px"></div>
                                <a style="color: #FD7858" @click="delete_ad(data)">Delete</a>
                            </div>
                        </template>
                        <a-button type="link">
                            ...
                        </a-button>
                    </a-popover>
                </div>
            </a-table>
            <a-pagination style="margin-top: 5px;" @change="turnPage" :pageSize="20" v-model="cur_page" :total="total_count"
                show-less-items />
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: "Name",
            dataIndex: "ad_name",
            width: 300
        },
        {
            title: "Type",
            dataIndex: "ad_type",
            scopedSlots: { customRender: 'type' }
        },
        {
            title: "Ad id",
            dataIndex: "ad_id"
        },
        {
            title: "Created time",
            dataIndex: "create_time"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'adCenter',
        components: {
        },
        data() {
            return {
                current: ['ad_campaign'],
                status: 1,
                sid: window.localStorage.getItem('sid'),
                columns,
                cur_page: 1,
                total_page: 0,
                total_count: 0,
                ad_list: [],

                selectedArr: [],
                start_date: '',
                end_date: '',
                item: {},
                show_deactivate_layer: false,
                show_delete_layer: false,
                show_activate_layer: false,
                show_deactivate_all_layer: false,
                show_delete_all_layer: false,
                show_activate_all_layer: false,
                show_content_layer: false,
                merchant_id: '',
            };
        },
        beforeMount() {
            let _sid = window.localStorage.getItem('sid') || '';
            let _token = window.localStorage.getItem('token') || '';

            if (!_sid || !_token) {
                window.location.replace('/login');
            }
        },
        async created() {
            this.get_ad_list();
        },
        watch: {
            current() {
                if (this.current.indexOf('ad_space') > -1) {
                    this.$router.push({
                        path: '/adspace'
                    });
                }
            }
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            })
        },
        methods: {
            ...mapActions({
                getAdList: 'ad/getAdList',
                deleteAd: 'ad/deleteAd',
                updateAdStatus: 'ad/updateAdStatus'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            onChangeStatus(e) {
                this.get_ad_list();
            },
            open_content_layer(data) {
                this.item = { ...data };
                this.show_content_layer = true;
            },
            cancel_content_layer() {
                this.show_content_layer = false;
            },
            async get_ad_list() {
                let { list, total_count, total_page } = await this.getAdList({
                    page: this.cur_page,
                    page_size: 20,
                    status: this.status
                });

                this.ad_list = list;
                this.total_page = total_page;
                this.total_count = total_count;
            },
            turnPage(e) {
                this.get_ad_list();
            },
            turn_to_create(data) {
                this.$router.push({
                    path: data.ad_id ? '/adedit?ad_id=' + data.ad_id : '/adedit'
                });
            },
            handleClickChange(a, b) {
            },
            async delete_ad(data) {
                this.$confirm({
                    title: 'Are you sure you want to delete?',
                    content: 'Unable to recover after deletion',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        await this.deleteAd({
                            ad_id: data.ad_id
                        });
                        this.get_ad_list();
                    },
                    onCancel: async () => {},
                });
            },
            async do_publish(data) {
                await this.updateAdStatus({
                    ad_id: data.ad_id,
                    status: 1
                });
                this.status = 1;
                this.get_ad_list();
            },
            async do_archive(data) {
                this.$confirm({
                    title: 'Are you sure you want to archive?',
                    content: 'Unable to recover after archive',
                    okText: 'Sure',
                    okType: 'danger',
                    cancelText: 'cancel',
                    onOk: async () => {
                        let res = await this.updateAdStatus({
                            ad_id: data.ad_id,
                            status: 2
                        });

                        if (res && res.code != 100000) {
                            this.$message.error(res.msg);
                            return;
                        }

                        this.status = 2;
                        this.get_ad_list();
                    },
                    onCancel: async () => {},
                });
            },
        }
    };
</script>

<style>
    .action_content {
        display: flex;
        justify-content: space-between;
    }
    .action_btn {
        width: 102px;
        height: 28px;
        border: none;
        border-radius: 8px;
        background-color: #7427FF;
        color: rgba(255, 255, 255, 1);
        font-family: 'Helvetica';
        font-size: 16px;
        margin-left: 10px;
    }
    .action_btn.delete_btn {
        background-color: #FD7858;
    }
    .action_btn.deactivate_btn {
        background-color: #7D7D7D;
    }
    .action_btn.active_btn {
        background-color: #2C9100;
    }
    .been_activated {
        text-align: center;
        line-height: 26px;
        font-size: 14px;
    }
    .been_activated.activated_orange {
        color: #FD7858;
    }
    .content_layer h2 {
        margin-top: 20px;
        font-size: 16px;
        color: #7427FF;
    }
    .content_layer .content_item {
        display: flex;
        color: #333;
        margin: 0;
        line-height: 28px;
    }
    .content_layer .content_item dt {
        font-weight: 500;
        margin-right: 10px;
    }
    .content_layer .content_item dd {
        font-size: 12px;
        margin-right: 20px;
    }
    .content_layer .content_item a {
        color: #7427FF;
        cursor: text;
    }
    .content_layer_bottom {
        border-top: 1px solid #d8d8d8;
        margin-top: 10px;
    }
    .content_layer_top {
        position: relative;
    }
    .content_layer_btn {
        position: absolute;
        top: -5px;
        right: 20px;
        line-height: 24px;
    }
    .content_layer_btn .action_btn {
        border-radius: 12px;
        height: 24px;
        font-size: 13px;
        width: 90px;
        cursor: auto;
    }
    .image_box a {
        display: block;
    }
</style>