<template>
    <div class="main_bd">
        <div class="head_title">User management</div>
        <div class="frm_controls search" style="margin: 30px 0">
            <span class="frm_input_box vcode with_counter counter_in append"
                style="display: inline-block; width: 300px; padding-right: 10px">
                <input class="frm_input" style="width: 100%" v-model="guest_info"
                    placeholder="search by name, id, phone, email" @keyup.enter="search_guest" />
            </span>
            <a-button type="primary" @click="search_guest"> Search </a-button>
            <a style="font-size: 18px; color: rgba(98, 0, 238, 0.85); margin-left: 20px"
                @click="showFilter = !showFilter">
                filter
                <a-icon :type="showFilter ? 'caret-up' : 'caret-down'" />
            </a>
        </div>
        <div v-if="showFilter" class="mod_default_box permission" style="background-color: #6200ee14; padding: 30px">
            <div class="mod_default_bd" style="margintop: 30px">
                <div class="form-group">
                    <div class="row" style="padding-bottom: 10px">
                        <div class="col-auto" style="padding-bottom: 10px" v-if="false">
                            <div class="frm_controls">
                                <span style="display: inline-block; width: 200px;color: #12263f;
    font-size: .9375rem;">City（Multiple choices）:</span>
                                <span>
                                    <a-checkbox-group v-model="checkedCityList" :options="city_list"
                                        @change="on_change_city" />
                                </span>
                            </div>
                        </div>
                        <div class="col-auto" style="padding-bottom: 10px">
                            <div class="frm_controls">
                                <span style="display: inline-block; width: 200px;color: #12263f;
    font-size: .9375rem;">Status:</span>
                                <span>
                                    <a-checkbox-group v-model="checkedBlackList" :options="blackOptions"
                                        @change="on_change_black" />
                                </span>
                            </div>
                        </div>
                        <div class="time_box">
                            <a-checkbox-group v-model="checkedTimeList" @change="on_change_time">
                                <div class="col-auto" style="padding-bottom: 10px">
                                    <div class="frm_controls">
                                        <span style="display: inline-block; width: 200px;color: #12263f;
    font-size: .9375rem;">Filtered by time:</span>
                                    </div>
                                </div>
                                <div class="col-auto" style="padding-bottom: 10px; padding-left: 200px">
                                    <div class="frm_controls">
                                        <a-checkbox value="active">Active Time</a-checkbox>
                                        <span style="display: inline-block; width: 200px; padding-right: 10px">
                                            <a-date-picker v-model="active_date_min" placeholder="Start date"
                                                style="width: 100%" />
                                        </span>
                                        <span>to</span>
                                        <span style="display: inline-block; width: 200px; padding-left: 10px">
                                            <a-date-picker v-model="active_date_max" placeholder="End date"
                                                style="width: 100%" :disabled-date="disabledEndDate" />
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto" style="padding-bottom: 10px; padding-left: 200px">
                                    <div class="frm_controls">
                                        <a-checkbox value="create">Registration time:</a-checkbox>
                                        <span style="display: inline-block; width: 200px; padding-right: 10px">
                                            <a-date-picker v-model="date_min" placeholder="Start date"
                                                style="width: 100%" />
                                        </span>
                                        <span>to</span>
                                        <span style="display: inline-block; width: 200px; padding-left: 10px">
                                            <a-date-picker v-model="date_max" placeholder="End date" style="width: 100%"
                                                :disabled-date="disabledRegistEndDate" />
                                        </span>
                                    </div>
                                </div>
                                <div class="col-auto" style="padding-bottom: 10px; padding-left: 200px">
                                    <div class="frm_controls">
                                        <a-checkbox value="birthday">birthday</a-checkbox>
                                        <span style="display: inline-block; width: 200px; padding-right: 10px">
                                            <a-date-picker v-model="birthday_date_min" placeholder="Start date"
                                                style="width: 100%" />
                                        </span>
                                        <span>to</span>
                                        <span style="display: inline-block; width: 200px; padding-left: 10px">
                                            <a-date-picker v-model="birthday_date_max" placeholder="End date"
                                                style="width: 100%" :disabled-date="disabledBirthdayEndDate" />
                                        </span>
                                    </div>
                                </div>
                            </a-checkbox-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :rowKey="(data) => data.id" :columns="columns" :data-source="guest_list" :pagination="false"
                @change="handleTableChange">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.index }}</p>
                </a>
                <template slot="createdate" slot-scope="text, data">
                    <span>{{ formatDate(data.createdate) }}</span>
                </template>
                <template slot="status" slot-scope="text, data">
                    <a-tag v-if="data.blocklist == 1" color="black">
                        {{ status[4].label }}
                    </a-tag>
                    <a-tag v-else color="geekblue">
                        {{ parseStatus(data) }}
                    </a-tag>
                </template>
                <div slot="action" slot-scope="text, data">
                    <a style="color: rgba(98, 0, 238, 0.85)" @click="toggle_blocklist(data)">{{
                            data.blocklist == 1 ? 're-activate' : 'add to blacklist'
                    }}</a>
                    <div style="height: 10px"></div>
                    <a style="color: rgba(98, 0, 238, 0.85)" @click="toDetail(data)">details</a>
                    <div style="height: 10px"></div>
                    <a style="color: rgba(98, 0, 238, 0.85)" @click="showLog(data)">log</a>
                </div>
            </a-table>
            <a-pagination style="margin-top: 25px" @change="turnPage" :pageSize="10" v-model="page_num"
                :total="total_count" show-less-items show-quick-jumper
                :show-total="(total) => `Total ${total_count} items`" />
        </div>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <a-descriptions>
                <div class="detail_title" slot="title">
                    <div class="modal_head">
                        <div>{{ curGuest.name == 'N/A' ? 'unnamed' : curGuest.name }}</div>
                        <a-tag v-if="curGuest.blocklist == 1" color="black">
                            {{ status[4].label }}
                        </a-tag>
                        <a-tag v-else color="blue">
                            {{ parseStatus(curGuest) }}
                        </a-tag>
                    </div>
                </div>
                <a-descriptions-item label="Phone" :span="2">
                    {{ curGuest.phone }}
                </a-descriptions-item>
                <a-descriptions-item label="E-mail" :span="1">
                    {{ curGuest.email }}
                </a-descriptions-item>
                <a-descriptions-item label="Birthday" :span="2">
                    {{ curGuest.birthday }}
                </a-descriptions-item>
                <a-descriptions-item label="Location" :span="1">
                    {{ curGuest.city }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Activity log</div>
                <a-descriptions-item label="Join date" :span="2">
                    {{ formatDate(curGuest.createdate) }}
                </a-descriptions-item>
                <a-descriptions-item label="Last open" :span="1">
                    {{ formatDate(Number(curGuest.last_active_time)) }}
                </a-descriptions-item>
                <a-descriptions-item label="Data deletion" :span="3" v-if="curGuest.data_del_time">
                    {{ parseReason(curGuest) }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Business record</div>
                <a-descriptions-item label="Waitlist total" :span="1">
                    {{ count_param.wait_count }}
                </a-descriptions-item>
                <a-descriptions-item label="Reservation total" :span="1">
                    {{ count_param.reservation_count }}
                </a-descriptions-item>
                <a-descriptions-item label="Pass total" :span="1">
                    {{ pass_count }}
                </a-descriptions-item>
            </a-descriptions>
            <div slot="footer" style="text-align: center; margin: 20px 0">
                <a-button type="danger" @click="toggle_blocklist(curGuest)">
                    {{ curGuest.blocklist == 1 ? 're-activate' : 'add to blacklist' }}
                </a-button>
                <a-button @click="handleOk"> Cancel </a-button>
            </div>
        </a-modal>
        <a-modal :width="600" v-model="logVisible" :closable="false" @ok="handleLogOk">
            <div style="text-align: center;">
                <h3>user log</h3>
            </div>
            <a-table :rowKey="(data) => data.create_time" :columns="logColumns" :data-source="action_log"
                :pagination="{ pageSize: 50 }" :scroll="{ y: 240 }" />
            <template slot="footer">
                <a-button type="primary" @click="handleLogOk"
                    style="margin-right: 200px; background-color: #2c9100; border-color: #2c9100">
                    Done
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<style>
.col_main .col_main_inner {
    width: 98%;
    margin: 0 auto;
}

.main_bd {
    padding-top: 20px;
    width: 98%;
    margin: 0 auto;
}

.mod_default_box {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px hsl(0deg 0% 59% / 30%);
    padding: 30px 0;
}

.col-auto {
    width: 100%;
}

.time_box {
    padding-right: 100px;
    position: relative;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.time_btn {
    position: absolute;
    right: 20px;
    top: 68px;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px 35px 0 0;
}

.event_name {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 24px;
    margin-bottom: 15px;
}

.detail_title {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 20px;
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
const moment = require('moment-timezone');

const blackOptions = ['Blacklist', 'Data deletion', 'Active', 'New user'];

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        scopedSlots: { customRender: 'index' },
        width: 100
    },
    {
        title: 'Join date',
        dataIndex: 'createdate',
        sorter: true,
        scopedSlots: { customRender: 'createdate' }
    },
    {
        title: 'User name',
        dataIndex: 'name'
    },
    {
        title: 'Phone',
        dataIndex: 'phone'
    },
    {
        title: 'Birthday',
        dataIndex: 'birthday'
    },
    {
        title: 'Location',
        dataIndex: 'city'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: 'More',
        dataIndex: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' },
        fixed: 'right'
    }
];
const logColumns = [
    {
        title: 'Time',
        dataIndex: 'create_time',
        width: 200,
    },
    {
        title: 'Action',
        dataIndex: 'action_name',
        width: 150,
    },
    {
        title: 'Operator',
        dataIndex: 'user_id',
        width: 200,
    },
];

export default {
    name: 'guests',
    components: {},
    data() {
        return {
            columns,
            blackOptions,
            sid: this.$route.query.sid || '',
            guest_info: '',
            guest_list_selected: [],
            date_min: null,
            date_max: null,
            active_date_min: null,
            active_date_max: null,
            birthday_date_min: null,
            birthday_date_max: null,
            checkedCityList: [],
            checkedBlackList: [],
            checkedTimeList: [],
            blocklist: null,
            page_num: 1,
            showFilter: false,
            detailVisible: false,
            curGuest: {},
            selectedStatus: '',
            status: [
                {
                    label: 'new user',
                    value: '0'
                },
                {
                    label: 'active',
                    value: '1'
                },
                {
                    label: 'data deleted',
                    value: '2'
                },
                {
                    label: 'Account deleted',
                    value: '3'
                },
                {
                    label: 'blacklisted',
                    value: '4'
                }
            ],
            logVisible: false,
            logColumns,
            user_id: ''
        };
    },
    beforeMount() {
        let _token = window.localStorage.getItem('token') || '';

        if (!_token) {
            window.location.replace('/login');
        }
    },
    mounted() {
        this.user_id = this.$route.query.user_id;
        if (this.user_id){
            this.getGuestById({
                id: this.user_id
            }).then(res => {
                this.toDetail(res.data);
            })     
        }
        this.search_guest_list({
            page_num: 1
        });
    },
    computed: {
        ...mapGetters({
            city_list: 'guest/get_city_list',
            guest_list: 'guest/get_guest_list',
            msg_opts: 'app/get_app_msg',
            total_page: 'guest/get_total_page',
            total_count: 'guest/get_total_count',
            count_param: 'guest/get_count_param',
            pass_count: 'guest/get_pass_count',
            action_log: 'guest/get_action_log'
        }),
        ...mapState({})
    },
    methods: {
        showLog(data) {
            this.get_action_log({
                id: data.id
            });
            this.logVisible = true;
        },
        handleLogOk() {
            this.logVisible = false;
        },
        handleTableChange(pagination, filters, sorter) {
            this.search_guest(sorter.order);
        },
        disabledDate(current) {
            return current && current > moment().endOf('day');
        },
        disabledEndDate(current) {
            return current && current.valueOf() <= this.active_date_min.valueOf();
        },
        disabledRegistEndDate(current) {
            return current && current.valueOf() <= this.date_min.valueOf();
        },
        disabledBirthdayEndDate(current) {
            return current && current.valueOf() <= this.birthday_date_min.valueOf();
        },
        on_change_city(checked) {
            this.checkedCityList = checked;
            this.search_guest();
        },
        on_change_black(val) {
            console.log(val);
            // this.blocklist = null;
            // this.selectedStatus = '';
            // if (val.indexOf('Blacklist') !== -1) {
            //     this.blocklist = 1;
            // }
            // if (val.indexOf('Data deletion') !== -1) {
            //     this.selectedStatus = 2;
            // } else {
            //     this.selectedStatus = 1;
            // }

            this.search_guest();
        },
        on_change_time(checked) {
            this.checkedTimeList = checked;
        },
        search_guest(sorder) {
            let serchObj = this.checkFilter();
            this.search_guest_list({
                sorder: sorder ? sorder : null,
                ...serchObj
            });
        },
        checkFilter(page_num) {
            page_num = page_num ? page_num : 1;
            this.page_num = page_num;
            let city_str = '';
            if (this.checkedCityList.length > 0) {
                for (let i = 0; i < this.checkedCityList.length; i++) {
                    city_str += this.checkedCityList[i] + ',';
                }
                city_str = city_str.substr(0, city_str.length - 1);
            }

            let serchObj = {};
            if (this.guest_info) {
                serchObj.search = this.guest_info;
            }

            if (city_str) {
                serchObj.city = city_str;
            }

            if (this.blocklist) {
                serchObj.blocklist = this.blocklist;
            }

            if (this.checkedBlackList.length > 0) {
                serchObj.checkedBlackList = this.checkedBlackList.join(',');
            }

            if (this.checkedTimeList.includes('active')) {
                let active_date_min = this.active_date_min ? moment(this.active_date_min).tz('America/New_York', true).startOf('day').valueOf() : null;
                let active_date_max = this.active_date_max ? moment(this.active_date_max).tz('America/New_York', true).endOf('day').valueOf() : null;
                serchObj.last_active_stime = active_date_min;
                serchObj.last_active_etime = active_date_max;
            }

            if (this.checkedTimeList.includes('birthday')) {
                let birthday_date_min = this.birthday_date_min
                    ? moment(this.birthday_date_min).format('YYYY-MM-DD')
                    : null;
                let birthday_date_max = this.birthday_date_max
                    ? moment(this.birthday_date_max).format('YYYY-MM-DD')
                    : null;
                serchObj.birthday_stime = birthday_date_min;
                serchObj.birthday_etime = birthday_date_max;
            }

            if (this.checkedTimeList.includes('create')) {
                let date_min = this.date_min ? moment(this.date_min).format('YYYY-MM-DD') : null;
                let date_max = this.date_max ? moment(this.date_max).format('YYYY-MM-DD') : null;
                serchObj.create_stime = date_min;
                serchObj.create_etime = date_max;
            }

            serchObj.page_num = page_num;

            return serchObj;
        },
        toggle_blocklist(data) {
            let msg = data.blocklist
                ? 'Are you sure to remove this user from the blacklist?'
                : 'Are you sure to add this user to the blacklist?';
            this.$swal({
                title: 'Confirm?',
                text: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then((result) => {
                if (result.value) {
                    this.update_blocklist({
                        id: data.id,
                        blocklist: data.blocklist ? 0 : 1,
                        index: data.index,
                        operate_user: window.localStorage.getItem('uname') || ''
                    });
                    this.detailVisible = false;
                }
            });
        },
        turnPage(e) {
            let serchObj = this.checkFilter(this.page_num);
            this.search_guest_list({
                ...serchObj
            });
        },
        toDetail(data) {
            this.get_count_param({
                phone: data.phone
            });
            this.get_pass_count({
                id: data.id
            });
            this.curGuest = { ...data };
            this.detailVisible = true;
        },
        handleOk() {
            this.detailVisible = false;
        },
        parseStatus(data) {
            if (data.status === '') {
                return '';
            }
            if (data.status === 1) {
                if (moment().add(-7, 'd') > moment(data.createdate)) {
                    return this.status[1].label;
                } else {
                    return this.status[0].label;
                }
            } else {
                for (let index = 0; index < this.status.length; index++) {
                    if (this.status[index].value == data.status) {
                        return this.status[index].label;
                    }
                }
            }
            return '';
        },
        formatDate(time) {
            if (!time) {
                return 'N/A'
            }
            return moment.tz(time, 'America/New_York').format('MM/DD/YYYY');
        },
        parseReason(user_data) {
            let str = '';

            if (user_data.data_del_time) {
                str += moment.tz(user_data.data_del_time * 1, 'America/New_York').format('MM/DD/YYYY HH:mm');
                str += ' ';
            }

            user_data.data_del_reason &&
                user_data.data_del_reason.map((item) => {
                    str += item + '  ';
                });
            return str;
        },
        ...mapActions({
            init_city_data: 'guest/initCityData',
            search_guest_list: 'guest/selectGuestList',
            update_blocklist: 'guest/updateBlocklist',
            get_count_param: 'guest/updateGuestCount',
            get_pass_count: 'guest/getPassCount',
            get_action_log: 'guest/actionLog',
            getGuestById: 'guest/getGuestById'
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        })
    }
};
</script>
