import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    doExport({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        return new Promise((resolve, reject) => {
            axiosApi({
                method: 'post',
                url: aj_host + `/weapp/minipass/data/guest/export`,
                data: post_data,
                responseType: 'blob',
                timeout: 0
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
};

// mutations
const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
