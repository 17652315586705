import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    feedback_list: [],
    total_page: 0,
    total_count: 0,
    survey_list: [],
    total_survey_count: 0,
    total_survey_page: 0,
    survey_data: {}
};

// getters
const getters = {
    get_feedback_list(state) {
        return state.feedback_list;
    },
    get_total_page(state) {
        return state.total_page;
    },
    get_total_count(state) {
        return state.total_count;
    },
    get_survey_list(state) {
        return state.survey_list;
    },
    get_total_survey_count(state) {
        return state.total_survey_count;
    },
    get_total_survey_page(state) {
        return state.total_survey_page;
    },
    get_survey_data(state) {
        return state.survey_data;
    }
};

// actions
const actions = {
    getFeedbackList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_feedback_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_FEEDBACK_LIST", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
    getSurveyList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_survey_list',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_SURVEY_LIST", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
    getSurveyData({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_survey_data',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("INIT_SURVEY_DATA", res.data.data);
                    //post_data.cb && post_data.cb();
                }
            })
    },
};

// mutations
const mutations = {
    INIT_FEEDBACK_LIST(state, res_data) {
        state.feedback_list = res_data.list;
        state.total_page = res_data.total_page;
        state.total_count = res_data.total_count;
    },
    INIT_SURVEY_LIST(state, res_data) {
        state.survey_list = res_data.list;
        state.total_survey_page = res_data.total_page;
        state.total_survey_count = res_data.total_count;
    },
    INIT_SURVEY_DATA(state, res_data) {
        state.survey_data = res_data
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
