import fetchApi from '../../kits/newFetch';
import { message } from 'ant-design-vue';
// import i18n from '../../locales';
// import { isValidStr } from '../../kits/tools';

// let lock_aj = false;
// let edit_cart_lock_aj = false;

// initial state
const state = {
    msg_opts: {},
    sid: window.localStorage.getItem('sid') || '',
    uid: window.localStorage.getItem('uid') || '',
    role: window.localStorage.getItem('role') || '',
    sname: window.localStorage.getItem('sname') || '',
    slogo: window.localStorage.getItem('slogo') || '',
    token: window.localStorage.getItem('token') || '',
    is_loading: false
};

// getters
const getters = {
    get_app_msg(state) {
        return state.msg_opts;
    },
    get_role(state) {
        return state.role;
    },
};

// actions
const actions = {
    // initData({ commit, rootState, rootGetters }, sid) {
    //     let aj_host = rootGetters.serverRoot;
    //     fetch(aj_host + '/weapp/takeout/store/get?sid=' + (state.sid || sid), {
    //         method: 'GET'
    //     })
    //         .then((res) => {
    //             return res.text();
    //         })
    //         .then((res) => {
    //             res = JSON.parse(res);
    //         });
    // }
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_MSG(state, _data) {
        state.msg_opts = _data;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.sid;
        state.uid = _data.uid;
        state.role = _data.role;
        state.sname = _data.sname;
        state.slogo = _data.slogo;
        state.token = _data.token;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
