<template>
    <div class="main_bd">
        <div style="margin: 40px 0">
            <a-breadcrumb>
                <span slot="separator" style="color: #000; font-size: 22px">></span>
                <router-link :to="'/master/list'">
                    <a-breadcrumb-item class="b-item-1">Master Account</a-breadcrumb-item>
                </router-link>
                <a-breadcrumb-item class="b-item-2">{{ chain_id ? 'Edit account' : 'Create account' }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="permission">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
                labelAlign="left">
                <div class="form-item" style="margin-bottom: 0;">
                    <div class="form-title">Master Account Info</div>
                    <a-form-model-item ref="chain_name" label="Account Name" prop="chain_name">
                        <a-input v-model="form.chain_name" autocomplete="new-password" placeholder="No more than 30 characters" @blur="
                            () => {
                                $refs.chain_name.onFieldBlur();
                            }
                        " :maxLength="30" />
                    </a-form-model-item>
                    <a-form-model-item ref="user_name" label="Login ID" prop="user_name">
                        <a-input v-model="form.user_name" autocomplete="new-password" placeholder="name@address.com" @blur="
                            () => {
                                $refs.user_name.onFieldBlur();
                            }
                        " :maxLength="100" />
                    </a-form-model-item>
                    <a-form-model-item ref="pswd" label="Login Password" prop="pswd">
                        <a-input type="password" v-model="form.pswd" autocomplete="new-password" placeholder="create password" @blur="
                            () => {
                                $refs.pswd.onFieldBlur();
                            }
                        " :maxLength="100" />
                    </a-form-model-item>
                </div>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
                    <a-button type="primary" class="create-btn" @click="doSave"> Save</a-button>
                    <a-button class="cancel-btn" @click="goBack"> Back </a-button>
                </a-form-model-item>
            </a-form-model>
            <div class="form-title">Linked Accounts</div>
            <a-table :pagination="false" :rowKey="data => data.id" :columns="merchant_columns" :data-source="linked_accounts" >
                <div slot="action" slot-scope="text, data">
                    <div>
                        <a style="color: #2c9100" @click="doDelete(data)">delete</a>
                    </div>
                </div>
            </a-table>
            <a-button type="primary" class="create-btn" @click="doAddAccount"> Add account</a-button>
        </div>
        <a-modal
            title=""
            :visible="show_link_layer"
            @ok="handleOk"
            @cancel="handleCancel"
            :width="500"
            style="height: 200px; overflow: auto;"
        >
            <a-descriptions>
                <div class="detail_title" slot="title">Add Link Account</div>
                <a-descriptions-item label="Merchant Email" :span="3">
                    <a-input v-model="add_user_name" autocomplete="new-password" placeholder="name@address.com" ></a-input>
                </a-descriptions-item>
                <a-descriptions-item label="Login Password" :span="3">
                    <a-input v-model="add_pswd" type="password" autocomplete="new-password" placeholder="enter password" ></a-input>
                </a-descriptions-item>
            </a-descriptions>
        </a-modal>
     </div>
</template>

<script>
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import moment from 'moment';

    const merchant_columns = [
        {
            title: "No.",
            dataIndex: "index"
        },
        {
            title: "Merchant ID",
            dataIndex: "sid"
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Merchant Email",
            dataIndex: "user_name"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    export default {
        name: 'edit_master_account',
        data() {
            return {
                show_link_layer: false,
                merchant_columns,
                chain_id: '',
                add_user_name: '',
                add_pswd: '',
                labelCol: { span: 3 },
                wrapperCol: { span: 10 },
                form: {
                    chain_name: '',
                    user_name: '',
                    pswd: '',
                },
                linked_accounts: [],
                rules: {
                    chain_name: [
                        { required: true, message: 'Please input account name', trigger: 'blur' },
                        { min: 1, max: 30, message: 'No more than 30 characters', trigger: 'blur' }
                    ],
                    user_name: [{ required: true, message: 'Please input Login ID', trigger: 'blur' }],
                    pswd: [],
                }
            };
        },
        beforeMount() {
        },
        mounted() {
            const { chain_id } = this.$route.query;
            if(chain_id){
                this.chain_id = chain_id;
                this.doGetMasterDetail();   
            }
        },
        computed: {
            ...mapGetters({
                msg_opts: 'app/get_app_msg'
            }),
            ...mapState({})
        },
        methods: {
            doGetMasterDetail() {
                this.getMasterDetail({
                    chain_id: this.chain_id
                }).then(res => {
                    this.form = {
                        chain_name: res.data.account_detail.chain_name,
                        user_name: res.data.account_detail.user_name,
                        pswd: '',
                    };
                    this.linked_accounts = res.data.account_detail.linked_list;
                })
            },
            handleCancel() {
                this.show_link_layer = false;
            },
            async handleOk() {
                if (!this.chain_id) {
                    this.$message.error('Please save the account first');
                    return;
                }

                if (!this.add_user_name) {
                    this.$message.error('Please input merchant email');
                    return;
                }

                if (!this.add_pswd) {
                    this.$message.error('Please input password');
                    return;
                }
                
                let res = await this.checkMerchantAccount({
                    user_name: this.add_user_name,
                    pswd: this.add_pswd
                });

                if (res && res.code == 100000) {
                    let sid = res.data.sid;

                    let link_res = await this.linkMerchantAccount({
                        chain_id: this.chain_id,
                        sid,
                        is_link: 1
                    });

                    if (link_res && link_res.code == 100000) {
                        this.$message.success('Link account success');
                        this.show_link_layer = false;
                        this.doGetMasterDetail();
                    } else {
                        this.$message.error(link_res.msg);
                    }
                } else {
                    this.$message.error('Wrong email or password');
                }

            },
            doDelete(data) {
                this.$confirm({
                    title: 'Are you sure you want to delete this account?',
                    content: 'You can link it back anytime.',
                    okText: 'Confirm',
                    // okType: 'danger',
                    cancelText: 'Cancel',
                    onOk: async () => {
                        let res = await this.linkMerchantAccount({
                            chain_id: this.chain_id,
                            sid: data.sid,
                            is_link: 0
                        });

                        if (res && res.code == 100000) {
                            this.$message.success('Delete account success');
                            this.doGetMasterDetail();
                        } else {
                            this.$message.error('Delete account failed');
                        }
                    }
                });
            },
            goBack() {
                this.$router.go(-1);
            },
            doSave() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (valid) {
                        if (this.chain_id) {
                            let edit_data = {
                                chain_id: this.chain_id,
                                user_name: this.form.user_name,
                                chain_name: this.form.chain_name
                            };

                            if (this.form.pswd) {
                                edit_data.pswd = this.form.pswd;
                            }

                            let res = await this.editMasterAccount(edit_data);

                            if (res && res.code == 100000) {
                                this.$message.success('Edit account success');
                            } else {
                                this.$message.error('Edit account failed');
                            }
                        } else {
                            let res =  await this.createMasterAccount({
                                ...this.form
                            });

                            if (res && res.code == 100000) {
                                this.$message.success('Create account success');
                                this.chain_id = res.data.chain_id;
                                window.history.replaceState(null, '', '/#/master/edit?chain_id=' + this.chain_id);
                            } else {
                                this.$message.error(res.msg);
                            }
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            doAddAccount() {
                if (!this.chain_id) {
                    this.$message.error('Please save the account first');
                    return;
                }
                
                this.show_link_layer = true;
            },
            ...mapActions({
                getMasterDetail: 'master/getMasterDetail',
                editMasterAccount: 'master/editMasterAccount',
                createMasterAccount: 'master/createMasterAccount',
                linkMerchantAccount: 'master/linkMerchantAccount',
                checkMerchantAccount: 'master/checkMerchantAccount'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            })
        }
    };
</script>