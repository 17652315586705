<template>
    <div class="main_bd">
        <div class="head_title">Master Account Management</div>
        <div class="action_content" style="float: right;">
            <div>
                <button style="width: 200px;" class="action_btn active_btn" @click="turn_to_create">Create an account</button>
            </div>
        </div>
        <div class="mod_default_box permission">
            <a-table :pagination="false" :rowKey="data => data.index" :columns="columns" :data-source="master_account_list" >
                <template slot="status_zone" slot-scope="text, data">
                    <a-tag color="green" v-if="data.status == 1">
                        Active
                    </a-tag>
                    <a-tag color="grey" v-else-if="data.status == 0">
                        Deactivated
                    </a-tag>
                </template>
                <div slot="action" slot-scope="text, data">
                    <div>
                        <div style="height: 10px"></div>
                        <a style="color: #2c9100" @click="showDetail(data)">Details</a>
                    </div>
                    <div>
                        <div style="height: 10px"></div>
                        <a style="color: #2c9100" @click="turn_to_create(data)">Edit</a>
                    </div>
                    <div>
                        <div style="height: 10px"></div>
                        <a style="color: #FD7858" @click="toggleActive(data)">{{ data.status == 0 ? 'Active' : 'Deactivate' }}</a>
                    </div>
                </div>
            </a-table>
        </div>
        <a-modal
            title=""
            :visible="show_detail_layer"
            @ok="handleOk"
            @cancel="handleOk"
            :width="900"
            style="height: 600px; overflow: auto;"
            >
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">{{ layer_data.chain_name }}</div>
                    </div>
                    <div>
                        <span>{{ layer_data.linked_count + ' Linked Account' }}</span>
                        <a-tag :color="layer_data.status == 1 ? 'green' : 'grey'">
                            {{ layer_data.status == 1 ? 'Active' : 'Deactivated' }}
                        </a-tag>
                    </div>
                </div>
            </template>
            <a-descriptions>
                <div class="detail_title" slot="title">Master Account Info</div>
                <a-descriptions-item label="Account Name" :span="3">
                    {{ layer_data.chain_name }}
                </a-descriptions-item>
                <a-descriptions-item label="Login ID" :span="3">
                    {{ layer_data.user_name }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Linked Accounts</div>
                <div>
                    <a-table :pagination="false" :rowKey="data => data.index" :columns="merchant_columns" :data-source="layer_data.linked_list" >
                    </a-table>
                </div>
            </a-descriptions>
            <template slot="footer">
                <a-button type="primary" @click="handleOk"
                    style="background-color: #2c9100; border-color: #2c9100;margin-left: 10px;margin-right: 400px;">
                    Done
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import Vue from 'vue';

    import VueSweetalert2 from "vue-sweetalert2";
    Vue.use(VueSweetalert2);

    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: "No.",
            dataIndex: "index",
            width: 200
        },
        {
            title: "Master Account Name",
            dataIndex: "chain_name"
        },
        {
            title: "Login ID",
            dataIndex: "user_name",
        },
        {
            title: "Linked Accounts",
            dataIndex: "linked_count"
        },
        {
            title: "Status",
            dataIndex: "status",
            scopedSlots: { customRender: 'status_zone' },
        },
        {
            title: 'Action',
            scopedSlots: { customRender: 'action' }
        }
    ];

    const merchant_columns = [
        {
            title: "No.",
            dataIndex: "index",
            width: 100
        },
        {
            title: "Merchant ID",
            dataIndex: "sid",
            width: 350
        },
        {
            title: "Merchant Name",
            dataIndex: "sname"
        },
        {
            title: "Merchant Email",
            dataIndex: "user_name"
        }
    ];

    export default {
        name: 'master_account_list',
        components: {
        },
        data() {
            return {
                columns,
                merchant_columns,
                master_account_list: [],
                show_detail_layer: false,
                layer_data: {}
            };
        },
        async created() {
            await this.doGetMasterList();
        },
        computed: {
            ...mapGetters({}),
            ...mapState({})
        },
        methods: {
            ...mapActions({
                getMasterList: 'master/getMasterList',
                getMasterDetail: 'master/getMasterDetail',
                editMasterAccount: 'master/editMasterAccount',
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            turn_to_create(data) {
                this.$router.push({
                    path: data && data.chain_id ? '/master/edit?chain_id=' + data.chain_id : '/master/edit'
                });
            },
            async showDetail(data) {
                this.show_detail_layer = true;
                console.log(data);
                let res = await this.getMasterDetail({
                    chain_id: data.chain_id
                });

                this.layer_data = res.data.account_detail;

            },
            toggleActive(data) {
                this.$confirm({
                    title: 'Are you sure you want to ' + (data.status == 0 ? 'activate' : 'deactivate') + ' this account?',
                    content: 'You can change the status back anytime.',
                    okText: 'Yes ' + (data.status == 0 ? 'activate' : 'deactivate') ,
                    okType: 'danger',
                    cancelText: 'Cancel',
                    onOk: async () => {
                        let res = await this.editMasterAccount({
                            chain_id: data.chain_id,
                            status: data.status == 0 ? 1 : 0
                        });

                        if (res && res.code != 100000) {
                            this.$message.error(res.msg);
                            return;
                        }

                        this.doGetMasterList();
                    },
                    onCancel: async () => {},
                });
            },
            handleOk() {
                this.show_detail_layer = false;
            },
            async doGetMasterList() {
                let res = await this.getMasterList();
                this.master_account_list = res.data.account_list;
            },
        }
    };
</script>
